import { Box, Grid } from '@mui/material';

import { YearbookMarketVideo } from '../../../types';
import { Video } from '../Video';

type GalleryProps = {
  videos: YearbookMarketVideo[];
  thumbnails?: Record<string, string>;
  handleVideoClick: (video: YearbookMarketVideo) => void;
  hidden: boolean;
};

export function Gallery({
  videos,
  thumbnails,
  handleVideoClick,
  hidden,
}: GalleryProps) {
  return (
    <Box role="tabpanel" hidden={hidden}>
      <Grid container spacing={2}>
        {videos.map((video) => (
          <Grid item xs={12} sm={6} md={3} key={video.videoId}>
            <Video
              video={video}
              handleVideoClick={handleVideoClick}
              thumbnail={thumbnails?.[video.videoId]}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
