import { useEffect } from 'react';

import { Box, Container, Grid, useMediaQuery } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import queryString from 'qs';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { hotjar } from 'react-hotjar';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TestAttributesConfig } from 'react-test-attributes';
import { ToastContainer } from 'react-toastify';

import Footer from './components/Footer';
import Header from './components/Header';
import { If } from './components/If';
import PresentationOngoing from './components/PresentationOngoing';
import MetaTags from './components/Seo/MetaTags';
import { useAuthContext } from './context/AuthContextProvider';
import { DataProvider } from './context/DataContext';
import APIs from './pages/APIs';
import APIDetails from './pages/APIs/APIDetails';
import AccountProfile from './pages/AccountProfile';
import AllArticles from './pages/AllArticles';
import PublishArticle from './pages/AllArticles/PublishArticle';
import AllDocuments from './pages/AllDocuments';
import AllEvents from './pages/AllEvents';
import AllInstitutions from './pages/AllInstitutions';
import AllOperations from './pages/AllOperations';
import AllProfessionals from './pages/AllProfessionals';
import Article from './pages/Article';
import Calculator from './pages/Calculator';
import ChangePassword from './pages/ChangePassword';
import Event from './pages/Event';
import EventPresentationLive from './pages/Event/Presentation/Live';
import FAQ from './pages/FAQ';
import { Favorites } from './pages/Favorites';
import Home from './pages/Home';
import Institution from './pages/Institution';
import Login from './pages/Login';
import Marketplace from './pages/Marketplace';
import MarketPlaceItemDetails from './pages/Marketplace/Details';
import MarketplaceManagement from './pages/MarketplaceManagement';
import Operation from './pages/Operation';
import OperationProfitabilityComparison from './pages/OperationProfitabilityComparison';
import PasswordRecovery from './pages/PasswordRecovery';
import Plans from './pages/Plans';
import Professional from './pages/Professional';
import Profitability from './pages/Profitability';
import Radar from './pages/Radar';
import Rankings from './pages/Rankings';
import Register from './pages/Register';
import Invite from './pages/Register/Invite';
import Signature from './pages/Signature';
import SocialProfile from './pages/SocialProfile';
import Training from './pages/Training';
import AllTrainings from './pages/Training/AllTrainings';
import UQBARMedia from './pages/UQBarMedia';
import UsersManagement from './pages/UsersManagement';
import AllVideos from './pages/Videos';
import SingleVideo from './pages/Videos/SingleVideo';
import VideoCollection from './pages/Videos/VideoCollection';
import { WidgetManagement } from './pages/Widget/WidgetManagement';
import Yearbooks from './pages/Yearbooks';
import YearbookPresentation2024 from './pages/Yearbooks/2024/presentation';
import { Yearbooks2025 } from './pages/Yearbooks/2025';
import Presentation from './pages/Yearbooks/presentation';
import { useScript } from './utils/hooks';
import { useRedirectUrl } from './utils/url';

import 'react-toastify/dist/ReactToastify.css';

if (process.env.REACT_APP_GTM) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
  };
  TagManager.initialize(tagManagerArgs);
}

export const YearbookRoutes = ({ year }) => {
  const { auth } = useAuthContext();
  const years = ['2023', '2024', '2025'];
  const isAvailableYear = years.includes(year);

  if (year === '2025' && auth.isLoggedIn && !auth.user?.subscription)
    return <></>;

  const hasPermission = auth.isLoggedIn && auth.user?.subscription?.plan?.type;

  if (!isAvailableYear) return <Redirect to="/anuarios" />;
  return (
    <>
      <If condition={isAvailableYear && year === '2023'}>
        <Presentation paywallBlur={false} />;
      </If>
      <If condition={isAvailableYear && year === '2024'}>
        <YearbookPresentation2024 paywallBlur={false} />
      </If>
      <If condition={isAvailableYear && year === '2025'}>
        <Yearbooks2025 paywallBlur={!hasPermission} />
      </If>
    </>
  );
};

export default function Routes() {
  useScript('https://js.hsforms.net/forms/v2.js');
  const { auth } = useAuthContext();
  const redirectUrl = useRedirectUrl();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isLargeScreen = useMediaQuery('(min-width: 1400px)');
  const params = queryString.parse(window.location.search);
  const referrer = document.referrer ? new URL(document.referrer) : undefined;

  useEffect(() => {
    if (
      process.env.REACT_APP_HOTJAR_HJID &&
      process.env.REACT_APP_HOTJAR_HJSV
    ) {
      hotjar.initialize(
        process.env.REACT_APP_HOTJAR_HJID,
        process.env.REACT_APP_HOTJAR_HJSV,
      );
    }
  }, []);

  const getSource = () => {
    if (params?.utm_source) return params?.utm_source;
    if (!referrer) return 'direct';
    return referrer?.host;
  };

  const getMedium = () => {
    if (params?.utm_medium) return params?.utm_medium;
    if (!referrer) return;

    const isOrganicSource = /google|bing/.test(referrer?.host);
    const isSocialSource = /facebook|twitter|instagram|linkedin/.test(
      referrer?.host,
    );
    if (isOrganicSource) {
      return 'organic';
    } else if (isSocialSource) {
      return 'social';
    } else {
      return 'referral';
    }
  };

  localStorage.setItem(
    'leadSource',
    JSON.stringify({
      traffic_medium: getMedium(),
      traffic_source: getSource(),
      traffic_campaign: params?.utm_campaign,
      traffic_value: params?.utm_term,
    }),
  );

  localStorage.setItem('referrer', referrer?.host ?? '');

  if (auth.isLoggedIn && !auth.isFetched) return <CircularProgress />;
  return (
    <TestAttributesConfig value={{ enableInProductionMode: true }}>
      <HelmetProvider>
        <MetaTags
          title="UQBAR - Educação, Conexão e Inteligência de Mercado"
          description="A Uqbar conecta todas as partes que compõem o mercado de Finanças Estruturadas do país: operações financeiras, instituições participantes e profissionais atuantes. Tenha acesso a dados, artigos, contatos, rankings e mais."
        />
        <Header />
        <PresentationOngoing />
        <Switch>
          <Route exact path="/plataforma">
            <Home />
          </Route>
          <Route exact path="/planos">
            <Plans />
          </Route>
          <Route path={['/account/invitation', '/convite']}>
            <Invite />
          </Route>
          <Redirect from="/register" to="/cadastro" />
          <Route path="/cadastro">
            {auth.isLoggedIn ? <Redirect to="/plataforma" /> : <Register />}
          </Route>
          <Route path="/login">
            {auth.isLoggedIn ? (
              <Redirect to={redirectUrl || '/plataforma'} />
            ) : (
              <Login />
            )}
          </Route>
          <Redirect from="/passwordRecovery" to="recuperar-senha" />
          <Route path="/recuperar-senha">
            {auth.isLoggedIn ? (
              <Redirect to="/plataforma" />
            ) : (
              <PasswordRecovery />
            )}
          </Route>
          <Redirect
            from="/changePassword"
            to={{ ...window.location, pathname: '/alterar-senha' }}
          />
          <Route path="/alterar-senha">
            {auth.isLoggedIn ? (
              <Redirect to="/plataforma" />
            ) : (
              <ChangePassword />
            )}
          </Route>
          <Redirect
            from="/professional/:slug/:hashId"
            to="/profissional/:slug/:hashId"
          />
          <>
            <Container
              component="main"
              style={{
                paddingTop: isMobile ? '150px' : '75px',
                overflowX: isMobile ? 'hidden' : 'auto',
                paddingLeft: isMobile ? '16px' : '80px',
                paddingRight: '16px',
              }}>
              <Box pb={5}>
                <Grid container>
                  <Grid item xs={12}>
                    <Switch>
                      <Route path="/profissional/:slug/:hashId">
                        <Professional />
                      </Route>
                      <Route path="/rankings">
                        <Rankings />
                      </Route>
                      <Route path="/radar">
                        <Radar />
                      </Route>
                      <Redirect
                        from="/institution/:slug/:hashId"
                        to="/instituicao/:slug/:hashId/perfil"
                      />
                      <Route
                        path={[
                          '/instituicao/:slug/:hashId/:tab',
                          '/instituicao/:slug/:hashId',
                        ]}>
                        <Institution />
                      </Route>
                      <Redirect
                        from="/article/:slug/:hashId"
                        to="/artigo/:slug/:hashId"
                      />
                      <Route path="/artigo/:slug/:hashId">
                        <Article />
                      </Route>
                      <Redirect from="/allArticles" to="/artigos" />
                      <Route path="/artigos">
                        <AllArticles />
                      </Route>
                      <Route path="/publicar/artigos">
                        <PublishArticle />
                      </Route>
                      <Redirect from="/allEvents" to="/eventos" />
                      <Route path="/eventos">
                        <AllEvents />
                      </Route>
                      <Route
                        path="/evento/:slug/:id/:tab(visao-geral|patrocinadores|palestrantes|programacao|faq)?"
                        exact>
                        <Event />
                      </Route>
                      <Route
                        path="/evento/:slug/:id/palestra/:presentationId/live"
                        exact>
                        <If condition={auth.isLoggedIn}>
                          <EventPresentationLive />
                        </If>
                        <If condition={!auth.isLoggedIn}>
                          <Redirect to="/login" />
                        </If>
                      </Route>
                      <Redirect
                        from="/training/:slug/:hashId"
                        to="/treinamento/:slug/:hashId"
                      />
                      <Route path="/treinamento/:slug/:hashId">
                        <Training />
                      </Route>
                      <Redirect from="/allTrainings" to="/treinamentos" />
                      <Route path="/treinamentos">
                        <AllTrainings />
                      </Route>
                      <Redirect from="/institutions" to="/instituicoes" />
                      <Route path="/instituicoes">
                        <AllInstitutions />
                      </Route>
                      <Redirect from="/professionals" to="/profissionais" />
                      <Route path="/profissionais">
                        <AllProfessionals />
                      </Route>
                      <Route path="/operacoes">
                        <AllOperations />
                      </Route>
                      <Route path="/comparar-rentabilidade">
                        <OperationProfitabilityComparison />
                      </Route>
                      <Route path="/documentos">
                        <AllDocuments />
                      </Route>
                      <Route path="/rentabilidades">
                        <Profitability />
                      </Route>
                      <Redirect
                        from="/operation/:market/:slug/:hashId/:tab"
                        to="/operacao/:market/:slug/:hashId/:tab"
                      />
                      <Route path="/operacao/:market/:slug/:hashId/:tab">
                        <Operation />
                      </Route>
                      <Redirect
                        from="/operation/:market/:slug/:hashId"
                        to="/operacao/:market/:slug/:hashId"
                      />
                      <Route path="/operacao/:market/:slug/:hashId">
                        <Operation />
                      </Route>
                      <Route path="/perfil-social/:tab?">
                        <If condition={auth.isLoggedIn}>
                          <SocialProfile />
                        </If>
                        <If condition={!auth.isLoggedIn}>
                          <Redirect to="/login" />
                        </If>
                      </Route>
                      <Redirect from="/profile/:tab" to="/perfil/:tab" />
                      <Route exact path="/perfil/:tab">
                        <If condition={auth.isLoggedIn}>
                          <AccountProfile />
                        </If>
                        <If condition={!auth.isLoggedIn}>
                          <Redirect to="/login" />
                        </If>
                      </Route>
                      <Route path="/usuarios">
                        <If condition={auth.isLoggedIn}>
                          <UsersManagement />
                        </If>
                        <If condition={!auth.isLoggedIn}>
                          <Redirect to="/login" />
                        </If>
                      </Route>
                      <Route path="/marketplace/gerenciar">
                        <If condition={auth.isLoggedIn}>
                          <MarketplaceManagement />
                        </If>
                        <If condition={!auth.isLoggedIn}>
                          <Redirect to="/login" />
                        </If>
                      </Route>
                      <Route path="/widget">
                        <If condition={auth.isLoggedIn}>
                          <WidgetManagement />
                        </If>
                        <If condition={!auth.isLoggedIn}>
                          <Redirect to="/login" />
                        </If>
                      </Route>
                      <Route path="/favoritos">
                        <If condition={auth.isLoggedIn}>
                          <Favorites />
                        </If>
                        <If condition={!auth.isLoggedIn}>
                          <Redirect to="/login" />
                        </If>
                      </Route>
                      <Route path="/calculadora">
                        <DataProvider>
                          <Calculator />
                        </DataProvider>
                      </Route>
                      <Route path="/videos" exact>
                        <AllVideos />
                      </Route>
                      <Route path="/videos/:collectionSlug/:id">
                        <If condition={auth.isLoggedIn}>
                          <VideoCollection />
                        </If>
                        <If condition={!auth.isLoggedIn}>
                          <Redirect to="/videos" />
                        </If>
                      </Route>
                      <Route path="/video/:collectionSlug/:videoSlug/:id">
                        <If condition={auth.isLoggedIn}>
                          <SingleVideo />
                        </If>
                        <If condition={!auth.isLoggedIn}>
                          <Redirect to="/videos" />
                        </If>
                      </Route>
                      <Route path="/faq">
                        <FAQ />
                      </Route>
                      <Route path="/midia">
                        <UQBARMedia />
                      </Route>
                      <Route exact path="/anuarios">
                        <Yearbooks />
                      </Route>
                      <Route path="/apis/:endpoint">
                        <APIDetails />
                      </Route>
                      <Route path="/apis">
                        <APIs />
                      </Route>
                      <Route exact path="/marketplace/:type?">
                        <Marketplace />
                      </Route>
                      <Route path="/marketplace/:type/:slug/:hashId">
                        <MarketPlaceItemDetails />
                      </Route>
                      <Route exact path="/assinatura">
                        <If condition={auth.isLoggedIn}>
                          <Signature />
                        </If>
                        <If condition={!auth.isLoggedIn}>
                          <Redirect to="/login" />
                        </If>
                      </Route>
                    </Switch>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </>
        </Switch>
        <Footer />
        <ToastContainer />
      </HelmetProvider>
    </TestAttributesConfig>
  );
}
