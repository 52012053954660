import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  Link,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Test from 'react-test-attributes';
import PasswordField from 'src/components/FormsUI/PasswordField';
import { getImageRegister } from 'src/services/media';
import { setBannerMessage } from 'src/store/display';
import { Media } from 'src/types/strapi';
import { useRedirectUrl } from 'src/utils/url';
import * as Yup from 'yup';

import * as S from './styles';
import { useAuthContext } from '../../context/AuthContextProvider';
import { login } from '../../services/auth';
import { ScrollToTop } from '../../utils/scroll';

const FORM_VALIDATION = Yup.object().shape({
  identifier: Yup.string().required('Campo obrigatório'),
  password: Yup.string().required('Campo obrigatório'),
});

const INITIAL_VALUES = {
  identifier: '',
  password: '',
  keepMeSigned: true,
};

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleLogin, notifyAuthChannel } = useAuthContext();
  const redirectUrl = useRedirectUrl();

  const submitMutation = useMutation(login, {
    onError: (error: any) => {
      if (error?.response?.data?.error?.name === 'ValidationError') {
        formik.setErrors(error?.response?.data?.error?.details);
      } else {
        dispatch(
          setBannerMessage({
            message: error?.response?.data?.error?.message,
            type: 'error',
            autoclose: false,
          }),
        );
      }
    },
    onSuccess: async (response) => {
      if (response.data?.jwt) {
        const { jwt } = response.data;
        const payload = JSON.parse(response.config.data);
        localStorage.removeItem('@App:token');
        if (payload.keepMeSigned) {
          localStorage.setItem('@App:token', jwt);
        }
        sessionStorage.setItem('@App:token', jwt);
        sessionStorage.removeItem('@App:tempToken');
        handleLogin(true);
        notifyAuthChannel('login', jwt);
        if (redirectUrl) {
          history.push(redirectUrl);
          return;
        }
        history.push('/plataforma');
      } else {
        dispatch(
          setBannerMessage({
            message: 'Não foi possível realizar o login, tente novamente',
            type: 'error',
            autoclose: false,
          }),
        );
      }
    },
  });

  const { data: imageData, isLoading: isLoadingLoginImage } = useQuery(
    'loginImage',
    () => getImageRegister('loginImage'),
  );

  const { data: uqbarDayEventData, isLoading: isLoadingUqbarDay } = useQuery(
    'uqbarday',
    () => getImageRegister('uqbarday'),
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: FORM_VALIDATION,
    onSubmit: (values) => {
      submitMutation.mutateAsync(values);
    },
  });

  const getImageData = (): { image?: Media; description?: string } => {
    if (redirectUrl) {
      const eventRegex = /^\/evento\/([^/]+)\/(\d+)$/;
      const match = redirectUrl.match(eventRegex);

      if (match && uqbarDayEventData?.uqbarday) {
        return {
          image: uqbarDayEventData.uqbarday.image,
          description: uqbarDayEventData.uqbarday.description,
        };
      }
    }

    return {
      image: imageData?.loginImage?.image,
      description: imageData?.loginImage?.description,
    };
  };

  const isLoading = isLoadingLoginImage || isLoadingUqbarDay;

  return (
    <ScrollToTop>
      <Box
        sx={(theme) => ({
          paddingTop: '75px',
          backgroundColor: '#FFFFFF',
          position: 'relative',
          [theme.breakpoints.down('sm')]: {
            paddingTop: '130px',
          },
        })}
        component="section">
        <Grid
          container
          sx={(theme) => ({
            height: 'calc(100vh - 75px)',
            [theme.breakpoints.down('sm')]: { height: 'calc(100vh + 15px)' },
          })}>
          <Hidden mdDown>
            <Grid item md={6} xl={5} sx={{ height: 'calc(100vh - 75px)' }}>
              {isLoading ? (
                <Skeleton variant="rectangular" width={740} height="100vh" />
              ) : (
                <>
                  <S.Image
                    src={getImageData().image?.url}
                    alt={getImageData().description}
                  />
                  <Typography
                    sx={(theme) => ({
                      position: 'absolute',
                      zIndex: 99,
                      bottom: 50,
                      left: 75,
                      fontSize: '24px',
                      fontWeight: 700,
                      color: '#FFFFFF',
                      maxWidth: 500,
                      lineHeight: '32px',
                      [theme.breakpoints.down('lg')]: {
                        fontSize: '18px',
                        maxWidth: 400,
                        lineHeight: '26px',
                      },
                    })}>
                    {getImageData().description}
                  </Typography>
                </>
              )}
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            md={6}
            xl={7}
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ padding: '0 20px' }}>
            <form
              onSubmit={formik.handleSubmit}
              style={{ width: '100%', maxWidth: 400 }}>
              <Typography textAlign="center" fontSize={32} fontWeight={700}>
                Acesse sua conta
              </Typography>
              <Grid
                container
                flexDirection="column"
                spacing={2}
                sx={(theme) => ({
                  marginTop: 7,
                  [theme.breakpoints.down(1367)]: {
                    marginTop: 2,
                  },
                })}>
                <Grid item>
                  <S.FieldTitle>E-mail</S.FieldTitle>
                  <FormControl fullWidth variant="outlined">
                    {/* @ts-ignore */}
                    <Test id="form-email">
                      <TextField
                        name="identifier"
                        placeholder="Insira seu e-mail"
                        value={formik.values.identifier}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.identifier &&
                          Boolean(formik.errors.identifier)
                        }
                        helperText={
                          formik.touched.identifier && formik.errors.identifier
                        }
                      />
                    </Test>
                  </FormControl>
                </Grid>
                <Grid item>
                  <S.FieldTitle>Senha</S.FieldTitle>
                  <FormControl fullWidth variant="outlined">
                    {/* @ts-ignore */}
                    <Test id="form-password">
                      <PasswordField
                        name="password"
                        placeholder="Digite uma senha"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password ? formik.errors.password : ''
                        }
                      />
                    </Test>
                  </FormControl>
                </Grid>
                <Grid item container justifyContent="center">
                  {/* @ts-ignore */}
                  <Test id="btn-login">
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ fontSize: '15px', fontWeight: 700 }}>
                      {submitMutation.isLoading ? (
                        <CircularProgress sx={{ color: '#FFFFFF' }} />
                      ) : (
                        'Acessar'
                      )}
                    </Button>
                  </Test>
                </Grid>
              </Grid>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={(theme) => ({
                  marginTop: '125px',
                  [theme.breakpoints.down(1367)]: {
                    marginTop: 2,
                  },
                  [theme.breakpoints.down('md')]: {
                    marginTop: '75px',
                  },
                })}>
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '12px',
                      fontWeight: 500,
                      letterSpacing: '0.045em',
                      textTransform: 'uppercase',
                    },
                  }}
                  onChange={() =>
                    formik.setFieldValue(
                      'keepMeSigned',
                      !formik.values.keepMeSigned,
                    )
                  }
                  checked={formik.values.keepMeSigned}
                  name="keepMeSigned"
                  control={<Checkbox size="small" />}
                  label="mantenha-me conectado"
                />

                {/* @ts-ignore */}
                <Test id="link-forgotPassword">
                  <Link
                    component={RouterLink}
                    to={'/recuperar-senha'}
                    style={{
                      fontSize: '12px',
                      fontWeight: 500,
                      textTransform: 'uppercase',
                      letterSpacing: '0.045em',
                      margin: '20px 0 28px',
                    }}>
                    esqueci minha senha
                  </Link>
                </Test>

                {/* @ts-ignore */}
                <Test id="link-register">
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      textTransform: 'uppercase',
                      letterSpacing: '0.045em',
                    }}>
                    Ainda não tem uma conta?{' '}
                    <Link component={RouterLink} to={'/planos'}>
                      escolha um plano
                    </Link>
                  </Typography>
                </Test>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </ScrollToTop>
  );
}
