import { ChartsList } from '../../types';

const categoriesDatesMothly: string[] = [
  '01/19',
  '02/19',
  '03/19',
  '04/19',
  '05/19',
  '06/19',
  '07/19',
  '08/19',
  '09/19',
  '10/19',
  '11/19',
  '12/19',
  '01/20',
  '02/20',
  '03/20',
  '04/20',
  '05/20',
  '06/20',
  '07/20',
  '08/20',
  '09/20',
  '10/20',
  '11/20',
  '12/20',
  '01/21',
  '02/21',
  '03/21',
  '04/21',
  '05/21',
  '06/21',
  '07/21',
  '08/21',
  '09/21',
  '10/21',
  '11/21',
  '12/21',
  '01/22',
  '02/22',
  '03/22',
  '04/22',
  '05/22',
  '06/22',
  '07/22',
  '08/22',
  '09/22',
  '10/22',
  '11/22',
  '12/22',
  '01/23',
  '02/23',
  '03/23',
  '04/23',
  '05/23',
  '06/23',
  '07/23',
  '08/23',
  '09/23',
  '10/23',
  '11/23',
  '12/23',
];

const categoriesDatesYearly: string[] = [
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
];
export const crsPrimaryMarketCharts: ChartsList = {
  emissionsCr: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante emitido',
        type: 'column',
        data: [903059000, 128171961.26, 3954069436.61],
      },
      {
        name: 'Núm. Operações',
        type: 'line',
        data: [3, 9, 196],
      },
    ],
    categories: ['2022', '2023', '2024'],
    numeric: true,
  },
  emissionsCrByBallastType: {
    chartType: 'column',
    data: [
      {
        name: 'Aluguel',
        data: [20000000, 0, 140000000],
      },
      {
        name: 'Aquisição de Ações',
        data: [783059000, 0, 0],
      },
      {
        name: 'Aquisição de Bens',
        data: [0, 0, 1909385206.34],
      },
      {
        name: 'Ativo Judicial',
        data: [0, 81719612.6, 43502540.28],
      },
      {
        name: 'Comércio de Insumos Agrícolas',
        data: [0, 0, 100000000],
      },
      {
        name: 'Construção de Imóvel',
        data: [0, 0, 77000000],
      },
      {
        name: 'Crédito Pessoal',
        data: [0, 0, 354412114.64],
      },
      {
        name: 'Financiamento',
        data: [0, 0, 2320500],
      },
      {
        name: 'Financiamento de Veículos',
        data: [100000000.0, 0, 0],
      },
      {
        name: 'Não Informado',
        data: [0, 0, 12550285.99],
      },
      {
        name: 'Pagamento de Despesas',
        data: [0, 0, 897923390.56],
      },
      {
        name: 'Patrocínio',
        data: [0, 0, 13258000.0],
      },
      {
        name: 'Recebíveis Comerciais',
        data: [0, 0, 67906535],
      },
      {
        name: 'Reforço de Caixa',
        data: [0, 46000000.0, 0],
      },
      {
        name: 'Reforço de Capital de Giro',
        data: [0, 9000000.0, 335810863.8],
      },
      {
        name: 'Reperfilamento de Dívidas',
        data: [0, 30000000.0, 0],
      },
      {
        name: 'Reposição de Investimento',
        data: [0, 35000000.0, 0],
      },
    ],
    categories: ['2022', '2023', '2024'],
    numeric: true,
  },
  emissionsCrByDebtor: {
    chartType: 'column',
    data: [
      {
        name: 'Pessoa Jurídica',
        data: [883059000, 128171961.26, 2847893633.97],
      },
      {
        name: 'Pessoa Física',
        data: [0, 0, 452246340],
      },
      {
        name: 'Multidevedor',
        data: [0, 0, 621395193.64],
      },
    ],
    categories: ['2022', '2023', '2024'],
    numeric: true,
  },
  emissionsCri: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante emitido',
        type: 'column',
        data: [
          18401272890.82, 42198698373.48, 52407814397.67, 51425353825.11,
          62758194744.9,
        ],
      },
      {
        name: 'Núm. Operações',
        type: 'line',
        data: [275, 476, 452, 392, 559],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCriByCreditRisk: {
    chartType: 'column',
    data: [
      {
        name: 'Concentrado',
        data: [
          15324257185.77, 32858743536.22, 42922146475.2, 45510097737.26,
          49325099228.43,
        ],
      },
      {
        name: 'Pulverizado',
        data: [
          3077015705.05, 9339954837.26, 9485667922.47, 5915256087.85,
          13433095516.47,
        ],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCriByDebtor: {
    chartType: 'column',
    data: [
      {
        name: 'Pessoa Jurídica (Setor Público)',
        data: [120000000, 66586160, 1674971000, 51279000, 0],
      },
      {
        name: 'Pessoa Jurídica',
        data: [
          16007422352.42, 36942427545.22, 48017098435.38, 45095818737.26,
          51031016073.11,
        ],
      },
      {
        name: 'Pessoa Física',
        data: [
          2101852581.03, 2900128342.86, 2059094660.24, 3649919093.46,
          7652232097.79,
        ],
      },
      {
        name: 'Multidevedor',
        data: [
          33179957.37, 2289556325.4, 568328302.05, 2628336994.39, 4074946574.0,
        ],
      },
      {
        name: 'Gov. Estadual',
        data: [138818000, 0, 88322000, 0, 0],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCriByRemuneration: {
    chartType: 'column',
    data: [
      {
        name: 'CDI',
        data: [
          5274191100.07, 6495968999.74, 20057386076.0, 22091181360.13,
          25232777627.79,
        ],
      },
      {
        name: 'IGP-DI',
        data: [44000000.0, 0, 0, 0, 0],
      },
      {
        name: 'IGP-M',
        data: [1578247568.78, 414094853.0, 65207437.64, 44776000.0, 80232000.0],
      },
      {
        name: 'INCC-DI',
        data: [89800000.0, 140620000.0, 0, 1108160000.0, 618548000.0],
      },
      {
        name: 'INPC',
        data: [49658598.81, 104450000.0, 0, 0, 0],
      },
      {
        name: 'IPCA',
        data: [
          11015957754.48, 33735701058.74, 32060220884.03, 24273676372.05,
          31514613961.79,
        ],
      },
      {
        name: 'Taxa Fixa',
        data: [
          349417868.68, 1142807462.0, 185000000.0, 3907560092.93, 5275881155.32,
        ],
      },
      {
        name: 'TR',
        data: [0, 0, 40000000.0, 0, 0],
      },
      {
        name: 'INCC-M',
        data: [0, 165056000.0, 0, 0, 36142000.0],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCriByDeadlineRange: {
    chartType: 'column',
    data: [
      {
        name: 'acima de 10 anos',
        data: [
          6636351942.0, 16414857191.2, 11483566283.65, 10530025943.15,
          12516700782.78,
        ],
      },
      {
        name: 'até 2 anos',
        data: [
          715912000.0, 242792560.0, 355016000.0, 1864587000.0, 1909728150.0,
        ],
      },
      {
        name: 'de 2 a 5 anos',
        data: [
          5205550414.34, 8625916462.4, 19037840146.0, 22596860846.5,
          26947491007.27,
        ],
      },
      {
        name: 'de 5 a 10 anos',
        data: [
          5843458534.48, 16915132159.88, 21531391968.02, 16433880035.46,
          21384274804.85,
        ],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  operationsCriByAssetBacking: {
    chartType: 'column',
    data: [
      {
        name: 'Aluguel',
        data: [57.0, 19.0, 61.0, 49.0, 53.0],
      },
      {
        name: 'Crédito Imobiliário',
        data: [210.0, 454.0, 312.0, 249.0, 471.0],
      },
      {
        name: 'Crédito Pessoal',
        data: [4.0, 1.0, 7.0, 13.0, 22.0],
      },
      {
        name: 'Multiclasse',
        data: [4.0, 2.0, 9.0, 3.0, 2.0],
      },
      {
        name: 'Reembolso',
        data: [0, 0, 63.0, 78.0, 10.0],
      },
      {
        name: 'Outros',
        data: [0, 0, 0, 0, 1.0],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
    monetary: false,
  },
  emissionsCriByRealEstateSegment: {
    chartType: 'column',
    data: [
      {
        name: 'Apartamentos e Casas',
        data: [
          5992790496.12, 10582105383.72, 12961270949.88, 13843857997.98,
          24812848873.86,
        ],
      },
      {
        name: 'Clínicas e Hospitais',
        data: [
          368390019.46, 1606500000.0, 5094990000.0, 3375125000.0, 3123174000.0,
        ],
      },
      {
        name: 'Educacional',
        data: [98102094.0, 129595435.0, 1331300000.0, 40000000.0, 8281000.0],
      },
      {
        name: 'Hotelaria',
        data: [
          608940000.0, 414861000.0, 727301000.0, 1980705000.0, 5649187000.0,
        ],
      },
      {
        name: 'Infraestrutura',
        data: [
          104844762.19, 1165583260.0, 1873992263.92, 1972086000.0, 3204141000.0,
        ],
      },
      {
        name: 'Lajes Corporativas',
        data: [
          2532949305.47, 4958398649.31, 8515796000.0, 11669101229.4,
          6818486132.62,
        ],
      },
      {
        name: 'Logístico e Industrial',
        data: [
          1379744599.95, 3643208140.0, 8612509928.31, 5935884178.0,
          6805715685.27,
        ],
      },
      {
        name: 'Loteamento',
        data: [
          1624113344.04, 6772047382.54, 2518580428.47, 3530363227.26,
          3296090536.35,
        ],
      },
      {
        name: 'Multipropriedade',
        data: [
          632913214.91, 2331799954.91, 598125225.07, 24934000.0, 73500000.0,
        ],
      },
      {
        name: 'Propriedade Rural',
        data: [779500000.0, 60000000.0, 1249188000.0, 75000000.0, 102024620.0],
      },
      {
        name: 'Varejo - Shoppings, Lojas etc',
        data: [
          4278985054.68, 8615597170.0, 8159147128.63, 8677723192.47,
          7091441896.8,
        ],
      },
      {
        name: 'Cemitérios e Funerárias',
        data: [0, 100000000.0, 90000000.0, 0, 0],
      },
      {
        name: 'Centros Esportivos',
        data: [0, 0, 640000000.0, 0, 86305000.0],
      },
      {
        name: 'Multisegmento',
        data: [0, 1819001998.0, 35613473.39, 180574000.0, 1502942000.0],
      },
      {
        name: 'Parques',
        data: [0, 0, 0, 120000000.0, 0],
      },
      {
        name: 'Outros',
        data: [0, 0, 0, 0, 184057000.0],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  investorsProfileCri: {
    chartType: 'column',
    data: [
      {
        name: 'Clubes de investimento',
        data: [28350000.0, 0, 0, 15000000.0, 90000000.0],
      },
      {
        name: 'Demais instituições financeiras',
        data: [91583000.0, 144350000.0, 1397045000.0, 251045000.0, 394828000.0],
      },
      {
        name: 'Demais pessoas jurídicas',
        data: [86706075.35, 159103550.0, 295048490.0, 175860466.0, 266257000.0],
      },
      {
        name: 'Fundos de investimento',
        data: [
          6813978955.43, 237933000.0, 10807554107.86, 4962663323.28,
          9563642711.37,
        ],
      },
      {
        name: 'Instituições financeiras ligadas à emissora e/ou aos participantes do consórcio',
        data: [
          830693001.0, 1523074000.0, 4050848000.0, 9103716000.0, 5558510184.68,
        ],
      },
      {
        name: 'Instituições Intermediárias participantes do consórcio de distribuição',
        data: [
          837215000.0, 1093259677.0, 5344693263.92, 1898669000.0, 1404183500.0,
        ],
      },
      {
        name: 'Investidores estrangeiros',
        data: [2900000.0, 0, 2000000.0, 640535000.0, 0],
      },
      {
        name: 'Pessoas Físicas',
        data: [
          1251909775.15, 2493176000.0, 6896036249.0, 13409323297.7,
          12702025203.88,
        ],
      },
      {
        name: 'Pessoas (físicas ou jurídicas) ligadas ao(s) originador(es) dos créditos',
        data: [0, 3000.0, 109467000.0, 0, 0],
      },
      {
        name: 'Companhias seguradoras',
        data: [0, 0, 115000000.0, 15816000.0, 0],
      },
      {
        name: 'Entidades de previdência privada',
        data: [0, 0, 129146000.0, 82550000.0, 1473000.0],
      },
      {
        name: 'Instituições financeiras Bancos Comerciais',
        data: [0, 0, 20000000.0, 51020000.0, 0],
      },
      {
        name: 'Instituições financeiras ligadas a(os) originador(es) dos créditos',
        data: [0, 0, 981966000.0, 0, 0],
      },
      {
        name: 'Demais pessoas jurídicas ligadas à emissora e/ou aos participantes do consórcio',
        data: [0, 0, 0, 50002000.0, 132107000.0],
      },
      {
        name: 'Instituições financeiras ligadas a(os) cedente(s) dos créditos',
        data: [0, 0, 0, 37380000.0, 111750000.0],
      },
      {
        name: 'Outros',
        data: [0, 0, 0, 5864000.0, 194000.0],
      },
      {
        name: 'Sócios, administradores, empregados, propostos, e demais pessoas ligadas à emissora e/ou aos participantes do consórcio',
        data: [0, 0, 0, 82252000.0, 86021000.0],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCra: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante Emitido',
        type: 'column',
        data: [
          15937331000, 25611230000, 45117429855, 43880480580.29, 41759227852,
        ],
      },
      {
        name: 'Núm. Operações',
        type: 'line',
        data: [67, 128, 247, 180, 185],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCraByCreditRisk: {
    chartType: 'column',
    data: [
      {
        name: 'Concentrado',
        data: [
          13579720000, 23045896000, 43754734855, 42734084726.29, 39761524087,
        ],
      },
      {
        name: 'Pulverizado',
        data: [2357611000, 2565334000, 1362695000, 1146395854, 1997703765],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCraByDebtor: {
    chartType: 'column',
    data: [
      {
        name: 'Pessoa Física',
        data: [
          2077338000.0, 1708189000.0, 1938418105.0, 1261439000.0, 1222303000.0,
        ],
      },
      {
        name: 'Pessoa Jurídica',
        data: [
          13859993000.0, 22864451000.0, 42861046750.0, 42314652580.29,
          40147945852.0,
        ],
      },
      {
        name: 'Multidevedor',
        data: [0, 1038590000.0, 317965000.0, 304389000.0, 388979000.0],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCraByBallastContract: {
    chartType: 'pie',
    data: [
      14640690, 600000, 2028351000, 10230000, 12715297765, 358435000,
      19181253397, 72544000, 722200000, 6655676000,
    ],
    categories: [
      'Barter',
      'CCB',
      'CDCA',
      'Cédula Imobiliária Rural (CIR)',
      'CPR Financeira',
      'CPR Financeira, Duplicatas',
      'Debêntures',
      'Duplicatas',
      'Letras Financeiras',
      'Nota Comercial',
    ],
  },
  emissionsCraByRemuneration: {
    chartType: 'column',
    data: [
      {
        name: 'CDI',
        data: [
          2887592000.0, 4614541000.0, 19275193884.0, 26041196726.29,
          18659008397.0,
        ],
      },
      {
        name: 'IPCA',
        data: [
          11328894000.0, 19831680000.0, 25542185166.0, 8528891000.0,
          9448382000.0,
        ],
      },
      {
        name: 'Taxa Fixa',
        data: [
          1720845000.0, 1165009000.0, 53750000.0, 7660096854.0, 11968370690.0,
        ],
      },
      {
        name: 'PTAX800',
        data: [0, 0, 244300805.0, 1650296000.0, 1683466765.0],
      },
      {
        name: 'Outros',
        data: [0, 0, 2000000.0, 0, 0],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  operationsCraBySegment: {
    chartType: 'column',
    data: [
      {
        name: 'Atividades Pecuárias',
        data: [
          6216344000.0, 7832698000.0, 10427485000.0, 6104429571.29,
          13438373000.0,
        ],
      },
      {
        name: 'Diversos',
        data: [
          220740000.0, 1592634000.0, 8456241340.0, 11677148000.0, 5542224000.0,
        ],
      },
      {
        name: 'Fibras',
        data: [
          1562791000.0, 1325000000.0, 4412588165.0, 1037432000.0, 1270158000.0,
        ],
      },
      {
        name: 'Frutas e Legumes',
        data: [
          523192000.0, 270000000.0, 395651000.0, 345500000.0, 1021718000.0,
        ],
      },
      {
        name: 'Grãos',
        data: [
          735767000.0, 2688699000.0, 7539638350.0, 14422698854.0, 7905352690.0,
        ],
      },
      {
        name: 'Insumos Agrícolas',
        data: [
          2651951000.0, 3919999000.0, 4752268000.0, 3493406000.0, 3172986162.0,
        ],
      },
      {
        name: 'Máquinas Agrícolas e Equipamentos',
        data: [
          900000000.0, 200000000.0, 555000000.0, 1670862000.0, 1319839000.0,
        ],
      },
      {
        name: 'Multiclasse',
        data: [200000000.0, 738345000.0, 0, 175000000.0, 0],
      },
      {
        name: 'Serviços Logísticos',
        data: [
          870895000.0, 665000000.0, 785140000.0, 449636155.0, 1971656000.0,
        ],
      },
      {
        name: 'Sucroenergético',
        data: [
          2055651000.0, 6192604000.0, 7732754000.0, 4504368000.0, 6087065000.0,
        ],
      },
      {
        name: 'Serviços Técnicos',
        data: [0, 145200000.0, 35000000.0, 0, 12000000.0],
      },
      {
        name: 'Tabaco',
        data: [0, 41051000.0, 25664000.0, 0, 0],
      },
      {
        name: 'Outros',
        data: [0, 0, 0, 0, 17856000.0],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
    monetary: false,
  },
  emissionsCraByEsgCertificate: {
    chartType: 'column',
    data: [
      {
        name: 'CBI',
        data: [25000000.0, 8000000.0, 500000000.0, 1550000000.0, 0],
      },
      {
        name: 'GBP',
        data: [
          841317000.0, 2457043000.0, 4282000000.0, 196959571.29, 948000000.0,
        ],
      },
      {
        name: 'SLBP',
        data: [0, 1148844000.0, 200000000.0, 0, 0],
      },
      {
        name: 'Impacto Socioambiental',
        data: [0, 0, 6000000.0, 11000000.0, 274889000.0],
      },
      {
        name: 'Mais de um princípio',
        data: [0, 0, 0, 1000000000.0, 0],
      },
      {
        name: 'Outros',
        data: [
          15071014000.0, 21997343000.0, 40129429855.0, 41122521009.0,
          40536338852.0,
        ],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  investorsProfileCra: {
    chartType: 'pie',
    data: [
      15319000, 770498000, 451881000, 17847000, 5637420945, 7130807765,
      2972842000, 53133453, 22659583999, 924080000,
    ],
    categories: [
      'Clubes de investimento',
      'Demais instituições financeiras',
      'Demais pessoas jurídicas',
      'Demais pessoas jurídicas ligadas à emissora e/ou aos participantes do consórcio',
      'Fundos de investimento',
      'Instituições financeiras ligadas à emissora e/ou aos participantes do consórcio',
      'Instituições Intermediárias participantes do consórcio de distribuição',
      'Outros',
      'Pessoas Físicas',
      'Sócios, administradores, empregados, propostos, e demais pessoas ligadas à emissora e/ou aos participantes do consórcio',
    ],
  },
  emissionsCraByDeadlineRange: {
    chartType: 'column',
    data: [
      {
        name: 'até 2 anos',
        data: [
          2086962000.0, 1531932000.0, 3145367300.0, 1079725854.0, 1508695455.0,
        ],
      },
      {
        name: 'de 2 a 5 anos',
        data: [
          5754702000.0, 9102493000.0, 15627608390.0, 12546250000.0,
          8243709000.0,
        ],
      },
      {
        name: 'de 5 a 10 anos',
        data: [
          8095667000.0, 14350763000.0, 20099692165.0, 15492211726.29,
          24028613397.0,
        ],
      },
      {
        name: 'acima de 10 anos',
        data: [0, 626042000.0, 6244762000.0, 14762293000.0, 7978210000.0],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
};

export const crsSecondaryMarketCharts: ChartsList = {
  craNegotiations: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante Negociado',
        type: 'column',
        data: [
          14885931915.31, 16088291336.23, 18968395136.21, 45904664477.03,
          53824769517.98,
        ],
      },
      {
        name: 'Número de negócios',
        type: 'line',
        data: [126221, 161552, 181336, 469395, 848991],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
    stacked: false,
  },

  criNegotiations: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante Negociado',
        type: 'column',
        data: [
          16493611130.12, 40172559456.14, 72938165904.02, 80616006620.13,
          99898005676.29,
        ],
      },
      {
        name: 'Número de negócios',
        type: 'line',
        data: [73753, 103730, 179973, 344287, 631009],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
    stacked: false,
  },
};

export const crsStockCharts: ChartsList = {
  stockCra: {
    chartType: 'column',
    data: [
      {
        name: 'Soma de saldo devedor',
        data: [
          64150240486.04, 97688343958.02, 120487192613.02, 134906695350.57,
        ],
      },
    ],
    categories: ['12/21', '12/22', '12/23', '12/24'],
    numeric: true,
    stacked: false,
  },
  stockCri: {
    chartType: 'column',
    data: [
      {
        name: 'Soma de saldo devedor',
        data: [
          83758638319.36, 107366592312.81, 145803938180.65, 163566057690.83,
          187550383954.23,
        ],
      },
    ],
    categories: ['12/20', '12/21', '12/22', '12/23', '12/24'],
    numeric: true,
    stacked: false,
  },
};
