import { Box, Typography } from '@mui/material';

import { SponsorsGrid } from './SponsorsGrid';
import { sponsors } from '../../data/presentation';
import { MarketName, SponsorLevel } from '../../types';

const sponsorsColors = {
  [SponsorLevel.DIAMOND]: '#9ac5db',
  [SponsorLevel.PLATINUM]: '#C0C0C0',
  [SponsorLevel.GOLD]: '#CD7F32',
  [SponsorLevel.SILVER]: '#B0B0B0',
  [SponsorLevel.BRONZE]: '#8B4513',
};

type SponsorsProps = {
  market: MarketName;
};

export function Sponsors({ market }: SponsorsProps) {
  return (
    <Box
      component="section"
      data-yearbook-section="Patrocinadores"
      sx={({ breakpoints }) => ({
        maxWidth: breakpoints.values.lg,
        display: 'flex',
        flexDirection: 'column',
        mx: 'auto',
        mt: 4,
        gap: 1,
        p: 2,
        [breakpoints.down(1480)]: {
          ml: '130px',
        },
        [breakpoints.down('lg')]: {
          ml: 0,
        },
        [breakpoints.down('md')]: {
          mt: 2,
        },
      })}>
      <Typography variant="h3">Patrocinadores</Typography>
      <Typography variant="h6" color="#8D8D8D" textTransform="uppercase">
        Anuários Uqbar 2025
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
        {Object.keys(sponsorsColors).map((color) => (
          <SponsorsGrid
            key={color}
            sponsors={sponsors}
            color={sponsorsColors[color as SponsorLevel]}
            market={market}
            level={color as SponsorLevel}
          />
        ))}
      </Box>
    </Box>
  );
}
