import React, { useEffect, useState } from 'react';

import {
  MenuItem,
  MenuList,
  Paper,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSectionInScreen } from 'src/utils/hooks';

import { availableMarkets } from '../../constants';
import { MarketName } from '../../types';
import { navigateToSection } from '../../utils';

type SideMenuProps = {
  market: MarketName;
  titleComponent?: React.ReactNode;
  sx?: SxProps<Theme>;
  disableNavigation?: boolean;
};

export function SideMenu({
  market,
  sx,
  titleComponent,
  disableNavigation = false,
}: SideMenuProps) {
  const [selectedMarket, setSelectedMarket] = useState<MarketName | null>(
    market || 'CRs',
  );
  const currentSection = useSectionInScreen();
  const history = useHistory();

  function selectMarket(market: MarketName) {
    navigateToSection('Inicio');
    history.push(`/anuarios/2025/${market}/Inicio`);
    setSelectedMarket(market);
  }

  useEffect(() => {
    if (disableNavigation) return;

    if (market) {
      if (currentSection && currentSection !== 'Inicio') {
        history.replace(`/anuarios/2025/${market}/${currentSection}`);
      } else {
        history.replace(`/anuarios/2025/${market}/Inicio`);
      }
    }
  }, []);

  useEffect(() => {
    if (market) {
      setSelectedMarket(market);
    }
  }, [market]);

  return (
    <Paper
      elevation={0}
      component={'aside'}
      sx={{
        ...sx,
      }}>
      {titleComponent}
      <MenuList
        sx={{
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}>
        {availableMarkets.map(({ label, Icon }, _) => (
          <MenuItem
            key={label}
            onClick={() => selectMarket(label as MarketName)}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              minHeight: 'auto',
              gap: 1,
              backgroundColor:
                selectedMarket === label ? 'primary.main' : 'transparent',
              color: selectedMarket === label ? 'white' : 'primary.main',
              '&:hover': {
                backgroundColor:
                  selectedMarket === label ? 'primary.main' : '#f0f0f0',
                color: selectedMarket === label ? 'white' : 'primary.main',
                cursor: 'pointer',
                '& > p': {
                  color: selectedMarket === label ? 'white' : 'primary.main',
                },
              },
              borderRadius: 2,
              pl: 1,
            }}>
            <Icon />
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
              }}
              color={selectedMarket === label ? 'white' : 'primary.main'}>
              {label}
            </Typography>
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
}
