import axios from 'axios';

import { advertisements, markets } from './data/presentation';
import { RankingWidgetParams, VimeoVideo } from './types';

export function getMarketByName(marketName: string) {
  return markets.find(({ name }) => marketName === name)?.name;
}

export function getSectionElement(sectionName: string) {
  const element = document.querySelector(
    `[data-yearbook-section="${sectionName}"]`,
  );
  return element;
}

export function getAdvertisementByPosition(marketName: string, pos: number) {
  return advertisements[marketName][pos - 1];
}

export function scrollSmoothToElement(el: Element | null, margin = 0) {
  window.scrollTo({
    behavior: 'smooth',
    top: window.scrollY + (el?.getBoundingClientRect().top ?? 0) + margin,
  });
}

export function getYearbookSectionsName(attribute = 'data-yearbook-section') {
  const sections = [...document.querySelectorAll('section')];

  const yearbookSections = sections.filter((section) =>
    section.hasAttribute(attribute),
  );

  return yearbookSections.map((section) =>
    section.getAttribute(attribute),
  ) as string[];
}

export function extractRankingParams(url: string): RankingWidgetParams | null {
  const paramsMatch = url.match(/www\.uqbar\.com\.br\/rankings\/dados\?(.*)/);

  if (paramsMatch) {
    const params = paramsMatch[1].split('&').map((i) => i.split('='));

    return Object.fromEntries(params);
  }

  return null;
}

export function getVideoEmbedUrl(videoId: string) {
  return `https://player.vimeo.com/video/${videoId}`;
}

export async function getVideoInfo(
  videoId: string,
): Promise<VimeoVideo | null> {
  const { data } = await axios.get<VimeoVideo[]>(
    `https://vimeo.com/api/v2/video/${videoId}.json`,
  );

  if (data.length > 0) {
    return data[0];
  }

  return null;
}

export async function getVideoThumbnailUrl(videoId: string): Promise<string> {
  const { data } = await axios.get(
    `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`,
  );
  return data.thumbnail_url;
}

export function getArticleAuthorId(linkUrl: string) {
  return linkUrl.split('/').slice(-1)[0];
}

export function navigateToSection(
  sectionName: string,
  margin = 0,
  wait?: boolean,
) {
  if (wait) {
    const observer = new MutationObserver(() => {
      const element = getSectionElement(sectionName);
      if (element) {
        scrollSmoothToElement(element, margin);
        observer.disconnect();
      }
    });
    observer.observe(document, { subtree: true, childList: true });
  } else {
    const element = getSectionElement(sectionName);
    if (element) {
      scrollSmoothToElement(element, margin);
    }
  }
}

export function numberToMinutes(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}
