import { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import IntroBg from '../../../../../assets/imgs/yearbook/2025/intro-bg.png';
import IntroLogo from '../../../../../assets/imgs/yearbook/2025/intro-logo.png';
import { CrIcon } from '../../components/Icons/CR';
import { FIAGROIcon } from '../../components/Icons/FIAGRO';
import { FIDCIcon } from '../../components/Icons/FIDC';
import { FIIIcon } from '../../components/Icons/FII';
import { MarketName } from '../../types';
import { getMarketByName } from '../../utils';

const marketIcons = [
  {
    label: 'CRs',
    Icon: CrIcon,
  },
  {
    label: 'FII',
    Icon: FIIIcon,
  },
  {
    label: 'FIDC',
    Icon: FIDCIcon,
  },
  {
    label: 'FIAGRO',
    Icon: FIAGROIcon,
  },
];

type IntroProps = {
  market: MarketName | undefined;
};

export function Intro({ market }: IntroProps) {
  const [selectedMarket, setSelectedMarket] = useState<MarketName | null>(
    market || 'CRs',
  );
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (market) {
      const marketName = getMarketByName(market);

      if (marketName) setSelectedMarket(marketName);
    }
  }, [market]);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    window.requestAnimationFrame(() => {
      setMousePosition({
        x: (e.clientX / window.innerWidth - 0.5) * 0.5,
        y: (e.clientY / window.innerHeight - 0.5) * 0.5,
      });
    });
  }, []);

  const handleScroll = useCallback(() => {
    window.requestAnimationFrame(() => {
      setScrollPosition(window.scrollY * 0.1);
    });
  }, []);

  useEffect(() => {
    let mouseMoveThrottle: ReturnType<typeof setTimeout> | null = null;
    let scrollThrottle: ReturnType<typeof setTimeout> | null = null;

    const throttledMouseMove = (e: MouseEvent) => {
      if (!mouseMoveThrottle) {
        mouseMoveThrottle = setTimeout(() => {
          mouseMoveThrottle = null;
          handleMouseMove(e);
        }, 20);
      }
    };

    const throttledScroll = () => {
      if (!scrollThrottle) {
        scrollThrottle = setTimeout(() => {
          scrollThrottle = null;
          handleScroll();
        }, 20);
      }
    };

    window.addEventListener('mousemove', throttledMouseMove);
    window.addEventListener('scroll', throttledScroll);

    handleScroll();

    return () => {
      window.removeEventListener('mousemove', throttledMouseMove);
      window.removeEventListener('scroll', throttledScroll);
      if (mouseMoveThrottle) clearTimeout(mouseMoveThrottle);
      if (scrollThrottle) clearTimeout(scrollThrottle);
    };
  }, [handleMouseMove, handleScroll]);

  const transform = `translate3d(${mousePosition.x * 50}px, ${mousePosition.y * 50 - scrollPosition}px, 0px)`;

  return (
    <Box
      component={'section'}
      data-yearbook-section="Inicio"
      sx={({ breakpoints }) => ({
        width: '100%',
        height: '100dvh',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingRight: '200px',
        [breakpoints.down('lg')]: {
          paddingRight: '100px',
        },
        [breakpoints.down('md')]: {
          padding: 4,
        },
      })}>
      <Box
        sx={({ breakpoints }) => ({
          position: 'absolute',
          top: 20,
          left: 0,
          right: 0,
          bottom: 0,
          width: '120%',
          height: '120%',
          margin: '-10% 0 0 -10%',
          backgroundSize: 'cover',
          backgroundImage: `url(${IntroBg})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          transform,
          transition: 'transform 0.05s linear',
          zIndex: 0,
          willChange: 'transform',
          [breakpoints.down('lg')]: {
            width: '130%',
            height: '130%',
            margin: '-15% 0 0 -15%',
          },
          [breakpoints.down('md')]: {
            width: '150%',
            height: '150%',
            margin: '-25% 0 0 -25%',
          },
        })}
      />
      <Box
        component={'img'}
        src={IntroLogo}
        alt="Intro"
        sx={({ breakpoints }) => ({
          width: '50%',
          position: 'relative',
          zIndex: 1,
          [breakpoints.down('md')]: {
            width: '70%',
          },
          [breakpoints.down('sm')]: {
            width: '100%',
          },
        })}
      />
      <Box
        sx={{
          width: '220px',
          position: 'relative',
          zIndex: 1,
        }}>
        <Typography
          variant="h1"
          sx={({ breakpoints }) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 1,
            color: 'white',
            width: '220px',
            textAlign: 'right',
            [breakpoints.down('lg')]: {
              fontSize: '3rem',
            },
            [breakpoints.down('md')]: {
              fontSize: '2.5rem',
            },
          })}>
          {(() => {
            const IconComponent = marketIcons.find(
              (market) => market.label === selectedMarket,
            )?.Icon;
            return IconComponent ? (
              <IconComponent
                sx={({ breakpoints }) => ({
                  fontSize: '2.5rem',
                  [breakpoints.down('lg')]: {
                    fontSize: '2rem',
                  },
                })}
              />
            ) : null;
          })()}
          {selectedMarket}
        </Typography>
      </Box>
    </Box>
  );
}
