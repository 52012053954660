import { useMemo } from 'react';

import { Box, lighten, Typography } from '@mui/material';

import { openings } from '../../data/presentation';
import { MarketName } from '../../types';

type OpeningProps = {
  market: MarketName;
};

export function Opening({ market }: OpeningProps) {
  const whiteParagraphs = useMemo(() => openings[market ?? 'CRs'], [market]);

  return (
    <Box
      component="section"
      data-yearbook-section="Abertura"
      sx={{
        background: `linear-gradient(to right, #ff8c00, ${lighten(
          '#ff8c00',
          0.1,
        )})`,
        px: 4,
        py: 6,
      }}>
      <Box
        sx={({ breakpoints }) => ({
          maxWidth: breakpoints.values.lg,
          display: 'flex',
          flexDirection: 'column',
          mx: 'auto',
          gap: 4,
          [breakpoints.down(1480)]: {
            ml: '130px',
          },
          [breakpoints.down('lg')]: {
            ml: 0,
          },
        })}>
        <Typography variant="h2" color="#131313" fontWeight={900}>
          Abertura
        </Typography>

        <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
          {whiteParagraphs.map((paragraph, idx) => (
            <Typography
              fontSize="1.25rem"
              variant="body1"
              component="p"
              fontWeight={600}
              key={idx}
              color="#131313">
              {paragraph}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
