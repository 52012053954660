import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Test from 'react-test-attributes';
import { setBannerMessage } from 'src/store/display';
import * as Yup from 'yup';

import { forgotPassword } from '../../services/auth';
import { FieldTitle } from '../Login/styles';

const FORM_VALIDATION = Yup.object().shape({
  identifier: Yup.string().required('Campo obrigatório'),
});

export default function PasswordRecovery() {
  const dispatch = useDispatch();
  const history = useHistory();
  const requestPasswordRecoveryForAccount = useMutation(forgotPassword, {
    onError: (error: any) => {
      if (error?.response?.data?.error?.name === 'ValidationError') {
        formik.setErrors(error?.response?.data?.error?.details);
      } else if (error?.response?.data?.error?.message?.includes('bloqueado')) {
        dispatch(
          setBannerMessage({
            message: error?.response?.data?.error?.message,
            type: 'error',
            autoclose: false,
          }),
        );
      } else {
        dispatch(
          setBannerMessage({
            message: 'Erro ao enviar e-mail, tente novamente',
            type: 'error',
            autoclose: false,
          }),
        );
      }
    },
    onSuccess: () => {
      dispatch(
        setBannerMessage({
          message: 'E-mail enviado com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
    },
  });

  const formik = useFormik({
    initialValues: { identifier: '' },
    validationSchema: FORM_VALIDATION,
    onSubmit: (value) => {
      requestPasswordRecoveryForAccount.mutate(value);
    },
  });

  return (
    <Box
      sx={(theme) => ({
        paddingTop: '75px',
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
          paddingTop: '130px',
        },
      })}
      component="section">
      <Container
        maxWidth="sm"
        sx={{
          height: 'calc(100vh - 75px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}>
        <Typography
          sx={(theme) => ({
            fontSize: '40px',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
              fontSize: '32px',
            },
          })}>
          Esqueceu sua senha?
        </Typography>
        <Typography textAlign="center">
          Não se preocupe, insira seu e-mail de cadastro para que a gente envie
          o link de recuperação de senha.
        </Typography>
        <Box maxWidth={370}>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="center">
              <Grid item xs={12}>
                <FieldTitle>E-mail</FieldTitle>
                <FormControl fullWidth variant="outlined">
                  {/* @ts-ignore */}
                  <Test id="recovery-form-email">
                    <TextField
                      name="identifier"
                      placeholder="Insira seu e-mail aqui"
                      value={formik.values.identifier}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.identifier &&
                        Boolean(formik.errors.identifier)
                      }
                      helperText={
                        formik.touched.identifier && formik.errors.identifier
                      }
                    />
                  </Test>
                </FormControl>
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <Test id="btn-password-recovery">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ fontSize: '16px', fontWeight: 700 }}>
                    {requestPasswordRecoveryForAccount.isLoading ? (
                      <CircularProgress sx={{ color: '#FFFFFF' }} />
                    ) : requestPasswordRecoveryForAccount.isSuccess ? (
                      'Reenviar e-mail'
                    ) : (
                      'Enviar e-mail'
                    )}
                  </Button>
                </Test>
              </Grid>
            </Grid>
          </form>
        </Box>
        <Typography textAlign="center">
          Caso não tenha recebido o e-mail de validação, mande um e-mail para:
          comercial@uqbar.com.br
        </Typography>
        <Button
          onClick={() => history.push('/login')}
          startIcon={<ArrowBackIcon />}
          sx={{
            color: '#4F4F4F',
            fontSize: '1rem',
            fontWeight: 400,
            textTransform: 'none',
            letterSpacing: 0,
            justifyContent: 'flex-start',
            '&:hover': {
              background: 'transparent',
            },
          }}>
          Voltar para tela de login
        </Button>
      </Container>
    </Box>
  );
}
