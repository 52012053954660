import { ChartsList } from '../../types';

const categoriesDates: string[] = [
  '01/20',
  '02/20',
  '03/20',
  '04/20',
  '05/20',
  '06/20',
  '07/20',
  '08/20',
  '09/20',
  '10/20',
  '11/20',
  '12/20',
  '01/21',
  '02/21',
  '03/21',
  '04/21',
  '05/21',
  '06/21',
  '07/21',
  '08/21',
  '09/21',
  '10/21',
  '11/21',
  '12/21',
  '01/22',
  '02/22',
  '03/22',
  '04/22',
  '05/22',
  '06/22',
  '07/22',
  '08/22',
  '09/22',
  '10/22',
  '11/22',
  '12/22',
  '01/23',
  '02/23',
  '03/23',
  '04/23',
  '05/23',
  '06/23',
  '07/23',
  '08/23',
  '09/23',
  '10/23',
  '11/23',
  '12/23',
  '01/24',
  '02/24',
  '03/24',
  '04/24',
  '05/24',
  '06/24',
  '07/24',
  '08/24',
  '09/24',
  '10/24',
  '11/24',
  '12/24',
];

export const fidcPrimaryMarketCharts: ChartsList = {
  emissionsFIDC: {
    chartType: 'column',
    data: [
      {
        name: 'Montante emitido',
        data: [
          73499073185.62, 115742896131.13, 102834502522.52, 160473500443.51,
          221815653039.08,
        ],
      },
    ],
    categories: ['2020', '2021', '2022', '2023', '2024'],
    numeric: true,
    stacked: false,
  },
  emissionsFIDCByCondominumType: {
    chartType: 'column',
    data: [
      {
        name: 'Condomínio Aberto',
        data: [
          22578770690.75, 37718888352.83, 34261822650.91, 39191715852.59,
          72129942811.95,
        ],
      },
      {
        name: 'Condomínio Fechado',
        data: [
          50920302494.87, 78024007778.3, 68572679871.61, 121281784590.92,
          149685710227.13,
        ],
      },
    ],
    categories: ['2020', '2021', '2022', '2023', '2024'],
    numeric: false,
    stacked: true,
  },
  emissionsFIDCByFundType: {
    chartType: 'column',
    data: [
      {
        name: 'Padronizado',
        data: [
          49984556684.21, 85869875457.24, 61279209080.71, 115436661689.98,
          185703670526.08,
        ],
      },
      {
        name: 'Não Padronizado',
        data: [
          23514516501.41, 29873020673.89, 41555293441.81, 45036838753.53,
          35973388429.24,
        ],
      },
    ],
    categories: ['2020', '2021', '2022', '2023', '2024'],
    numeric: false,
    stacked: true,
  },
  emissionsFIDCByProductType: {
    chartType: 'column',
    data: [
      {
        name: 'Ações Judiciais e Precatórios',
        data: [
          721789163.72, 4671271271.98, 7237009587.78, 11626739155.41,
          8679721621.57,
        ],
      },
      {
        name: 'Cotas de FIDC',
        data: [
          159760149.51, 381606239.09, 40600000, 5093777796.78, 16510652861.41,
        ],
      },
      {
        name: 'Crédito Imobiliário',
        data: [
          712050836.16, 914143277.43, 948056941.93, 1684583894.45,
          1050673456.46,
        ],
      },
      {
        name: 'Crédito Pessoa Jurídica',
        data: [
          5824702449.54, 15319552528.46, 7563296683.72, 18570298739.52,
          25977919347.6,
        ],
      },
      {
        name: 'Crédito Pessoal',
        data: [
          8956790679.24, 9017545459.65, 20027046163.57, 27552046075.15,
          35363812674.9,
        ],
      },
      {
        name: 'Direitos',
        data: [
          1467493376.65, 383437119.47, 1036021218.08, 996643921.62,
          641706296.93,
        ],
      },
      {
        name: 'FIAGRO',
        data: [
          16898614.83, 72522549.27, 1337614222.87, 4572158814.51, 2869729266.24,
        ],
      },
      {
        name: 'Financiamento de Veículos',
        data: [
          641183997.62, 5473963833.54, 2887333504.35, 7318005008.53,
          13891736406.34,
        ],
      },
      {
        name: 'Multiclasse',
        data: [
          8065884147.85, 23861604167.67, 10486999093.72, 14192574526.12,
          25577209783.12,
        ],
      },
      {
        name: 'Prestação de Serviço Público',
        data: [
          1485751320.82, 114655987.86, 492975410.93, 134035142.7, 3717749460.33,
        ],
      },
      {
        name: 'Recebíveis Comerciais',
        data: [
          30204745397.94, 35520721433.38, 32940376432.77, 55538306370.2,
          71788816375.22,
        ],
      },
      {
        name: 'Recebíveis do Agronegócio',
        data: [
          2247405585.0, 4039666884.15, 13286793665.51, 7221217044.97,
          5854930243.47,
        ],
      },
      {
        name: 'Recebíveis Educacionais',
        data: [
          119050000.0, 274215534.99, 78262025.77, 639795388.79, 966969837.7,
        ],
      },
      {
        name: 'Setor Público',
        data: [
          11047283527.24, 13438978495.35, 3876232128.11, 1971862318.65,
          717061107.32,
        ],
      },
      {
        name: 'Títulos Mobiliários',
        data: [5000000.0, 1956665533.41, 0.0, 0.0, 2350217459.89],
      },
      {
        name: 'Recebíveis Médicos',
        data: [
          1673283939.5, 43739903.31, 51441496.08, 43648609.38, 133546269.49,
        ],
      },
      {
        name: 'Aluguel',
        data: [0.0, 139305738.9, 38180900.0, 61346610.49, 123975224.81],
      },
      {
        name: 'Tributos',
        data: [0.0, 119300173.22, 506263047.33, 978054549.11, 162234371.44],
      },
    ],
    categories: ['2020', '2021', '2022', '2023', '2024'],
    numeric: true,
    stacked: true,
  },
};

export const fidcSecondaryMarketCharts: ChartsList = {
  negotiatedQuotasInFIDC: {
    chartType: 'line-column',
    data: [
      {
        type: 'column',
        name: 'Montante Negociado',
        data: [
          16997939284.47, 12066834216.34, 9800274251.47, 18723534625.68,
          19113992174.99,
        ],
      },
      {
        type: 'line',
        name: 'Número de negócios',
        data: [19985, 14049, 141348, 298987, 415202],
      },
    ],
    categories: ['2020', '2021', '2022', '2023', '2024'],
    numeric: true,
    stacked: false,
  },
  negotiatedQuotasInFIDCMonthly: {
    chartType: 'line-column',
    data: [
      {
        type: 'column',
        name: 'Montante Negociado',
        data: [
          970182049.64, 1288965220.63, 1444619191.56, 2435056684.27,
          2145619500.72, 2075853345.82, 1780107969.72, 1637541872.74,
          1355016915.22, 1600815103.15, 1341052744.83, 1039161576.69,
        ],
      },
      {
        type: 'line',
        name: 'Número de negócios',
        data: [
          27000, 24045, 44859, 25571, 31000, 33338, 52386, 39652, 33048, 43796,
          23791, 36716,
        ],
      },
    ],
    categories: [
      'Jan/2024',
      'Fev/2024',
      'Mar/2024',
      'Abr/2024',
      'Mai/2024',
      'Jun/2024',
      'Jul/2024',
      'Ago/2024',
      'Set/2024',
      'Out/2024',
      'Nov/2024',
      'Dez/2024',
    ],
    numeric: true,
    stacked: false,
  },
  negotiatedQuotasInFIDCExchange: {
    chartType: 'line-column',
    data: [
      {
        type: 'column',
        name: 'Montante Negociado',
        data: [168477964.36, 18945514210.63],
      },
      {
        type: 'line',
        name: 'Número de negócios',
        data: [368344, 46858],
      },
    ],
    categories: ['B3', 'Cetip'],
    numeric: true,
    stacked: false,
  },
};

export const fidcStockMarketCharts: ChartsList = {
  plFidc: {
    chartType: 'line-column',
    data: [
      {
        name: 'Soma de PL',
        type: 'column',
        data: [
          156785094632.63, 164125732132.48, 164827054097.2, 165274910565.75,
          163800792043.93, 163061440600.65, 165241679249.43, 164949516789.04,
          166660997389.5, 168471397939.06, 190849894817.91, 197198571879.97,
          192093670558.79, 197413423539.57, 209972972515.04, 215629339424.7,
          228176948479.02, 231712291921.55, 219442950382.19, 232605238586.72,
          236898925177.87, 231760393083.83, 230979038687.98, 244285436157.68,
          245987968282.66, 251399839969.78, 256139773152.28, 261557136228.78,
          265975601290.15, 275083291878.83, 282259219493.53, 291956038845.2,
          297363349303.63, 301286858789.9, 308373044404.72, 330771695978.73,
          332542786315.41, 334191456856.59, 339865576522.55, 343822427341.92,
          348124533362.53, 366940640015.22, 376462656927.63, 382987583715.86,
          394114978716.34, 392592843714.26, 401154819719.68, 447239540994.18,
          453832779850.63, 461032480681.91, 476157362252.88, 488297336667.62,
          505825790933.38, 520715686561.59, 535846299634.07, 545419172026.77,
          563447907888.73, 585319752531.24, 595965668603.05, 635926878656.22,
        ],
      },
      {
        name: 'Núm. de FIDC',
        type: 'line',
        data: [
          924, 931, 942, 949, 963, 964, 984, 996, 1003, 1017, 1167, 1197, 1196,
          1209, 1245, 1269, 1306, 1334, 1343, 1373, 1415, 1439, 1447, 1483,
          1499, 1521, 1548, 1589, 1614, 1643, 1680, 1704, 1751, 1780, 1822,
          1879, 1900, 1915, 1966, 1996, 2008, 2032, 2050, 2067, 2222, 2218,
          2222, 2354, 2380, 2404, 2449, 2492, 2574, 2633, 2696, 2761, 2825,
          2941, 2978, 3046,
        ],
      },
    ],
    categories: categoriesDates,
    numeric: true,
    stacked: false,
    animated: false,
  },

  plFIDCByCondominumType: {
    chartType: 'column',
    data: [
      {
        name: 'Condomínio Aberto',
        data: [
          26789480152.06, 27443321503.41, 27627921162.69, 29767988472.16,
          28960774277.2, 29605928591.73, 29835884068.81, 27863776742.8,
          28635374835.64, 29053727480.2, 32222855446.89, 32591385778.89,
          32844434415.71, 32979625930.24, 33191345721.1, 33331922552.35,
          33464315022.63, 34608502443.29, 35032981425.88, 35467796122.27,
          35980938394.19, 36598676733.69, 35751345203.98, 38625258205.04,
          36606322308.08, 37541228593.43, 37878864641.41, 40634480922.09,
          40782939365.7, 44701983482.77, 45713951301.49, 49385871285.79,
          49093177307.99, 49793634960.52, 51516525316.4, 62491929340.7,
          61011226909.81, 63030157272.46, 65761696040.13, 66163290650.31,
          66639512361.56, 69228861021.02, 74109636991.1, 75551580201.79,
          76274719819.23, 71297016876.41, 75446163824.74, 84802523000.22,
          83823955437.74, 86027978509.2, 90904069491.42, 94206176359.14,
          98912786926.21, 103462007651.71, 108504382174.19, 113362735026.69,
          116343645772.44, 124691657758.93, 121809589987.31, 134677149677.43,
        ],
      },

      {
        name: 'Condomínio Fechado',
        data: [
          129995614480.57, 136682410629.07, 137199132934.51, 135506922093.59,
          134840017766.73, 133455512008.92, 135405795180.62, 137085740046.24,
          138025622553.86, 139417670458.86, 158627039371.02, 164607186101.08,
          159249236143.08, 164433797609.33, 176781626793.94, 182297416872.35,
          194712633456.39, 197103789478.26, 184409968956.31, 197137442464.45,
          200917986783.68, 195161716350.14, 195227693484.0, 205660177952.64,
          209381645974.58, 213858611376.35, 218260908510.87, 220922655306.69,
          225192661924.45, 230381308396.06, 236545268192.04, 242570167559.41,
          248270171995.64, 251493223829.38, 256856519088.32, 268279766638.03,
          271531559405.6, 271161299584.13, 274103880482.42, 277659136691.61,
          281485021000.97, 297711778994.2, 302353019936.53, 307436003514.07,
          317840258897.11, 321295826837.85, 325708655894.94, 362437017993.96,
          370008824412.89, 375004502172.71, 385253292761.46, 394091160308.48,
          406913004007.17, 417253678909.88, 427341917459.88, 432056437000.08,
          447104262116.29, 460628094772.31, 474156078615.74, 501249728978.79,
        ],
      },
    ],
    categories: categoriesDates,
    numeric: false,
    stacked: true,
    animated: false,
  },

  plFIDCByFundType: {
    chartType: 'column',
    data: [
      {
        name: 'Padronizado',
        data: [
          90295447937.01, 95338862591.45, 96166161525.02, 94426945322.62,
          92767376615.28, 90351516049.88, 91043828012.94, 91799674023.68,
          92488885782.89, 93225662207.3, 104527994243.82, 108910578647.89,
          109563521448.03, 112281807423.61, 117658935872.63, 119394341373.23,
          130099236543.58, 133366338610.15, 133612201439.56, 136811003688.13,
          137856543556.83, 140884234974.5, 136578180985.77, 146003470253.42,
          149806703548.0, 153240827659.93, 156683381363.3, 160812121204.59,
          164621541488.73, 168477864268.03, 173423825624.12, 178149703856.77,
          183133028729.51, 187397280055.54, 193344641396.08, 203516709146.93,
          203749941125.86, 205347302318.28, 209673365953.41, 211157700432.45,
          212334388863.07, 227564617000.91, 227876927039.89, 229863744488.04,
          235434630387.21, 236869977752.11, 239473225812.53, 269226541535.18,
          271677081295.9, 278016547546.96, 289189150049.12, 296779124609.98,
          311435175627.36, 321635546289.44, 330888903527.4, 335288902255.83,
          346665535762.57, 364817734176.31, 385124538318.68, 403200355436.19,
        ],
      },

      {
        name: 'Não Padronizado',
        data: [
          66489646695.62, 68786869541.03, 68660892572.18, 70847965243.13,
          71033415428.65, 72709924550.77, 74197851236.49, 73149842765.36,
          74172111606.61, 75245735731.76, 86285539872.79, 88249783596.3,
          82492735648.62, 85093559042.9, 92271745257.17, 96192564410.25,
          98035739816.53, 98296915987.51, 85781412058.96, 95745242005.06,
          98993043362.49, 90826316339.67, 94390228163.65, 98271203202.33,
          96170378164.22, 98148001793.4, 99445253152.56, 100733766361.99,
          101342680272.73, 106593928150.18, 108823774665.0, 113794584821.5,
          114218441690.63, 113877575837.14, 115016299704.8, 127242738188.06,
          128780452144.0, 128831650308.28, 130179549348.38, 132651947380.78,
          135777279309.82, 139363575841.04, 148573599550.77, 153123839227.82,
          158680348329.13, 155722865962.15, 161681593907.15, 178012999459.0,
          182155698554.73, 183015933134.95, 186968212203.76, 191518212057.64,
          194390615306.02, 199080140272.15, 204957396106.67, 210130269770.94,
          216782372126.16, 219510915532.73, 209687797765.52, 231591716737.36,
        ],
      },
    ],
    categories: categoriesDates,
    numeric: false,
    stacked: true,
    animated: false,
  },

  plFIDCByProduct: {
    chartType: 'column',
    data: [
      {
        name: 'Ações Judiciais e Precatórios',
        data: [
          5640007457.87, 5672277944.98, 5409639105.24, 5421439548.57,
          5459346518.32, 5540458748.01, 5586475363.12, 5792913834.25,
          5866655041.86, 5835641431.62, 5985308307.26, 5650881858.59,
          5881056910.92, 6034869332.28, 6467958238.46, 6587781223.46,
          6695821067.62, 6637692892.34, 8906920669.05, 8912267978.77,
          9795471340.31, 9811566083.16, 10401362320.35, 11462373050.76,
          12189249254.65, 12339676910.44, 12632287676.1, 10775939252.53,
          10962403257.05, 13245205484.13, 13316971983.18, 13904566351.76,
          14392619855.13, 13491874365.66, 13952301147.92, 14957612981.73,
          15711810451.78, 16520924392.82, 16039405337.14, 15891387493.41,
          17891872919.19, 18897962100.99, 19551200664.45, 19551634269.25,
          20298620686.51, 20566159696.5, 21866654035.47, 24183778480.72,
          24683374948.53, 24784944707.28, 25452888600.08, 25953671797.89,
          26605980046.57, 28140813649.55, 29157407293.77, 30442938806.54,
          31885511548.26, 33749306906.88, 33944163123.51, 35586620327.32,
        ],
      },
      {
        name: 'Cotas de FIDC',
        data: [
          286241858.61, 292883548.03, 341293251.47, 258991393.08, 198390205.96,
          203241851.22, 191666284.57, 107236546.89, 119848498.84, 9084682.36,
          17929441016.44, 18626081470.33, 17236893056.47, 18829204864.96,
          19117038977.08, 19020570853.69, 19204315033.7, 19523141568.75,
          19655224829.99, 19752902487.02, 20046051357.21, 20143296293.01,
          16163858041.46, 17447005061.03, 17586631358.96, 17647560519.31,
          18214420413.51, 19287573559.19, 19425831829.81, 20531562917.74,
          20570348311.42, 21174132678.65, 21244440258.34, 21364017548.54,
          21471436716.65, 22051625511.88, 22998579598.16, 23302434599.29,
          23900682718.85, 24314071053.87, 24722517108.12, 26236769856.3,
          26657940112.49, 27557441303.98, 29152100791.72, 29342996468.23,
          26136499649.76, 32795593506.56, 35691085989.18, 34068663114.19,
          35261181024.96, 37570311901.14, 38942134341.4, 42219974088.54,
          43256030440.87, 46337113056.17, 49769142044.66, 54962717261.7,
          60561742748.28, 67412848901.35,
        ],
      },
      {
        name: 'Crédito Imobiliário',
        data: [
          2300637093.65, 2408533348.65, 2449829983.78, 2449695484.33,
          2520871525.71, 2525656956.48, 2550646155.66, 2594295748.42,
          2524380817.94, 2534852163.88, 2526320552.48, 2532647045.82,
          2575826590.49, 2608413071.72, 2547162631.47, 2620267295.91,
          2648743902.05, 2749327542.3, 2893523204.24, 2945094011.82,
          2922379530.01, 3017070181.64, 3072747990.89, 2759988720.61,
          2799098724.66, 2835291463.07, 2899168072.68, 2932041680.77,
          2984145962.98, 3029852144.93, 3051137263.97, 3317702065.99,
          3310449722.02, 3372207859.96, 3463925375.85, 3523399043.94,
          3551236390.75, 3619909081.47, 3668553482.78, 3663986115.62,
          3621625548.37, 3611338393.17, 3653809151.0, 3692592924.38,
          3731680808.22, 3814539312.56, 3884062408.39, 4184354396.5,
          4078660241.28, 4145430239.23, 4264777478.38, 4111766763.95,
          4256682549.86, 4314086657.0, 4699068818.7, 4827325236.26,
          4824409974.61, 4952960431.37, 4951416766.06, 5047998342.07,
        ],
      },
      {
        name: 'Crédito Pessoa Jurídica',
        data: [
          16661506326.98, 17967314973.27, 18287458667.39, 18795280167.86,
          18038097786.47, 18574653003.74, 18985802632.55, 19531919685.55,
          19929277122.5, 20483471177.58, 20754613870.45, 22323640639.03,
          23007287233.68, 23003805044.87, 22450505127.75, 22750610651.49,
          28481652715.72, 28078300926.21, 25751335642.16, 26177718221.8,
          27164584544.62, 26058023128.7, 22434474931.32, 24494192493.07,
          25669141550.75, 26095733112.52, 26472108278.43, 27041462177.85,
          27520846409.64, 28649362832.0, 29422213510.21, 29926338732.4,
          29412576082.27, 29736725386.36, 31402196492.25, 31807979742.68,
          31809594649.07, 32038003371.12, 33238435103.63, 34007431661.36,
          35721024273.92, 36668858389.91, 38242605943.1, 38886505310.6,
          38282671050.0, 38485593183.15, 38873934593.01, 38256272506.33,
          39835230557.4, 40187792986.62, 42144970342.18, 43824962834.73,
          47845387114.85, 48146569367.83, 49303950301.37, 47716152419.06,
          48230076045.07, 48485381152.56, 49856238618.79, 49200890901.99,
        ],
      },
      {
        name: 'Crédito Pessoal',
        data: [
          8263783800.7, 12004198505.83, 12225702203.37, 12332514889.28,
          12362186111.27, 12197888119.04, 12269746303.78, 12419995173.41,
          12706606587.32, 12815903351.49, 13480799857.68, 14063176137.2,
          14312723317.46, 14491070406.88, 14732783402.56, 15073583144.33,
          15491325805.2, 16916949652.71, 17127168385.35, 18437644114.44,
          18274714908.79, 18822082663.3, 20129681154.44, 21545564522.14,
          22404801547.61, 22281361452.12, 22950103301.31, 24382369967.81,
          25757450829.33, 26392729776.72, 27742186810.71, 28799358276.7,
          27759207299.2, 28434970231.15, 29166367029.41, 29703351639.56,
          30134703771.68, 30306806752.54, 30690864260.13, 31778453732.54,
          32892535091.46, 44894066700.8, 46025953567.71, 47838545467.41,
          50864572927.48, 52020226354.3, 52807366618.7, 58070064298.42,
          59172733504.03, 59975763601.82, 64980700695.19, 65501285208.56,
          70283659559.33, 72182424398.96, 73877734793.43, 74771065878.09,
          79891414915.54, 81922491957.63, 80498965350.59, 78954423630.07,
        ],
      },
      {
        name: 'Direitos',
        data: [
          723548423.04, 535184870.86, 544819431.32, 597862246.62, 655334617.0,
          1107066814.93, 1089584392.75, 1305748909.63, 1292415355.02,
          1853664667.14, 1848329978.37, 1923090961.66, 1882676470.75,
          1906630732.14, 1818943540.75, 1804784355.86, 1875535666.64,
          1863762668.12, 1907077805.66, 1919037634.84, 1934015708.37,
          2011396206.55, 1997951961.68, 1946326679.11, 1955282690.0,
          1923536477.49, 1967935642.53, 2044674019.76, 2081632879.45,
          2127777636.56, 2168965304.88, 2212795644.11, 2649569996.62,
          2616904121.9, 2674335740.53, 2741176482.75, 2722502940.02,
          2731357383.83, 2761956580.67, 2772713071.26, 2727489894.18,
          2861177671.96, 2927737402.2, 2956661000.87, 2935961696.25,
          2962016037.86, 3097728691.56, 3487548760.93, 3548996781.42,
          3451369797.46, 3405001456.34, 3368396905.17, 3402790692.95,
          3281433190.36, 3325236159.89, 3301847634.61, 2983527689.73,
          2747236490.43, 2643250283.03, 2919849658.73,
        ],
      },
      {
        name: 'Financiamento de Veículos',
        data: [
          5853122579.93, 5844192201.31, 5796373268.92, 5459291512.25,
          5178889852.29, 5085798822.42, 4907082008.12, 4928602181.52,
          4965590716.59, 4984175534.51, 4940921579.06, 4992903696.22,
          5010401057.34, 5008030328.97, 4905531362.91, 4892449071.11,
          7626500749.46, 7297967956.58, 7362952508.76, 7224152942.26,
          7026399379.66, 7329679223.28, 8689827676.92, 9526281916.41,
          9537786909.53, 9589449723.76, 9432572868.99, 9637811922.83,
          9595098190.92, 10109942696.99, 10807199186.55, 10856142212.34,
          11004655318.27, 11322530930.51, 11386972436.45, 11667664425.83,
          11407987192.89, 11342028295.64, 11781460146.06, 12026016844.53,
          11910117656.12, 12249858093.23, 12443318585.04, 12184943680.8,
          12665036028.42, 12455348610.93, 12075311853.61, 12285470509.47,
          12184102306.84, 12140106189.39, 12401267472.78, 12374024335.44,
          12357203184.41, 12364863410.52, 12475372730.09, 12683033510.03,
          13719448040.14, 14978387248.26, 15680908607.35, 20924835303.52,
        ],
      },
      {
        name: 'Multiclasse',
        data: [
          18599947514.03, 18755185055.88, 19106413672.26, 20757740346.69,
          20807584663.72, 18528291505.89, 19171912831.07, 19597246751.26,
          19948653387.19, 20682502461.1, 21267271684.36, 21920759350.56,
          22267128396.74, 22546327410.79, 23093563572.91, 23804940903.99,
          24369952075.79, 25484826424.88, 23631011433.3, 24433590598.9,
          25365105198.16, 25774154544.76, 28362357750.98, 30571544581.43,
          31196031122.45, 32351509008.58, 34206360719.7, 35301224336.7,
          36236398582.7, 36624904829.31, 37696881671.43, 38321624508.76,
          38548887596.42, 39387022731.14, 40354319792.0, 41089009639.47,
          41557335051.27, 41965189896.15, 41641877139.13, 42228367927.01,
          42180617596.46, 41769574049.41, 46297448258.73, 47228999441.69,
          47770096013.9, 43622991211.16, 45962290129.85, 50414376277.56,
          51244642990.43, 52435372170.26, 53615792248.05, 54158788699.17,
          54519221139.08, 55911081746.48, 57552002264.62, 61427703703.26,
          62848105824.91, 71318890341.33, 73855952839.83, 82930425860.37,
        ],
      },
      {
        name: 'Prestação de Serviço Público',
        data: [
          5808715190.87, 6070178438.55, 7202112798.12, 7153237536.5,
          7047930516.98, 6668069947.72, 6864929220.78, 6772927223.3,
          6654663708.85, 6545071379.46, 6428340135.81, 6251918480.24,
          6142650589.9, 6138369722.02, 6048408588.43, 5880771498.43,
          5660323213.0, 5412557314.66, 5290341341.25, 4800044284.01,
          5045553626.92, 4914972227.77, 4810159180.59, 4709302468.18,
          4676712621.72, 4497773996.78, 4432496976.01, 4310750412.45,
          4186856983.04, 4032040184.24, 3894054817.72, 3737616565.88,
          3585926409.31, 3596376013.2, 3480631537.16, 3343588724.83,
          3226820272.07, 2989382491.69, 2906975993.71, 2827470678.92,
          2742477060.7, 2564501137.32, 2446907519.93, 2392829792.46,
          2290955959.09, 2265699328.15, 2241014628.63, 2234496406.11,
          2349606080.34, 3080516737.74, 4748253392.05, 6389434492.28,
          6425061465.63, 6848563870.15, 6895050760.38, 6882518290.73,
          6852477536.07, 6908754644.78, 6973355216.71, 6995213008.3,
        ],
      },
      {
        name: 'Recebíveis Comerciais',
        data: [
          54973520746.49, 55552772480.55, 55749535498.55, 54171302351.88,
          53544495837.23, 51275797089.09, 52098362619.82, 52321548830.37,
          52538928496.35, 52880670967.68, 53856625742.96, 56838505327.45,
          57426158459.5, 58917282335.79, 63544764688.54, 64416519137.37,
          66360180716.25, 66610510960.37, 65776031911.04, 67905823505.33,
          69884578425.38, 72880477453.89, 72830711722.31, 77512951229.01,
          77887591312.6, 80257019072.69, 81878392251.9, 84054896832.17,
          84548826347.19, 86673843299.62, 90513697502.12, 95520696964.78,
          99688932366.5, 101049122053.25, 102314515938.71, 110024878270.43,
          110397843526.66, 110548373237.12, 113793521554.05, 114560336941.88,
          115597978453.27, 117524913885.2, 119840033879.55, 120944588022.36,
          123920950591.26, 124991968688.38, 128990533919.13, 146927977766.48,
          146603343501.94, 150082434304.15, 152026228958.15, 156562263834.5,
          160918195472.78, 167168622137.62, 172859752959.47, 175551687537.46,
          180476273500.85, 181424023371.35, 189492494549.55, 205907519297.09,
        ],
      },
      {
        name: 'Recebíveis do Agronegócio',
        data: [
          15145325975.81, 15189674790.68, 15222745228.21, 15356474978.18,
          15404297723.14, 15491510756.65, 15209790212.26, 13408282414.94,
          13649691780.45, 13799017129.2, 15911422542.72, 16470751129.68,
          10816047071.22, 10846923279.04, 16698167982.2, 20585835309.96,
          20540713143.36, 21015431163.78, 11146633579.74, 21131736243.43,
          21395693554.87, 12358597488.98, 13012704487.52, 14899085207.86,
          10908101586.61, 11661356986.11, 11780214615.64, 11707499066.15,
          11832499608.38, 12108510453.91, 11070944569.06, 11768571235.39,
          13023794375.01, 13469660407.34, 15026218309.22, 25082639467.05,
          25091806608.69, 24937693922.93, 25079583355.2, 24643158926.0,
          23192888802.35, 22429347434.32, 21386916851.18, 20990279239.37,
          20741807069.95, 19923510606.79, 20933530906.98, 23510081736.1,
          23410786039.97, 23867270913.37, 23784363217.22, 23713301964.72,
          22334323329.75, 21635614669.26, 20491694905.66, 19563083936.3,
          18863621201.84, 18224708231.23, 20148169230.4, 21772132966.78,
        ],
      },
      {
        name: 'Recebíveis Educacionais',
        data: [
          1166941854.76, 1147698000.75, 1127012196.98, 1088679733.51,
          1059321547.28, 1034418199.37, 1095746673.45, 1073598165.95,
          1077116972.65, 1055180922.64, 1033329735.83, 1016052032.73,
          1042347321.69, 1027002833.65, 1145558490.16, 1118050774.71,
          1109773535.58, 1689890263.15, 1686608711.85, 1850827949.63,
          1841320890.42, 1897632754.87, 1896137197.03, 1912563487.8,
          2095686455.95, 2108307227.1, 2143382215.78, 2190910248.66,
          2208855725.43, 2223587815.96, 2251189827.45, 2521877025.59,
          2535206876.65, 2568732534.83, 2602912001.66, 2636144175.18,
          2672653451.34, 2821990541.31, 3251137111.7, 3496929232.71,
          3529331831.04, 3790382742.06, 3806192561.96, 3994425638.24,
          4005247785.91, 4119801646.52, 4102430659.07, 4498673200.64,
          4624910552.29, 4927515966.71, 4885165976.7, 4928418846.08,
          5006277906.03, 4927506799.02, 5029208991.69, 5074752270.08,
          5233986280.25, 5361312632.22, 5473138036.63, 6399879000.47,
        ],
      },
      {
        name: 'Recebíveis Médicos',
        data: [
          1194482.75, 5444070.76, 5454292.76, 7076427.38, 7113111.61,
          7144896.25, 7186146.23, 8919705.26, 10219475.32, 22611288.12,
          22811548.17, 26773693.81, 31609180.58, 32411060.88, 31233813.57,
          31565947.26, 30987695.23, 37380771.9, 37404010.22, 41491942.65,
          40385371.57, 41565092.39, 50874795.32, 50451876.2, 53067235.13,
          59820843.64, 58380749.67, 62119894.73, 70012719.91, 67959903.65,
          67478790.19, 76999743.45, 76542704.5, 84647682.5, 83995007.44,
          79184365.32, 80056439.51, 84066607.12, 88468365.98, 89522698.53,
          89842740.2, 102395333.02, 125807874.97, 126810848.23, 128578044.5,
          132138710.95, 134121353.84, 133679840.74, 136088049.5, 136513767.96,
          138301677.45, 142161227.24, 258690754.21, 397693491.12, 470619171.18,
          469546637.85, 470602337.48, 477600990.2, 491259357.36, 501455793.95,
        ],
      },
      {
        name: 'Setor Público',
        data: [
          21112960166.3, 22430554808.66, 21108484547.52, 21173763799.99,
          21263182743.03, 24565342335.07, 24945217600.06, 24771799917.1,
          25086625223.85, 24675623905.98, 24578607133.87, 24271922859.38,
          24170476700.02, 25731658735.45, 26120977225.81, 25826339665.38,
          25856631359.68, 26065597390.49, 25928696466.55, 24642943014.14,
          23862964850.18, 24214835701.69, 24472186869.07, 22813908123.72,
          24346969464.13, 24602521346.11, 23756987819.65, 24145492651.15,
          24368557924.82, 25029259576.43, 25305666912.63, 25344345602.89,
          25564820913.81, 25826762006.04, 25804441957.5, 26508827072.37,
          25523282052.17, 25229485779.38, 25067043540.17, 25240939760.74,
          24943433499.39, 26746660400.32, 26205604340.77, 27620511116.58,
          29959365806.14, 30263440516.08, 31868898214.46, 35940506068.05,
          35447829395.7, 36191398981.32, 36830427567.86, 37216154854.32,
          37914442154.49, 37645962316.05, 39474524153.85, 39207625871.12,
          39350951498.71, 38370191484.59, 28746768948.91, 27845657034.22,
        ],
      },
      {
        name: 'Títulos Mobiliários',
        data: [
          227611900.93, 229596693.95, 229888326.42, 231394117.7, 233696519.53,
          235474811.89, 238098563.1, 269965464.74, 240529170.73, 241699929.73,
          232070346.71, 235239946.9, 235821356.73, 236550583.15, 1192229715.31,
          1157367179.77, 2147341077.37, 2133376075.27, 2143044666.86,
          2219878595.81, 2086825434.88, 2233239631.52, 2293558556.61,
          2161137524.19, 2142699345.99, 2158057201.22, 2179087955.85,
          2443269385.39, 2467212401.58, 2373972609.3, 2146295855.48,
          2139966754.22, 2135925621.33, 2114426726.89, 2127881418.68,
          2143816494.02, 2167218287.25, 2189046998.41, 2212876210.72,
          2232157755.68, 2259252029.14, 2274027564.03, 2288054188.1,
          2330664032.03, 2343247543.85, 2331730486.82, 2425119502.44,
          1727743128.04, 1755103323.97, 1738207272.65, 1759228690.11,
          1783152677.2, 3527474755.38, 3998996996.9, 4369554402.96,
          4577983945.32, 4970717668.73, 5481807044.92, 5820446880.07,
          5672498162.32,
        ],
      },
      {
        name: 'FIAGRO',
        data: [
          20029259.91, 20042399.77, 20291624.89, 20166031.93, 20052764.39,
          20626742.88, 29432242.11, 44516236.45, 49795034.04, 52226946.57,
          53680785.74, 54227250.37, 54566845.3, 54873796.98, 58145157.13,
          57902411.98, 77150722.37, 78502756.84, 82279960.44, 88523123.59,
          89202039.53, 127866747.0, 180453441.53, 223957559.44, 281220205.67,
          587326375.67, 663641738.65, 723414038.34, 1145395322.48,
          1213020460.66, 1500745312.68, 1534534223.13, 1599688049.48,
          1798307155.23, 1977942959.05, 2214671106.48, 2267074849.01,
          2322618276.43, 2450693367.26, 2604547121.63, 2611535991.04,
          2802252105.83, 2949068201.5, 2956539413.74, 3144221618.13,
          3325925312.97, 3800734773.65, 4173377516.59, 4374393037.85,
          4738343504.12, 5194697137.13, 5358120561.18, 5396380225.84,
          5852034294.52, 6682208906.89, 6416484935.04, 6769496226.28,
          6935063314.53, 6996645411.66, 7334612384.55,
        ],
      },
      {
        name: 'Aluguel',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 117075593.2, 116695255.73, 121561938.28, 123679016.99,
          123937661.32, 125204367.68, 138317955.98, 143739971.03, 145285583.81,
          144053083.12, 144394153.9, 144823529.4, 142840632.87, 218798863.18,
          241174146.07, 248727826.99, 443824971.27, 463675747.99, 465317621.86,
          464772386.9, 466435710.63, 471089827.7, 491556566.69, 491757465.29,
          498706196.42, 494469410.53, 496621208.13, 494627146.37, 496286068.91,
          491589752.95, 493830669.31, 492101092.65, 497467533.45, 486233162.74,
          491524469.18, 498348291.89, 497862392.04, 508140252.79, 511626003.58,
          482341631.01, 481123959.73, 482519337.43, 470509598.08,
        ],
      },
      {
        name: 'Tributos',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 54786242.28, 110483700.74,
          114156925.22, 258252669.36, 328178772.76, 371292628.4, 438752786.04,
          506918623.81, 514443000.67, 557596113.09, 581378031.78, 608746064.13,
          618974796.25, 730809213.35, 746203639.26, 764304796.78, 809404710.79,
          941712204.23, 986422291.31, 1005847926.16, 1111636741.85,
          1228958148.54, 1377138302.33, 1464290587.3, 1449424527.93,
          1529101889.19, 1539838079.34, 1704743443.22, 1828370396.66,
          1831252163.15, 1898605966.32, 1714193719.36, 1695806596.79,
          1764629771.37, 1713973576.34, 1763301227.25, 1826994482.05,
          1865018951.75,
        ],
      },
    ],
    categories: categoriesDates,
    numeric: false,
    stacked: true,
    animated: false,
  },

  plFidcMM: {
    chartType: 'line-column',
    data: [
      {
        type: 'column',
        name: 'Soma de pl',
        data: [
          21390155386.48, 21530672186.15, 21351643315.57, 19996096425.88,
          19596160380.1, 19291849170.68, 19454350516.64, 19291529161.83,
          19340493750.26, 19687809605.88, 20283674910.35, 20674246424.63,
          20831978530.84, 21184926997.73, 21683279993.38, 22368537983.35,
          23350630092.31, 24220334846.85, 24731729934.38, 25671238288.13,
          26252231665.59, 26764615528.68, 27752260160.94, 29049209394.81,
          29457874255.6, 30263618833.15, 31519892731.05, 32577733138.67,
          33482160241.39, 34141818514.86, 35024168187.96, 36210012479.47,
          36625639511.7, 36626653166.36, 37224924189.82, 38280851911.15,
          38189726803.67, 38373486451.92, 38838021834.06, 38814295646.78,
          38999472256.16, 38703967986.5, 38614285939.24, 38737130108.7,
          39126746903.01, 39654055941.49, 40208220394.52, 40233392669.73,
          40942262508.6, 41537780219.14, 41944584160.24, 42843314795.27,
          43560695312.16, 44166037526.2, 44703826785.13, 45666458390.7,
          46844643228.54, 48898973399.4, 49966394133.73, 51153794679.86,
        ],
      },
      {
        type: 'line',
        name: 'Num. de FIDCs MM',
        data: [
          312, 313, 313, 313, 315, 317, 321, 324, 324, 328, 335, 336, 336, 337,
          344, 346, 352, 355, 361, 366, 370, 371, 372, 373, 375, 378, 378, 380,
          380, 381, 386, 386, 386, 385, 386, 386, 381, 380, 381, 379, 377, 375,
          373, 373, 376, 376, 377, 376, 375, 374, 374, 370, 370, 370, 367, 365,
          364, 366, 364, 362,
        ],
      },
    ],
    categories: categoriesDates,
    numeric: true,
    stacked: false,
    animated: false,
  },
};
