import { useEffect, useState } from 'react';

import { Home, Menu } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSectionInScreen } from 'src/utils/hooks';

import { colors } from '../../constants';
import { MarketName } from '../../types';
import { getYearbookSectionsName, navigateToSection } from '../../utils';
import { SideMenu } from '../SideMenu';

const menuItems = [
  { label: 'Patrocinadores', active: false },
  { label: 'Carta ao leitor', active: true },
  { label: 'Abertura', active: false },
  { label: 'Mercado Primário', active: false },
  { label: 'Mercado Secundário', active: false },
  { label: 'Estoque', active: false },
  { label: 'Rankings', active: false },
  { label: 'Artigos', active: false },
  { label: 'Vídeos', active: false },
];

type NavigationMenuProps = {
  market: MarketName;
  section?: string;
};

export default function NavigationMenu({
  market,
  section,
}: NavigationMenuProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [sections, setSections] = useState<string[]>([]);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const history = useHistory();
  const currentSection = useSectionInScreen();

  const handleDrawerToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setMobileOpen(!mobileOpen);
  };

  const handleItemClick = (item: string) => {
    navigateToSection(item, -90);
    if (isMobile) {
      setMobileOpen(false);
    }

    history.push(`/anuarios/2025/${market}/${item}`);
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      let yearbookSections = getYearbookSectionsName();
      yearbookSections = yearbookSections.filter(
        (section) => section !== 'Inicio',
      );

      if (yearbookSections.length > 1) {
        setSections(yearbookSections);
        observer.disconnect();
      }
    });
    observer.observe(document, { subtree: true, childList: true });
  }, [market]);

  useEffect(() => {
    if (section) {
      navigateToSection(section, -90, true);
    }
  }, [section]);

  const mobileMenu = (
    <Box sx={{ width: 200 }} role="presentation">
      <SideMenu
        market={market}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 1,
          backgroundColor: 'rgba(200, 200, 200, 0.5)',
        }}
        disableNavigation={true}
        titleComponent={
          <Typography
            sx={{
              mt: 2,
              fontSize: 14,
              fontWeight: 600,
              color: colors.black,
              textAlign: 'center',
            }}>
            Você está lendo:
          </Typography>
        }
      />
      <List>
        <ListItem key="Inicio" disablePadding>
          <ListItemButton
            onClick={() => handleItemClick('Inicio')}
            sx={{
              backgroundColor:
                currentSection === 'Inicio' ? '#ff8c00' : 'transparent',
              color: currentSection === 'Inicio' ? 'white' : 'inherit',
              '&:hover': {
                backgroundColor:
                  currentSection === 'Inicio' ? '#ff8c00' : '#e0e0e0',
              },
            }}>
            <ListItemText primary="Inicio" />
          </ListItemButton>
        </ListItem>
        {sections.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              onClick={() => handleItemClick(item)}
              sx={{
                backgroundColor:
                  currentSection === item ? '#ff8c00' : 'transparent',
                color: currentSection === item ? 'white' : 'inherit',
                '&:hover': {
                  backgroundColor:
                    currentSection === item ? '#ff8c00' : '#e0e0e0',
                },
              }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      color="default"
      elevation={0}
      sx={({ breakpoints }) => ({
        backgroundColor: 'transparent',
        [breakpoints.up('md')]: {
          backgroundColor: 'transparent',
          flexGrow: 1,
          mx: 'auto',
          zIndex: 1000,
        },
      })}>
      <Toolbar
        sx={({ breakpoints }) => ({
          mt: 2,
          [breakpoints.up('lg')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        })}>
        <Hidden lgDown>
          <IconButton
            edge="start"
            sx={{
              backgroundColor:
                currentSection === 'Inicio'
                  ? 'primary.main'
                  : hoveredItem === 'Inicio'
                    ? '#e0e0e0'
                    : 'white',
              color: currentSection === 'Inicio' ? 'white' : 'primary.main',
              borderRadius: '50%',
              width: 48,
              height: 48,
              boxShadow: 1,
              '&:hover': {
                backgroundColor:
                  currentSection === 'Inicio' ? '#e67e00' : 'white',
                color: currentSection === 'Inicio' ? 'white' : 'primary.main',
              },
              marginRight: 2,
            }}
            onClick={() => handleItemClick('Inicio')}>
            <Home sx={{ fontSize: 27 }} />
          </IconButton>
        </Hidden>

        {isMobile ? (
          <>
            <IconButton
              aria-label="open drawer"
              edge="end"
              sx={{
                backgroundColor: 'rgba(3, 3, 3, 0.4)',
                borderRadius: '50%',
                width: 48,
                height: 48,
              }}
              onClick={handleDrawerToggle}>
              <Menu color="primary" sx={{ fontSize: 40 }} />
            </IconButton>
            <Drawer
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              disableScrollLock={true}
              keepMounted={true}>
              {mobileMenu}
            </Drawer>
          </>
        ) : (
          <Box
            sx={({ breakpoints }) => ({
              maxWidth: breakpoints.values.xl,
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'white',
              borderRadius: 6,
              boxShadow: 1,
              py: 1,
              [breakpoints.down('lg')]: {
                overflowX: 'scroll',
                width: '100%',
              },
            })}>
            {menuItems.map((item) => (
              <Button
                key={item.label}
                onClick={() => handleItemClick(item.label)}
                onMouseEnter={() => setHoveredItem(item.label)}
                onMouseLeave={() => setHoveredItem(null)}
                sx={{
                  minWidth: {
                    xl: '150px',
                    lg: '40px',
                  },
                  fontSize: '1rem',
                  textTransform: 'none',
                  mx: 0.5,
                  borderRadius: 6,
                  padding: '4px 8px',
                  backgroundColor:
                    currentSection === item.label
                      ? '#ff8c00'
                      : hoveredItem === item.label &&
                          currentSection !== item.label
                        ? '#e0e0e0'
                        : 'transparent',
                  color: currentSection === item.label ? 'white' : 'inherit',
                  '&:hover': {
                    backgroundColor:
                      currentSection === item.label ? '#ff8c00' : '#e0e0e0',
                  },
                  fontWeight: 500,
                }}>
                {item.label === 'Hover' && hoveredItem !== 'Hover'
                  ? 'Hover'
                  : item.label}
              </Button>
            ))}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
