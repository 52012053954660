import { SvgIcon, SvgIconProps } from '@mui/material';

export function FIIIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.6 10.667h-1.7v1.777h1.7m0-5.333h-1.7V8.89h1.7m1.7 5.333H8.5v-1.778h1.7v-1.777H8.5V8.889h1.7V7.11H8.5V5.333h6.8M6.8 3.556H5.1V1.778h1.7m0 5.333H5.1V5.333h1.7m0 5.334H5.1V8.889h1.7m0 5.333H5.1v-1.778h1.7M3.4 3.556H1.7V1.778h1.7m0 5.333H1.7V5.333h1.7m0 5.334H1.7V8.889h1.7m0 5.333H1.7v-1.778h1.7m5.1-8.888V0H0v16h17V3.556H8.5z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
