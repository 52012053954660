import { useMemo } from 'react';

import { ArrowForward } from '@mui/icons-material';
import { Box, Button, Grid, lighten, Typography } from '@mui/material';
import { getContrastColor } from 'src/utils/color';

import { MarketName, Sponsor, SponsorLevel } from '../../../types';
import { SponsorCard } from '../SponsorCard';

const SPONSOR_IMAGES_SIZES = {
  [SponsorLevel.BRONZE]: '120px',
  [SponsorLevel.SILVER]: '150px',
  [SponsorLevel.GOLD]: '180px',
  [SponsorLevel.PLATINUM]: '210px',
  [SponsorLevel.DIAMOND]: '240px',
};

type SponsorsGridProps = {
  sponsors: Sponsor[];
  color: string;
  market: MarketName;
  level: SponsorLevel;
};

export function SponsorsGrid({
  sponsors,
  color,
  market,
  level,
}: SponsorsGridProps) {
  const filteredSponsors = useMemo(() => {
    return sponsors.filter(
      (sponsor) => sponsor.level === level && sponsor.markets.includes(market),
    );
  }, [level, market]);

  if (filteredSponsors.length === 0) {
    return null;
  }

  const getSponsorImage = (imageName: string) => {
    return require(`src/assets/imgs/yearbook/2025/sponsors/${imageName}.png`);
  };

  function handleClickBeASponsor() {
    window.open(
      'mailto:comercial@uqbar.com.br?subject=Quero ser um patrocinador dos anuários Uqbar&body=Olá, tenho interesse em patrocinar os anuários Uqbar. Meu nome é [Nome] e minha empresa é [Empresa].',
      '_blank',
    );
  }

  function handleClickSponsor(sponsor: Sponsor) {
    window.open(sponsor.site, '_blank');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: 2,
        background: `linear-gradient(45deg, ${color}, ${lighten(color, 0.5)})`,
        p: 2,
        borderRadius: 2,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
        <Typography variant="h4" color={getContrastColor(color)}>
          {level}
        </Typography>
        <Button
          size="small"
          onClick={handleClickBeASponsor}
          variant="text"
          sx={{ color: getContrastColor(color) }}>
          Seja um patrocinador
          <ArrowForward fontSize="small" />
        </Button>
      </Box>
      <Grid
        container
        spacing={1}
        bgcolor={'white'}
        borderRadius={2}
        margin={0}
        width={'100%'}
        padding={1}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {filteredSponsors.map((sponsor) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            key={sponsor.site}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <SponsorCard onClick={() => handleClickSponsor(sponsor)}>
              <Box
                component="img"
                src={getSponsorImage(sponsor.image).default}
                width={SPONSOR_IMAGES_SIZES[level]}
              />
            </SponsorCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
