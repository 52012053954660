import { CrIcon } from './components/Icons/CR';
import { FIAGROIcon } from './components/Icons/FIAGRO';
import { FIDCIcon } from './components/Icons/FIDC';
import { FIIIcon } from './components/Icons/FII';

export const colors = {
  black: '#131313',
  white: '#FFFFFF',
  primary: 'primary.main',
  secondary: 'secondary.main',
};

export const availableMarkets = [
  {
    label: 'CRs',
    Icon: CrIcon,
  },
  {
    label: 'FII',
    Icon: FIIIcon,
  },
  {
    label: 'FIDC',
    Icon: FIDCIcon,
  },
  {
    label: 'FIAGRO',
    Icon: FIAGROIcon,
  },
];
