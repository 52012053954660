import { Tabs as MuiTabs, Tab } from '@mui/material';

type TabsProps = {
  tabValue: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
};

export function Tabs({ tabValue, handleTabChange }: TabsProps) {
  return (
    <MuiTabs
      value={tabValue}
      onChange={handleTabChange}
      sx={{
        mb: 2,
        '& .MuiTabs-indicator': { display: 'none' },
        '& .MuiTab-root': {
          borderRadius: '20px',
          mx: 0.5,
          minWidth: 'auto',
          px: 2,
        },
      }}>
      <Tab
        label="Todos os Videos"
        sx={{
          bgcolor: tabValue === 0 ? 'primary.main' : 'grey.800',
          color: tabValue === 0 ? 'common.black' : 'common.white',
          fontWeight: 'bold',
          '&.Mui-selected': {
            bgcolor: 'primary.main',
            color: 'common.black',
          },
        }}
      />
      <Tab
        label="Relacionados"
        sx={{
          bgcolor: tabValue === 1 ? 'primary.main' : 'grey.800',
          color: tabValue === 1 ? 'common.black' : 'common.white',
          fontWeight: 'bold',
          '&.Mui-selected': {
            bgcolor: 'primary.main',
            color: 'common.black',
          },
        }}
      />
    </MuiTabs>
  );
}
