import {
  fiagroPrimaryCharts,
  fiagroSecondaryCharts,
  fiagroStockCharts,
} from './charts/FIAGRO';
import {
  YearbookDescriptions,
  YearbookMarketChartInfo,
  YearbookMarketRanking,
} from '../types';

export const fiagroOpeningTexts = [
  `O Anuário Uqbar de FIAGRO 2024 traz uma análise completa sobre as principais dinâmicas e tendências do mercado de Fundos de Investimento nas Cadeias Produtivas Agroindustriais (FIAGRO). 
  Este relatório apresenta dados detalhados que evidenciam tanto os desafios quanto as oportunidades que marcaram o setor ao longo do ano.`,

  `No mercado primário, as emissões combinadas dos FIAGRO-FIDC e FIAGRO-FII somaram R$ 4,68 bilhões, registrando uma queda significativa de 58,8% em comparação ao ano anterior. 
  Enquanto os FIAGRO-FIDC tiveram uma retração de 37,1%, com um montante de R$ 2,87 bilhões, os FIAGRO-FII sofreram um declínio ainda mais acentuado, totalizando apenas R$ 1,81 bilhão. 
  Apesar disso, segmentos específicos como os fundos dedicados a Fazendas mantiveram-se fortes, com emissões crescentes e relevantes no cenário geral.`,

  `Já o mercado secundário demonstrou vigor e atratividade crescentes, com um volume total negociado de aproximadamente R$ 11 bilhões, distribuídos por mais de 18 milhões de transações. 
  Os FIAGRO-FII tiveram protagonismo nessa dinâmica, impulsionados por fundos líderes como o FIAGRO XP Crédito Agro e o FIAGRO Kinea Crédito Agro. 
  No mercado de FIAGRO-FIDC, o destaque foi o aumento significativo nas negociações e o crescimento dos direitos creditórios em carteira, alcançando R$ 5,6 bilhões.`,

  `O estoque de FIAGROs também se expandiu de forma robusta, atingindo um patrimônio líquido combinado de R$ 46,44 bilhões, com destaque para o crescimento acelerado dos FIAGRO-FII e FIAGRO-FIDC. 
  Fundos como FIAGRO Kinea Crédito Agro, Itaú Asset Rural e Agrogalaxy lideraram em patrimônio líquido, refletindo a consolidação desses instrumentos entre investidores que buscam exposição estratégica ao agronegócio.`,

  `Finalmente, a seção de Rankings destaca as instituições, administradores e gestores que dominaram o mercado em 2024, revelando uma competição acirrada pela liderança e oferecendo insights valiosos sobre as estratégias vencedoras no setor. 
  A seguir, você encontrará informações detalhadas e exclusivas sobre esses resultados, que definiram o ano dos FIAGROs.`,
];

export const fiagroYearbookDescriptions: YearbookDescriptions = {
  primary: [
    `Em 2024, as emissões combinadas de FIAGRO-FIDC e FIAGRO-FII totalizaram R$ 4,68 bilhões, uma queda de 58,8% em relação ao ano anterior. 
    Individualmente, as emissões de FIAGRO-FIDC recuaram 37,1%, somando R$ 2,87 bilhões, enquanto as de FIAGRO-FII caíram ainda mais significativamente (73,3%), totalizando apenas R$ 1,81 bilhão.`,

    'Nos FIAGRO-FIDC, o destaque foi o aumento das emissões em condomínios abertos, que passaram de R$ 0,06 bilhão em 2023 para R$ 0,25 bilhão em 2024, enquanto os fechados recuaram para R$ 2,62 bilhões.',

    `Entre os FIAGRO-FII, os fundos focados em Fazenda continuaram predominantes, com emissões aumentando para R$ 0,83 bilhão. 
    Já os fundos da categoria Especial, ligados a silos e armazéns, tiveram queda expressiva, emitindo apenas R$ 0,12 bilhão. Houve ainda uma emissão residual de R$ 0,10 bilhão em fundos classificados como Indeterminados.`,
  ],
  secondary: [
    'Em 2024, os mercados secundários de FIAGRO-FII e FIAGRO-FIDC apresentaram desempenho robusto, com um montante negociado combinado de quase R$ 11 bilhões e mais de 18 milhões de transações. Esse resultado reflete a crescente atratividade dos investimentos ligados ao agronegócio.',
    'O mercado de FIAGRO-FII respondeu pela maior parte, com R$ 10,40 bilhões negociados e 17,7 milhões de operações, crescendo cerca de 38% em relação ao ano anterior. Os fundos FIAGRO XP Crédito Agro (R$ 1,58 bilhão) e FIAGRO Kinea Crédito Agro (R$ 993 milhões) foram os principais destaques.',
    'Já o mercado de FIAGRO-FIDC somou R$ 591 milhões em negociações, registrando um aumento expressivo em volume financeiro e no número de operações comparado a 2023. O FIAGRO BTG Pactual Crédito Agrícola foi o destaque do ano, com R$ 151,8 milhões negociados, principalmente no mercado de balcão.',
  ],
  stock: [
    `A análise do patrimônio líquido (PL) dos FIAGROs em 2024 revela um cenário dinâmico e em expansão, destacando o crescimento acelerado desse mercado, que atingiu um PL combinado de R$ 46,44 bilhões distribuído entre FIAGROs-FII, FIDC e FIP. 
    Esse avanço reflete o interesse crescente dos investidores por veículos de investimento no agronegócio, impulsionado pela versatilidade e capacidade de atender diferentes estratégias financeiras.`,

    `O segmento de FIAGRO-FII apresentou uma evolução impressionante, com o PL totalizando R$ 18,62 bilhões no fim de 2024, distribuído por 56 fundos ativos. 
    Os maiores destaques ficaram por conta do FIAGRO Kinea Crédito Agro, com R$ 2,19 bilhões, seguido pelo FIAGRO Itaú Asset Rural (R$ 1,62 bilhão) e o FIAGRO XP Crédito Agro (R$ 1,44 bilhão).`,

    `Já os FIAGRO-FIDC também mostraram forte desempenho, com um crescimento anual expressivo de 75,78% em patrimônio líquido, alcançando R$ 7,33 bilhões distribuídos em 63 fundos. 
    Essa expansão está associada à proximidade com a originação do crédito e à flexibilidade desses instrumentos financeiros. 
    Liderando o ranking desse segmento estão o FIAGRO Agrogalaxy Fornecedores RLtd (R$ 1,11 bilhão), o FIAGRO Insumos Milenio Terramagna (R$ 491,84 milhões) e o FIAGRO RCL3X (R$ 391,04 milhões).`,

    'O mercado de FIAGRO-FIP, embora mais restrito e opaco, manteve-se estável com 13 fundos, acumulando um patrimônio de R$ 20,47 bilhões—um aumento de 21,85% em relação ao ano anterior.',

    `Adicionalmente, destaca-se a carteira dos FIAGRO-FIDC em Direitos Creditórios (DC), que alcançou R$ 5,6 bilhões em 2024, frente aos R$ 3,41 bilhões registrados em 2023. 
    Os maiores volumes nessa categoria foram registrados pelo FIAGRO Agrogalaxy Fornecedores RLtd (R$ 861,24 milhões), seguido por Rotterdam FIAGRO (R$ 372,78 milhões) e FIAGRO Insumos Milenio Terramagna (R$ 325,87 milhões).`,

    `A análise da composição do PL dos FIAGROs-FII evidencia ainda a predominância da Gestão de Ativos Financeiros, representando 60,65% do total. 
    Já os FIAGROs-FII com gestão ativa, que permitem maior flexibilidade aos gestores, dominam amplamente o mercado, com 97,95% do PL, reforçando a preferência crescente por estratégias dinâmicas e proativas na gestão dos ativos agropecuários.`,

    'Confira a seguir detalhes e análises aprofundadas sobre esses números e tendências que definiram o mercado de FIAGROs em 2024.',
  ],
  rankings: [
    `Os Rankings do Anuário Uqbar de FIAGRO oferecem uma visão estratégica sobre a consolidação e o dinamismo dos fundos voltados ao agronegócio brasileiro ao longo de 2024. 
    Nesta seção, você encontrará análises detalhadas sobre os principais fundos, administradores e gestores que marcaram presença significativa no mercado, destacando-se tanto pelo volume de patrimônio administrado quanto pela diversidade de estratégias adotadas.`,

    'Entre os fundos FIAGRO-FIDC e FIAGRO-FII com maior patrimônio líquido, o FIAGRO Kinea Crédito Agro lidera com R$ 2,19 bilhões, seguido por fundos vinculados a players de peso como o FIAGRO Itaú Asset Rural e o FIAGRO XP Crédito Agro. ',

    `Já entre os administradores, destaca-se a competição acirrada pelo patrimônio líquido, com a XP Investimentos e a Intrag DTVM praticamente empatadas em liderança, evidenciando a alta concentração e a disputa pela liderança no mercado. 
    Em termos de número de fundos administrados, o Banco Daycoval ocupa o primeiro lugar.`,

    'Entre os gestores, destaque para a Milenio Capital, que lidera em patrimônio gerido, e para a Eco Gestão de Ativos, que aparece à frente em número de fundos geridos. ',
  ],
};

export const fiagroPrimaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fiagroPrimaryCharts.emissionsFIDC,
    name: 'Emissões de FIAGROs-FIDCs',
    section: 'primary',
    details: {
      title: 'Emissões têm redução de 37%',
      texts: [
        'As emissões de FIAGROs-FIDCs em 2024 apresentaram um montante de R$ 2,87 bilhões, o que representa uma redução de 37,1% em comparação ao ano anterior, que registrou R$ 4,57 bilhões. ',
      ],
      value: 2.87,
      valueTitle: 'Montante Emitido 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroPrimaryCharts.emissionsByTypeOfCondominium,
    name: 'Emissões por tipo de Condomínio',
    section: 'primary',
    details: {
      title: 'Condomínio fechado mantém forte representação em 2024',
      texts: [
        `O cenário dos FIAGROs-FIDCs apresentou mudanças perceptíveis. Os fundos de condomínio fechado, que em 2023 já haviam demonstrado um crescimento expressivo, atingindo R$ 4,51 bilhões, observou uma queda considerável em 2024, totalizando R$ 2,62 bilhões. 
        Por outro lado, o condomínio aberto mostrou um crescimento notável. Em 2023, sua representatividade era tímida, com apenas R$ 0,06 bilhão, mas em 2024, este montante saltou para R$ 0,25 bilhão, indicando um aumento crescente por esse tipo de fundo. 
        Naturalmente, a participação no total de ambos os tipos de condomínio também se alterou, com os “fechados” respondendo por 91,32% do total em 2024, ante 98,67% em 2023.`,
      ],
      value: 2.62,
      valueTitle: 'Montante Emitido 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroPrimaryCharts.emissionsFII,
    name: 'Emissões de FIAGROs-FIIs',
    section: 'primary',
    details: {
      title: 'Emissões de FIAGROs-FIIs',
      texts: [
        `Em 2024, as emissões dos FIAGROs-FIIs totalizaram R$ 1,81 bilhão, representando uma queda significativa de 73,34% em relação ao montante emitido em 2023, que foi de R$ 6,79 bilhões. 
        Essa redução acentuada marca um contraste com o crescimento observado nos anos anteriores, onde em 2022 as emissões alcançaram R$ 6,67 bilhões, e em 2021, no seu início, foram de R$ 2,18 bilhões. `,
      ],
      value: 1.81,
      valueTitle: 'Montante Emitido 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroPrimaryCharts.emissionsFIIFIDC,
    name: 'Emissões de FIAGROs-FIIs e FIAGROs-FIDCs',
    section: 'primary',
    details: {
      title: 'Emissões de FIAGROs-FIIs e FIAGROs-FIDCs',
      texts: [
        `Em 2024, as emissões combinadas de FIAGROs-FIIs e FIDCs totalizaram R$ 4,68 bilhões, refletindo uma queda de 58,8% em relação aos R$ 11,36 bilhões emitidos em 2023. 
        Este declínio é notável, considerando o crescimento robusto observado nos anos anteriores, onde as emissões atingiram R$ 8,01 bilhões em 2022 e R$ 2,25 bilhões em 2021. `,
      ],
      value: 4.68,
      valueTitle: 'Montante Emitido 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroPrimaryCharts.emissionsByTypeOfImmobile,
    name: 'Emissões por tipo de Imóvel',
    section: 'primary',
    details: {
      title: 'Participação dos FIAGROs-FIIs do tipo de imóvel Fazenda',
      texts: [
        `Em 2024, a participação dos FIAGROs-FIIs do tipo Fazenda continuou a se destacar, alcançando um montante emitido de R$ 0,83 bilhão, o que representa um aumento em relação aos R$ 0,65 bilhão registrados em 2023. 
        Assim, os FIAGROs-FIIs do tipo Fazenda mantiveram uma participação significativa no total emitido. Por outro lado, a modalidade Especial, que inclui FIAGROs-FIIs que investem em Silos e Armazéns, viu sua emissão cair para R$ 0,12 bilhão em 2024, uma redução expressiva em comparação aos R$ 0,20 bilhão do ano anterior. 
        Além disso, foi registrado um montante de R$ 0,10 bilhão na categoria Indeterminado, referente a dois fundos: FIAGRO Éxes Terras, que sequer apresenta informe mensal, e o FIAGRO Fruto Terras I, que, apesar de operacional desde julho de 2024, mantém a carteira apenas em ativos de liquidez.`,
      ],
    },
  },
];

export const fiagroSecondaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fiagroSecondaryCharts.amountNegotiatedFIDC,
    section: 'secondary',
    name: 'Montante Negociado de FIAGROs-FIDCs',
    details: {
      title:
        'Crescimento do montante negociado e número de negócios no FIAGRO-FIDC',
      texts: [
        `Ao somar os dados dos dois mercados, observamos um panorama impressionante. Em 2024, o total combinado do montante negociado nos mercados de FIAGROs-FIDCs e FIAGROs-FIIs alcançou aproximadamente R$ 10,99 bilhões, com um total de cerca de 18.127.956 negócios. 
        Esse crescimento conjunto reflete a crescente atratividade do agronegócio como uma classe de ativos, demonstrando que os investidores estão cada vez mais dispostos a alocar recursos em veículos de investimento que oferecem exposição ao setor agrícola.`,

        `O mercado de FIAGROs-FIIs também apresentou um desempenho robusto em 2024, alcançando um montante negociado de R$ 10,40 bilhões e 17.757.132 negócios. 
        Este resultado representa um crescimento de aproximadamente 38,4% em relação a 2023, quando o montante foi de R$ 7,49 bilhões, e um aumento de 8,5% no número de transações.`,

        'Os fundos que se destacaram no mercado de FIAGROs-FIIs foram o FIAGRO XP Crédito Agro, com R$ 1,58 bilhão negociados, e o FIAGRO Kinea Crédito Agro, que também apresentou um desempenho relevante com R$ 993 milhões.',

        `O mercado de FIAGROs-FIDCs apresentou um aumento significativo em 2024, com um montante negociado de R$ 591,28 milhões e 370.824 negócios. 
        Em comparação a 2023, onde o montante foi de R$ 542,02 milhões com 253.694 transações, o crescimento em termos absolutos foi de R$ 49,26 milhões, evidenciando não apenas um aumento no volume financeiro, mas também um incremento considerável no número de negociações, que cresceu em mais de 117 mil transações.`,
      ],
      value: 10.99,
      valueTitle: 'Montante Negociado 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroSecondaryCharts.amountNegotiatedFII,
    section: 'secondary',
    name: 'Montante Negociado de FIAGROs-FIIs',
    details: {
      title: 'Montante negociado de cotas de FIAGROs-FIIs é recorde em 2024',
      texts: [
        `Ao somar os dados dos dois mercados, observamos um panorama impressionante. Em 2024, o total combinado do montante negociado nos mercados de FIAGROs-FIDCs e FIAGROs-FIIs alcançou aproximadamente R$ 10,99 bilhões, com um total de cerca de 18.127.956 negócios. 
        Esse crescimento conjunto reflete a crescente atratividade do agronegócio como uma classe de ativos, demonstrando que os investidores estão cada vez mais dispostos a alocar recursos em veículos de investimento que oferecem exposição ao setor agrícola.`,

        `O mercado de FIAGROs-FIIs também apresentou um desempenho robusto em 2024, alcançando um montante negociado de R$ 10,40 bilhões e 17.757.132 negócios. 
        Este resultado representa um crescimento de aproximadamente 38,4% em relação a 2023, quando o montante foi de R$ 7,49 bilhões, e um aumento de 8,5% no número de transações.`,

        'Os fundos que se destacaram no mercado de FIAGROs-FIIs foram o FIAGRO XP Crédito Agro, com R$ 1,58 bilhão negociados, e o FIAGRO Kinea Crédito Agro, que também apresentou um desempenho relevante com R$ 993 milhões.',

        `O mercado de FIAGROs-FIDCs apresentou um aumento significativo em 2024, com um montante negociado de R$ 591,28 milhões e 370.824 negócios. 
        Em comparação a 2023, onde o montante foi de R$ 542,02 milhões com 253.694 transações, o crescimento em termos absolutos foi de R$ 49,26 milhões, evidenciando não apenas um aumento no volume financeiro, mas também um incremento considerável no número de negociações, que cresceu em mais de 117 mil transações.`,
      ],
      value: 10.4,
      valueTitle: 'Montante Negociado 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroSecondaryCharts.amountNegotiatedEnvironment,
    section: 'secondary',
    name: 'FIAGROs-FIDCs Ambiente de Negociação',
    details: {
      title: 'FIAGROs-FIDCs Ambiente de Negociação',
      texts: [
        'A distribuição dos negócios entre os ambientes de negociação também merece destaque. Na Bolsa, foram contabilizados 298 negócios com um montante de R$ 168,48 milhões, enquanto no Balcão, 28 negócios totalizaram R$ 422,80 milhões. O principal FIAGRO-FIDC do ano, foi o FIAGRO BTG Pactual Crédito Agrícola, com R$ 151,81 milhões.',
      ],
      value: 10.4,
      valueTitle: 'Montante Negociado 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroSecondaryCharts.amountNegotiatedEnvironmentNumber,
    section: 'secondary',
    name: 'FIAGROs-FIDCs Número de Negociações por Ambiente',
    details: {
      title: 'FIAGROs-FIDCs Número de Negociações por Ambiente',
      texts: [
        'A distribuição dos negócios entre os ambientes de negociação também merece destaque. Na Bolsa, foram contabilizados 298 negócios com um montante de R$ 168,48 milhões, enquanto no Balcão, 28 negócios totalizaram R$ 422,80 milhões. O principal FIAGRO-FIDC do ano, foi o FIAGRO BTG Pactual Crédito Agrícola, com R$ 151,81 milhões.',
      ],
      value: 10.4,
      valueTitle: 'Montante Negociado 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
];

export const fiagroStock: YearbookMarketChartInfo[] = [
  {
    chart: fiagroStockCharts.liquidFIDC,
    section: 'stock',
    name: 'Patrimônio líquido dos FIAGROs-FIDCs',
    details: {
      title: 'Patrimônio líquido dos FIAGROs-FII, FIDC e FIP em 2024',
      texts: [
        `Os dados refletem uma trajetória impressionante de crescimento no patrimônio líquido (PL) dos FIAGROs-FIIs ao longo do período entre agosto de 2021 e dezembro de 2024. 
        Iniciando com um PL de aproximadamente R$ 559 milhões em agosto de 2021, distribuídos entre três FIIs, observa-se uma tendência ascendente constante, culminando em um PL de mais de R$ 18,62 bilhões em dezembro de 2024, espalhados por 56 diferentes de FIAGROs-FIIs..`,

        `Três FIAGROs-FIIs com maior Patrimônio Líquido em 2024

-   FIAGRO Kinea Crédito Agro PL - R$ 2,196 bilhões
    
-   FIAGRO Itaú Asset Rural PL - R$ 1,624 bilhão
    
-   FIAGRO XP Crédito Agro -PL - R$ 1,44 bilhão`,

        `Ao fim de 2024, o mercado registrou um total de 63 FIAGROs-FIDC, com um PL de R$ 7,33 bilhões. Comparado a 2023, o número de fundos e o PL aumentaram em 65,79% e 75,78%, respectivamente. 
        O crescimento recente do FIAGRO-FIDC, relação ao ano anterior, pode ser atribuído à sua maior versatilidade e proximidade com a originação do crédito, o que reduz custos para o originador.`,

        `Os três FIAGROs-FIDCs com maior PL ao fim de 2024 foram:

-   FIAGRO Agrogalaxy Fornecedores RLtd - R$ 1,11 bilhão
    
-   FIAGRO Insumos Milenio Terramagna - R$ 491,84 milhões
    
-   FIAGRO RCL3X - R$ 391,04 milhões`,

        `Em relação ao opaco segmento de FIAGROs-FIP que ao fim de 2024 somava R$ 20,47 bilhões, distribuído em 13 fundos, o PL acumulado aumentou em 21,85%, mas o número de fundos não se alterou.
        Combinando com os mercados de FIAGROs-FII, FIDC e FIP o total de PL em 2024 assinala a cifra de R$46,44 bi, distribuído em 134 FIAGROs.`,
      ],
      value: 4.6,
      valueTitle: 'Patrimômio Líquido 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroStockCharts.creditoryFIDC,
    section: 'stock',
    name: 'Direitos Creditórios dos FIAGROs-FIDCs',
    details: {
      title: 'Soma de Direitos Creditórios dos FIAGROs-FIDCs em 2024',
      texts: [
        'A carteira dos FIAGROs-FIDCs é composta por recebíveis, duplicatas, CPR-F e, também, debêntures, Certificados de Recebíveis do Agronegócio, entre outros, e deve estar alinhada com as atividades permitidas pela legislação para os FIAGROs.',
        'Ao final de 2024, a soma de Direitos Creditórios (DC) alcançou a cifra de R$ 5,6 bilhões, representando um crescimento significativo em relação aos R$ 3,41 bilhões registrados em 2023.',
        `Os três FIAGROs-FIDCs com maior soma de Direitos Creditórios em 2024 são:

-   FIAGRO Agrogalaxy Fornecedores RLtd - DC: R$ 861,24 milhões
    
-   Rotterdam FIAGRO - DC: R$ 372,78 milhões
    
-   FIAGRO Insumos Milenio Terramagna - DC: R$ 325,87 milhões`,
      ],
      value: 5.6,
      valueTitle: 'Direitos Creditórios 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroStockCharts.liquidByFinalityFII,
    section: 'stock',
    name: 'Patrimônio líquido dos FIAGROs-FIIs por finalidade',
    details: {
      title:
        'Gestão de Ativos Financeiros é a principal finalidade de FIAGROs-FIIs.',
      texts: [
        'Em 2024, a Gestão de Ativos Financeiros continua sendo a principal finalidade dos FIAGROs-FII. Os FIAGROs-FII encerraram o mês de dezembro de 2024 com 60,65% do Patrimônio Líquido (PL) do mercado, mantendo uma média anual de 64,83% do PL.',
        'A estrutura do mercado é dominada pela Gestão de Ativos Financeiros, que representa a maior parte do segmento. Em seguida, temos o segmento Diversas, com 25,99% do PL. Por último, o segmento Renda representa 13,36% do PL.',
      ],
    },
  },
  {
    chart: fiagroStockCharts.liquidByManagementTypeFii,
    section: 'stock',
    name: 'Patrimônio líquido dos FIAGROs-FIIs por tipo de gestão',
    details: {
      title: 'FIAGROS-FIIs de Gestão de Ativa são destaque em PL',
      texts: [
        'Os FIAGROS-FIIs de gestão ativa, em que os gestores possuem o maior grau de liberdade nos ativos que farão parte da carteira, registraram 97,95% do PL em 2024, um aumento de 0,2 p.p. em relação a 2023 (97,75%). A gestão passiva representa apenas 2,05% do PL.',
      ],
    },
  },
];

export const fiagroRankings: YearbookMarketRanking[] = [
  {
    name: 'Ranking de FIAGRO-FII/FIDC por PL',
    details: {
      title: 'Ranking de FIAGRO-FII/FIDC por PL',
      texts: [
        'O ranking de patrimônio líquido dos fundos FIAGRO-FIDC e FIAGRO-FII destaca a  relevância desses veículos de investimento no setor agropecuário brasileiro, com dados referentes a 31 de dezembro de 2024. Liderando a lista, o FIAGRO Kinea Crédito Agro apresenta um patrimônio líquido impressionante de R$ 2,19 bilhões, evidenciando sua posição dominante no mercado. Em seguida, o FIAGRO Itaú Asset Rural, com R$ 1,62 bilhão, e o FIAGRO XP Crédito Agro, com R$ 1,43 bilhão, reforçam a força de gestoras renomadas no segmento. O FIAGRO Canaã, com R$ 1,24 bilhão, e o FIAGRO Agrogalaxy Fornecedores RLtd, com R$ 1,10 bilhão, completam o top 5.',
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Montante',
      ranks: [
        {
          id: 968785,
          institutionId: 0,
          operationId: '8654987',
          slug: 'fiagro-kinea-credito-agro',
          name: 'FIAGRO Kinea Crédito Agro',
          position: 1,
          value: 2196335358.77,
          percentage: 8.46,
          uqbarId: 8654987,
        },
        {
          id: 968786,
          institutionId: 0,
          operationId: '8691783',
          slug: 'fiagro-itau-asset-rural',
          name: 'FIAGRO Itaú Asset Rural',
          position: 2,
          value: 1623933250.85,
          percentage: 6.25,
          uqbarId: 8691783,
        },
        {
          id: 968787,
          institutionId: 0,
          operationId: '8814662',
          slug: 'fiagro-xp-credito-agro',
          name: 'FIAGRO Xp Crédito Agro',
          position: 3,
          value: 1435521946.47,
          percentage: 5.53,
          uqbarId: 8814662,
        },
        {
          id: 968788,
          institutionId: 0,
          operationId: '8870870',
          slug: 'fiagro-canaa',
          name: 'FIAGRO Canaã',
          position: 4,
          value: 1244520260.92,
          percentage: 4.79,
          uqbarId: 8870870,
        },
        {
          id: 968789,
          institutionId: 0,
          operationId: '9448452',
          slug: 'fiagro-agrogalaxy-fornecedores-rltd',
          name: 'FIAGRO Agrogalaxy Fornecedores RLtd',
          position: 5,
          value: 1107449526.65,
          percentage: 4.26,
          uqbarId: 9448452,
        },
      ],
    },
  },
  {
    name: 'Ranking de Administrador de FIAGRO-FII/FIDC por PL',
    details: {
      title: 'Ranking de Administrador de FIAGRO-FII/FIDC por PL',
      texts: [
        'O ranking dos administradores de FIAGRO-FIDC e FIAGRO-FII, organizado pelo critério de patrimônio líquido, revela a concentração e a força das principais instituições no mercado de fundos voltados ao agronegócio. A XP Investimentos lidera com um patrimônio de R$ 4,73 bilhões, representando 18,24% do total, seguida de perto pela Intrag DTVM, com R$ 4,73 bilhões e 18,23%, demonstrando a competitividade acirrada no topo. O Banco Genial, com R$ 3,33 bilhões (12,85%), e o BTG Pactual Serviços Financeiros DTVM, com R$ 2,72 bilhões (10,48%), consolidam suas posições como players relevantes, enquanto o Banco Daycoval fecha o top 5 com R$ 2,59 bilhões (9,98%). ',
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Montante',
      ranks: [
        {
          name: 'XP Investimentos',
          percentage: 18.24,
          value: 4734887798,
          institutionId: 6659,
          slug: 'xp-investimentos',
          uqbarId: 6659,
          position: 1,
        },
        {
          name: 'Intrag DTVM',
          percentage: 18.23,
          value: 4731151105,
          institutionId: 6361,
          slug: 'intrag-dtvm',
          uqbarId: 6361,
          position: 2,
        },
        {
          name: 'Banco Genial',
          percentage: 12.85,
          value: 3334233401,
          institutionId: 7137,
          slug: 'banco-genial',
          uqbarId: 7137,
          position: 3,
        },
        {
          name: 'BTG Pactual Serviços Financeiros DTVM',
          percentage: 10.48,
          value: 2720245931,
          institutionId: 6613,
          slug: 'btg-pactual-servicos-financeiros-dtvm',
          uqbarId: 6613,
          position: 4,
        },
        {
          name: 'Banco Daycoval',
          percentage: 9.98,
          value: 2590886138,
          institutionId: 6499,
          slug: 'banco-daycoval',
          uqbarId: 6499,
          position: 5,
        },
      ],
    },
  },
  {
    name: 'Ranking de Administrador de FIAGRO-FII/FIDC por Número de Fundos',
    details: {
      title: 'Ranking de Administrador de FIAGRO-FII/FIDC por Número de Fundos',
      texts: [
        'O ranking dos administradores de FIAGRO-FIDC e FIAGRO-FII, organizado pelo número de fundos administrados, destaca a capacidade operacional e a abrangência das principais instituições no mercado agropecuário brasileiro. O Banco Daycoval lidera com 18 fundos, representando 15,13% do total, seguido pela Singulare, com 14 fundos (11,76%), evidenciando sua forte presença no segmento. A XP Investimentos, com 12 fundos (10,08%), e o BTG Pactual Serviços Financeiros DTVM, com 11 fundos (9,24%), aparecem em seguida, empatados em número com a Vórtx, também com 11 fundos (9,24%), o que reflete uma disputa acirrada entre grandes players. ',
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Núm. de Fundos',
      ranks: [
        {
          name: 'Banco Daycoval',
          percentage: 15.13,
          value: 18,
          institutionId: 6499,
          slug: 'banco-daycoval',
          uqbarId: 6499,
          position: 1,
        },
        {
          name: 'Singulare',
          percentage: 11.76,
          value: 14,
          institutionId: 7264,
          slug: 'singulare',
          uqbarId: 7264,
          position: 2,
        },
        {
          name: 'XP Investimentos',
          percentage: 10.08,
          value: 12,
          institutionId: 6659,
          slug: 'xp-investimentos',
          uqbarId: 6659,
          position: 3,
        },
        {
          name: 'BTG Pactual Serviços Financeiros DTVM',
          percentage: 9.24,
          value: 11,
          institutionId: 6613,
          slug: 'btg-pactual-servicos-financeiros-dtvm',
          uqbarId: 6613,
          position: 4,
        },
        {
          name: 'Vórtx',
          percentage: 9.24,
          value: 11,
          institutionId: 8052,
          slug: 'vortx',
          uqbarId: 8052,
          position: 5,
        },
      ],
    },
  },
  {
    name: 'Ranking de Gestor de FIAGRO-FII/FIDC por PL',
    details: {
      title: 'Ranking de Gestor de FIAGRO-FII/FIDC por PL',
      texts: [
        'O ranking dos gestores de FIAGRO-FIDC e FIAGRO-FII, organizado pelo critério de patrimônio líquido, evidencia a força e a especialização das principais gestoras no mercado de fundos voltados ao agronegócio. A Milenio Capital lidera com um patrimônio de R$ 3,10 bilhões, representando 9,59% do total, seguida pela Kinea Investimentos, com R$ 2,63 bilhões (8,13%), destacando-se como referências em gestão de recursos no setor. O BTG Pactual Gestora de Recursos, com R$ 2,11 bilhões (6,55%), e a Genial Gestão, com R$ 2,06 bilhões (6,38%), aparecem logo atrás, enquanto a XP Vista Asset Management fecha o top 5 com R$ 1,87 bilhão (5,77%)',
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Montante',
      ranks: [
        {
          name: 'Milenio Capital',
          percentage: 9.59,
          value: 3100466041,
          institutionId: 7311,
          slug: 'milenio-capital',
          uqbarId: 7311,
          position: 1,
        },
        {
          name: 'Kinea Investimentos',
          percentage: 8.13,
          value: 2630733837,
          institutionId: 6578,
          slug: 'kinea-investimentos',
          uqbarId: 6578,
          position: 2,
        },
        {
          name: 'BTG Pactual Gestora de Recursos',
          percentage: 6.55,
          value: 2117993810,
          institutionId: 6482,
          slug: 'btg-pactual-gestora-de-recursos',
          uqbarId: 6482,
          position: 3,
        },
        {
          name: 'Genial Gestão',
          percentage: 6.38,
          value: 2063973682,
          institutionId: 7160,
          slug: 'genial-gestao',
          uqbarId: 7160,
          position: 4,
        },
        {
          name: 'XP Vista Asset Management',
          percentage: 5.77,
          value: 1867568759,
          institutionId: 7370,
          slug: 'xp-vista-asset-management',
          uqbarId: 7370,
          position: 5,
        },
      ],
    },
  },
  {
    name: 'Ranking de Gestor de FIAGRO-FII/FIDC por Número de Fundos',
    details: {
      title: 'Ranking de Gestor de FIAGRO-FII/FIDC por Número de Fundos',
      texts: [
        'O ranking dos gestores de FIAGRO-FIDC e FIAGRO-FII, organizado pelo critério de número de fundos geridos, destaca a capacidade operacional e a diversificação das principais gestoras no setor agropecuário. A Eco Gestão de Ativos lidera com 10 fundos, representando 5,81% do total, seguida pela Milenio Capital, com 8 fundos (4,65%), evidenciando sua especialização na estruturação de FIAGROs. O BTG Pactual Gestora de Recursos e a Asset Bank Asset Management aparecem empatados com 7 fundos cada (4,07%), enquanto a Genial Gestão fecha o top 5 com 6 fundos (3,49%).',
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Núm. de Fundos',
      ranks: [
        {
          name: 'Eco Gestão de Ativos',
          percentage: 5.81,
          value: 10,
          institutionId: 6707,
          slug: 'eco-gestao-de-ativos',
          uqbarId: 6707,
          position: 1,
        },
        {
          name: 'Milenio Capital',
          percentage: 4.65,
          value: 8,
          institutionId: 7311,
          slug: 'milenio-capital',
          uqbarId: 7311,
          position: 2,
        },
        {
          name: 'BTG Pactual Gestora de Recursos',
          percentage: 4.07,
          value: 7,
          institutionId: 6482,
          slug: 'btg-pactual-gestora-de-recursos',
          uqbarId: 6482,
          position: 3,
        },
        {
          name: 'Asset Bank Asset Management',
          percentage: 4.07,
          value: 7,
          institutionId: 7869,
          slug: 'asset-bank-asset-management',
          uqbarId: 7869,
          position: 4,
        },
        {
          name: 'Genial Gestão',
          percentage: 3.49,
          value: 6,
          institutionId: 7160,
          slug: 'genial-gestao',
          uqbarId: 7160,
          position: 5,
        },
      ],
    },
  },
];
