import { SvgIcon, SvgIconProps } from '@mui/material';

export function FIAGROIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.064 0L7.2 2.847l.573.578 1.145-1.131.9.895v1.863c0 .903-.728 1.627-1.636 1.627H7.74A4.865 4.865 0 019 9.933a4.853 4.853 0 01-.074.813h2.553c.1-.894.53-1.72 1.204-2.321a3.694 3.694 0 014.499-.315V4.238c0-.903-.728-1.627-1.636-1.627h-5.147l-.9-.895L10.636.578 10.064 0zm-7.61 3.425a.818.818 0 00-.818.813c0 .448.369.814.819.814h4.09c0-.895-.728-1.627-1.636-1.627H2.455zm1.637 2.44c-.434 0-.865.069-1.276.204l.294.756-.384.147-.27-.757a4.081 4.081 0 00-2.013 1.88l.744.333-.171.366-.737-.326A4.047 4.047 0 000 9.933c0 .43.07.86.205 1.269l.76-.293.148.382-.761.269a4.077 4.077 0 001.89 2l.327-.74.376.171-.327.732c.47.182.97.276 1.473.277.434 0 .864-.069 1.276-.203l-.294-.757.384-.146.27.756a4.081 4.081 0 002.013-1.879l-.745-.325.172-.374.737.325c.182-.467.277-.963.278-1.464 0-.432-.07-.86-.205-1.27l-.76.293-.148-.382.761-.268a4.077 4.077 0 00-1.89-2.002l-.327.74-.377-.17.328-.732a4.112 4.112 0 00-1.473-.277zm0 1.627c.65 0 1.275.257 1.736.715a2.433 2.433 0 010 3.451 2.462 2.462 0 01-3.472 0 2.434 2.434 0 010-3.451 2.462 2.462 0 011.736-.715zm11.045.814c-.76 0-1.487.3-2.024.834a2.839 2.839 0 000 4.026 2.872 2.872 0 004.05 0 2.84 2.84 0 000-4.026 2.872 2.872 0 00-2.026-.834zm0 1.627c.326 0 .638.128.868.357a1.217 1.217 0 010 1.726 1.23 1.23 0 01-1.735 0 1.216 1.216 0 010-1.726 1.23 1.23 0 01.867-.357z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
