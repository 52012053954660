import { SvgIcon, SvgIconProps } from '@mui/material';

export function FIDCIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.4 3H1.6V1.5h12.8m0 9H1.6V6h12.8m0-6H1.6C.712 0 0 .667 0 1.5v9c0 .398.169.78.469 1.06.3.282.707.44 1.131.44h12.8c.424 0 .831-.158 1.131-.44.3-.28.469-.662.469-1.06v-9c0-.833-.72-1.5-1.6-1.5z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
