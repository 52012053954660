import {
  fiiPrimaryMarketCharts,
  fiiSecondaryMarketCharts,
  fiiStockMarketCharts,
} from './charts/FII';
import {
  YearbookDescriptions,
  YearbookMarketChartInfo,
  YearbookMarketRanking,
} from '../types';

export const fiiOpeningTexts = [
  `
  Em 2024, o mercado de Fundos de Investimento Imobiliário (FIIs) viveu um ano marcado por retomadas vigorosas e novos recordes, consolidando sua posição como uma das principais alternativas de investimento no Brasil. 
  Ao longo deste anuário, você terá acesso a uma análise detalhada dos principais movimentos do setor, incluindo o desempenho excepcional das emissões no mercado primário, que ultrapassaram R$ 47 bilhões, recuperando-se após dois anos de retração.
  `,
  `
  Além disso, você encontrará uma visão abrangente sobre o mercado secundário, que atingiu cifras históricas tanto no montante negociado, ultrapassando R$ 93 bilhões, quanto no número de transações, que bateu a marca dos 184 milhões de negócios. Esse crescimento reflete a crescente maturidade e liquidez dos FIIs na B3.
  `,
  `
  A seção de Estoque trará uma análise detalhada da expansão no Patrimônio Líquido total, que rompeu a barreira dos R$ 350 bilhões, e no número de fundos ativos, superando mil FIIs pela primeira vez na história. Destaca-se também a crescente preferência dos investidores pela gestão ativa e a mudança no perfil dos imóveis, com o segmento de logística assumindo protagonismo.
  `,
  `
  Por fim, os Rankings exclusivos da Uqbar apresentarão uma visão completa sobre os fundos, gestores, 
  administradores, auditores e assessores jurídicos que se destacaram em 2024. Nomes como Maxi Renda, XP Malls e Kinea Investimentos aparecem com destaque, refletindo estratégias bem-sucedidas e tendências de mercado que certamente influenciarão o futuro próximo.
  `,
  `
  Explore nas próximas páginas um panorama completo e detalhado sobre os grandes protagonistas e tendências que moldaram o mercado brasileiro de FIIs em 2024.
  `,
];

export const fiiYearbookDescriptions: YearbookDescriptions = {
  primary: [
    `Em 2024, as emissões de cotas de FIIs apresentaram uma expressiva recuperação, com captações atingindo a marca de R$ 47,4 bilhões. 
    Esse volume representou um crescimento robusto de aproximadamente 39% em comparação ao ano anterior, consolidando uma retomada vigorosa após dois anos consecutivos de retração nas captações.`,

    `
    Ao analisar as emissões por tipo de ativo, os fundos com foco em imóveis permaneceram dominantes, respondendo por 46,09% do total emitido, uma ampliação significativa em relação aos 38,67% observados em 2023. 
    Simultaneamente, os fundos direcionados à renda fixa também conquistaram maior relevância no mercado, saltando para 29,25% das emissões, impulsionados pela busca dos investidores por ativos de menor volatilidade.
    `,

    `
    Por outro lado, os fundos "Multiclasse" mantiveram uma presença estável, representando 7,53% das emissões totais. Já os fundos focados em renda variável sofreram uma leve redução, totalizando 13,32% das emissões no período analisado. 
    Um destaque negativo ficou por conta dos FIAGRO, cuja participação caiu consideravelmente para 3,82% do volume emitido, frente aos quase 20% registrados em 2023, refletindo os desafios específicos enfrentados pelo setor.
    `,

    `
    Quando analisado o mercado por tipo de imóvel, o segmento de "Varejo" se destacou como líder em emissões, representando 25,29% do volume captado em 2024. 
    Apesar de registrar uma pequena queda percentual em relação ao ano anterior, o montante absoluto captado cresceu cerca de 40%, passando de R$ 4,23 bilhões para R$ 5,85 bilhões.
    `,

    `
    O setor "Logística" continuou relevante, respondendo por 25,20% das emissões. Embora tenha sofrido uma leve redução de participação relativa em relação ao ano anterior, o montante captado cresceu aproximadamente 6%, alcançando R$ 5,85 bilhões. 
    Já o segmento de "Escritórios" mostrou sinais claros de recuperação, elevando sua participação para 11,3% das emissões, um aumento expressivo frente aos 1,49% registrados em 2023, sugerindo uma renovação do interesse por essa categoria após um período de baixo desempenho.
    `,
  ],
  secondary: [
    `
    Em 2024, o mercado secundário de Fundos Imobiliários registrou um desempenho notável, destacando-se como um dos pilares da robustez e da atratividade desse segmento para os investidores. 
    Impulsionado por um crescente interesse em ativos de renda, o ano marcou recordes tanto no volume negociado quanto no número de transações.`,

    `O montante negociado atingiu o patamar histórico de R$ 93,98 bilhões, um expressivo crescimento de 35,8% em relação ao ano anterior. 
    Esse avanço é reflexo da liquidez crescente e da maturidade adquirida pelo mercado, demonstrando a consolidação dos FIIs como instrumentos estratégicos na composição das carteiras de investimento.`,

    `
    Em linha com essa expansão, o número de negócios realizados alcançou a cifra de 184.494.484 transações em 2024, representando um aumento de 34,5% na comparação anual. 
    Esse indicador reforça não apenas o vigor do mercado secundário, mas também a diversificação de participantes, com cada vez mais investidores buscando oportunidades e flexibilidade na negociação das cotas.    
    `,
    'A seguir, detalharemos as movimentações e destaques do mercado secundário ao longo do ano, oferecendo uma visão completa sobre os principais indicadores que marcaram a dinâmica de negociação dos Fundos Imobiliários na B3.',
  ],
  stock: [
    'O mercado de FII registrou crescimento significativo tanto no Patrimônio Líquido (PL) quanto na quantidade de fundos ativos, ultrapassando importantes marcos históricos e demonstrando forte resiliência diante de desafios econômicos.',

    `Destaca-se o avanço expressivo no volume total de recursos sob gestão, que ultrapassou pela primeira vez a marca dos R$ 350 bilhões, refletindo um crescimento de 11,8% em relação ao ano anterior. 
    Além disso, o mercado de FIIs rompeu a barreira simbólica de 1.000 fundos ativos, indicando uma expansão de 12,9% e confirmando o aumento da popularidade dessa classe de ativos entre investidores brasileiros.`,

    `Em termos de composição, o patrimônio dos FIIs permanece fortemente concentrado em ativos imobiliários tradicionais, como logística e imóveis comerciais, mas com uma crescente relevância dos fundos focados em ativos financeiros e estratégias alternativas. 
    Fundos de renda fixa e FIAGRO-FII registraram aumentos notáveis em suas participações, evidenciando a contínua diversificação dos portfólios.`,

    'Outro destaque relevante foi o avanço dos FIIs de gestão ativa, que agora representam aproximadamente 90% do Patrimônio Líquido total, resultado de uma preferência crescente dos investidores por estratégias mais dinâmicas e flexíveis, especialmente em um cenário econômico de rápidas transformações.',

    `Por fim, a mudança no perfil dos imóveis que compõem os FIIs chamou atenção em 2024. 
    O segmento de Logística consolidou-se como o mais representativo em termos de patrimônio, superando segmentos tradicionais como Escritórios. 
    Essa mudança reflete tendências mais amplas do mercado imobiliário, impactadas pelo crescimento do comércio eletrônico e pelas novas demandas de infraestrutura logística no país. 
    Confira, a seguir, uma análise detalhada dessas transformações e tendências que marcaram o mercado de FIIs ao longo do último ano.`,
  ],
  rankings: [
    'A seção de Rankings do Anuário Uqbar de FII revela um cenário dinâmico e promissor, marcado pela ascensão de novos líderes, crescimento expressivo nas operações e significativa diversificação nos produtos ofertados. A seguir, você terá acesso a análises exclusivas e detalhadas, com destaque para fundos, gestores, administradores e assessores jurídicos que marcaram presença de forma relevante no último ano.',
    'Entre os fundos imobiliários, destaca-se o Maxi Renda, que liderou tanto em montante emitido quanto em número de negociações, refletindo sua atratividade junto aos investidores. Por outro lado, o XP Malls consolidou sua posição no mercado secundário, movimentando mais de R$ 3,8 bilhões em negociações, o maior volume do ano.',
    'Entre os escritórios de advocacia, Ishikawa, Peyser Advogados ocupou o topo dos rankings, tanto em número de operações quanto em montante emitido, comprovando sua relevância estratégica nas assessorias jurídicas das principais emissões.',
    'No campo da valorização, fundos como o Edifício Almirante Barroso surpreenderam com uma variação de preço próxima de 100%, enquanto o Ourinvest RE I destacou-se com dividend yields superiores a 45%, mostrando a força das estratégias focadas em retorno ao investidor.',
    'Além disso, administradores como o BTG Pactual Serviços Financeiros DTVM dominaram o mercado em capitalização e número de fundos administrados, refletindo sua posição consolidada no setor. Já entre os gestores, a Kinea Investimentos reforçou sua liderança, administrando quase R$ 30 bilhões em ativos.',
    'Esses resultados evidenciam a evolução constante do mercado de FIIs, destacando as estratégias vencedoras, as instituições em ascensão e as tendências que moldarão o futuro do setor. Confira agora todos os detalhes e descubra quem foram os grandes protagonistas do mercado imobiliário em 2024.',
  ],
};

export const fiiStockMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fiiStockMarketCharts.plFII,
    name: 'Patrimônio Líquido dos FIIs',
    section: 'stock',
    details: {
      title: 'PL dos FIIs em 2024',
      texts: [
        `O mercado de FIIs manteve sua trajetória de crescimento ao longo de 2024, com aumentos tanto no Patrimônio Líquido (PL) quanto no número de fundos ativos. 
        No final do ano, o PL ultrapassou R$ 350 bilhões, estabelecendo um novo recorde histórico. O número de FIIs também seguiu essa tendência, superando a marca de 1.000 fundos ativos. Em comparação com dezembro de 2023, o PL e o número de FIIs expandiram, respectivamente, 11,8% e 12,9%.`,
      ],
      value: 0,
      valueTitle: 'Patrimônio Líquido Total',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiiStockMarketCharts.plByAssetTypeFII,
    name: 'Patrimônio Líquido por Tipo de Ativo',
    section: 'stock',
    details: {
      title: 'FIIs de Imóvel se mantém como maior destaque histórico',
      texts: [
        `Em dezembro de 2024, os FIIs com ativos imobiliários permaneceram como a principal categoria, representando cerca de 62,6% do Patrimônio Líquido (PL) total. 
        Os FIIs de renda fixa continuaram a ganhar espaço, alcançando aproximadamente 21,2% do PL. Já os FIIs de renda variável registraram uma leve redução, representando 7,7% do total, enquanto o FIAGRO-FII manteve sua trajetória de crescimento, atingindo cerca de 5,3% do PL.`,
      ],
    },
  },
  {
    chart: fiiStockMarketCharts.plByGoalTypeFII,
    name: 'Patrimônio Líquido por Tipo de Finalidade',
    section: 'stock',
    details: {
      title: 'PL por tipo de Finalidade',
      texts: [
        `Ao final de 2024, os FIIs com foco na obtenção de renda permaneceram como a principal categoria, representando aproximadamente 37,4% do Patrimônio Líquido (PL) total. 
        Em seguida, os FIIs de finalidade diversa, que englobam estratégias como ganho de capital, securitização e desenvolvimento, totalizaram cerca de 34,7% do PL. Já os FIIs voltados à gestão de ativos financeiros consolidaram sua participação, atingindo 25,6% do PL.`,
      ],
    },
  },
  {
    chart: fiiStockMarketCharts.plByManagementTypeFII,
    name: 'Patrimônio Líquido por Tipo de Gestão',
    section: 'stock',
    details: {
      title: 'FIIs de Gestão ativa possuem maior visibilidade histórica',
      texts: [
        `Os FIIs de gestão ativa, nos quais os gestores possuem maior dinamismo na composição da carteira, consolidaram-se ao longo dos anos. No início de 2018, os FIIs de gestão passiva representavam cerca de 34% do patrimônio líquido (PL) do mercado. 
        No entanto, essa participação diminuiu continuamente, chegando a aproximadamente 10% em dezembro de 2024. Assim, os FIIs de gestão ativa passaram a representar cerca de 90% do PL total do mercado de FIIs, reforçando a predominância dessa estratégia ao longo do tempo.`,
      ],
    },
  },
  {
    chart: fiiStockMarketCharts.plByPropertyTypeFII,
    name: 'Patrimônio Líquido por Tipo de Imóvel',
    section: 'stock',
    details: {
      title: 'FIIs de Logística dominam em PL',
      texts: [
        `Historicamente, é possível observar que os FIIs de Logística têm ganhado espaço de forma consistente em termos de PL. 
        Uma análise recente realizada pela Uqbar mostra que o segmento logístico continua a se destacar no mercado de FIIs, consolidando-se como um dos mais relevantes.`,

        `No final de 2024, os fundos de logística se tornaram a maior fatia do mercado de FIIs, representando aproximadamente 22,56% do total, um crescimento expressivo em relação aos 5,38% observados em janeiro de 2018. 
        Esse avanço foi impulsionado pelo crescimento de fundos como Prologis Brazil Logistics Venture (PBLV11) com R$ 6,57 bilhões, Patria LOG RLtda (HGLG11) com R$ 5,51 bilhões e BTG Pactual Logística (BTLG11) com R$ 4,45 bilhões, que se destacam entre os maiores do setor.`,

        `Por outro lado, os FIIs de Escritórios vêm perdendo relevância ao longo dos anos, sendo ultrapassados por segmentos como Varejo e outros setores híbridos. 
        Essa mudança reflete a evolução das preferências do mercado e os impactos de novas dinâmicas no setor imobiliário.`,
      ],
    },
  },
];

export const fiiSecondaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fiiSecondaryMarketCharts.secondaryFII,
    name: 'Mercado Secundário',
    section: 'secondary',
    details: {
      title: 'Novo recorde de montante negociado em 2024',
      texts: [
        `O mercado secundário de cotas é composto por indicadores como o montante negociado e o número de negócios com cotas de FIIs na B3. 
        Em 2024, o montante negociado atingiu um novo recorde de R$93,98 bilhões, representando um crescimento expressivo de 35,8% em relação a 2023, quando o valor negociado foi de R$69,2 bilhões.`,

        'O número de negócios também registrou um aumento significativo, alcançando 184.494.484 transações em 2024, um crescimento de 34,5% em comparação às 137.144.578 negociações realizadas no ano anterior.',
      ],
      value: 93.98,
      valueTitle: 'Montante Negociado 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
];

export const fiiPrimaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fiiPrimaryMarketCharts.emissionsFII,
    name: 'Emissões dos FIIs',
    section: 'primary',
    details: {
      title: 'Emissões de FII',
      texts: [
        `As emissões de FIIs no mercado primário tiveram um crescimento expressivo em 2024, atingindo R$ 47,4 bilhões em captações. 
        O montante representa um aumento de aproximadamente 38,96% em relação a 2023, quando as emissões totalizaram R$ 34,11 bilhões, 
        sinalizando uma forte recuperação após dois anos de queda no volume captado.`,
      ],
      value: 47.4,
      valueTitle: 'Montante Emitido 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiiPrimaryMarketCharts.variableIncomeFII,
    name: 'Emissões de FII por Tipo de Ativo',
    section: 'primary',
    details: {
      title: 'Emissões de FII por Tipo de Ativo',
      texts: [
        'Em 2024, os FIIs do segmento "Imóvel" seguiram como os mais representativos no mercado, com 46,09% do total de emissões, registrando um aumento em relação a 2023, quando correspondiam a 38,67%.',
        'Os fundos de "Renda Fixa" também ampliaram sua participação, alcançando 29,25% das emissões em 2024, um crescimento expressivo em relação aos 19,08% do ano anterior, impulsionado pelo maior interesse em ativos de menor volatilidade.',
        'Os fundos "Multiclasse" mantiveram uma presença estável, com 7,53% das emissões, enquanto os FIIs de "Renda Variável" registraram um leve recuo, totalizando 13,32% das emissões de 2024. Já os FIAGRO tiveram uma queda significativa, representando apenas 3,82% das emissões em 2024, frente aos 19,91% de 2023, refletindo os desafios enfrentados pelo setor.',
      ],
      value: 0,
      valueTitle: 'Montante Emitido 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiiPrimaryMarketCharts.emissionsByPropertyTypeFII,
    name: 'Emissões de FII por Tipo de Imóvel',
    section: 'primary',
    details: {
      title:
        'Participação dos FIIs de Escritórios em emissões reduz drasticamente.',
      texts: [
        `O segmento "Logística" foi o mais representativo, correspondendo a uma parcela significativa de 26,78% do montante emitido em 2024. 
        Embora continue sendo um dos setores de maior peso, sua participação relativa sofreu uma retração de 15,55 pp. em comparação a 2023. No entanto, em termos absolutos, o montante emitido teve um crescimento de quase 5%, passando de R$5,58 bilhões para R$5,85 bilhões em 2024.`,

        'O setor de "Varejo" representou 26,76% das emissões em 2024. Apesar da ligeira queda na participação percentual em relação ao ano anterior, o segmento registrou um aumento expressivo de 40% no montante emitido, subindo de R$4,18 bilhões para R$5,85 bilhões.',

        'Por outro lado, o segmento "Escritórios" apresentou variações moderadas, representando 12,19% das emissões em 2024. Este segmento demonstrou sinais de recuperação após uma participação ínfima de 1,61% em 2023.',
      ],
      value: 14.23,
      valueTitle: 'Montante Emitido 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
];

export const fiiRankings: YearbookMarketRanking[] = [
  {
    name: 'Ranking de FII por Montante Emitido em 2024',
    details: {
      title: 'Ranking de FII por Montante Emitido em 2024',
      texts: [
        'No mercado primário, o ranking de FIIs por Montante Emitido agora conta com 330 fundos. Os três primeiros colocados representam uma concentração de 10,94% do total. Na primeira posição, o Maxi Renda soma R$ 1,75 bilhões, correspondendo a 3,69%. A segunda posição do ranking é pertence XP Malls, com R$ 1,74 bilhões e uma participação de 3,66%. Fechando o ranking o Btg Pactual Real Estate Hedge Fund ocupa a terceira colocação, com R$ 1,63 bilhões, correspondendo a 3,44% do PL.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=quotafiiallmarket&criteria=amountissued&timeRange=yearly',
  },
  {
    name: 'Ranking de FII por Montante Emitido em 2024 (Fundos Listados)',
    details: {
      title: 'Ranking de FII por Montante Emitido em 2024 (Fundos Listados)',
      texts: [
        'O mercado primário de fundos com cotas listadas na B3 conta atualmente com 180 participantes. O ranking de FIIs por montante emitido é liderado pelo Maxi Renda, que soma R$ 1,75 bilhões e representa 3,93% do total. Na segunda posição, o XP Malls detém R$ 1,74 bilhões, correspondendo a 3,90%. Fechando o top 3, o BTG Pactual Real Estate Hedge Fund ocupa a terceira colocação, com R$ 1,63 bilhões e uma participação de 3,67%. A concentração dos três primeiros colocados atinge 13,41% do mercado.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=primarymarketlisted&criteria=amountissued&timeRange=yearly',
  },
  {
    name: 'Ranking de Advogados por Montante Emitido em 2024',
    details: {
      title: 'Ranking de Advogados por Montante Emitido em 2024',
      texts: [
        'O ranking de Assessores Jurídicos conta com 19 participantes e um montante total de R$ 10,40 bilhões. O primeiro lugar é ocupado pelo escritório Ishikawa, Peyser Advogados, que soma R$ 2,55 bilhões, correspondendo a 24,53% do total. Em segundo lugar, está o Tauil e Chequer Sociedade de Advogados, com R$ 2,00 bilhões, equivalente a 19,23%. Já o terceiro lugar fica com o PMK Advogados Associados, que detém R$ 1,26 bilhões, representando 12,15% do total.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=legaladvisor&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Advogados por Número de Operações em 2024',
    details: {
      title: 'Ranking de Advogados por Número de Operações em 2024',
      texts: [
        'O ranking de Assessores Jurídicos reúne 19 participantes e contabiliza um total de 85 operações, sendo o Top 3 concentrando 45,9% do mercado. Na liderança, o escritório Ishikawa, Peyser Advogados se destaca com 16 operações, representando 18,82% do total. Em seguida, o Tauil e Chequer Sociedade de Advogados ocupa a segunda posição, com 13 operações, equivalente a 15,29%. Fechando o top 3, o PMK Advogados Associados soma 10 operações, correspondendo a 11,76% do total.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=legaladvisor&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Cotas de FII por Montante Negociado em 2024',
    details: {
      title: 'Ranking de Cotas de FII por Montante Negociado em 2024',
      texts: [
        'O ranking de cotas de FII por Montante Negociado em 2024 conta com 445 participantes, totalizando R$ 91,24 bilhões em volume negociado. Os três primeiros colocados representam juntos 11,58% desse total.',
        'O XP Malls lidera o ranking, com R$ 3,81 bilhões em negociações, equivalente a 4,06% do mercado. Em seguida, o Maxi Renda ocupa a segunda posição, somando R$ 3,53 bilhões, ou 3,75% do total. Fechando o pódio, o Kinea Rendimentos Imobiliários aparece em terceiro lugar, com R$ 3,24 bilhões, correspondendo a 3,44% do volume negociado.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=quotafii&criteria=amountnegotiated&timeRange=yearly',
  },
  {
    name: 'Ranking de Cotas de FII por Número de Negócios em 2024',
    details: {
      title: 'Ranking de Cotas de FII por Número de Negócios em 2024',
      texts: [
        'O ranking de cotas de FII negociadas no mercado secundário contabiliza um total de 175.056.147 negócios e 445 participantes ranqueados. A liderança pertence ao Maxi Renda, que acumula 10.187.785 negócios, correspondendo a 5,52% do total. Na segunda posição está o Capitânia Securities II, com 7.226.403 negócios, o que equivale a 3,92% do volume total. Em terceiro lugar está o Valora CRI CDI com 4.977.119 negócios (2,70%).',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=quotafii&criteria=tradesnumber&timeRange=yearly',
  },
  {
    name: 'Ranking de Cotas de FII por Variação de Preço em 2024',
    details: {
      title: 'Ranking de Cotas de FII por Variação de Preço em 2024',
      texts: [
        'O ranking de cotas de Fundos Imobiliários por variação de preço destaca três fundos com desempenhos notáveis. Em primeiro lugar, o Edifício Almirante Barroso, que apresentou uma variação de 96% no período analisado. Em segundo, o Ourinvest RE I, com um crescimento de 75%. Fechando o pódio, o REAG Multi Ativos Imobiliários, que obteve uma valorização de 36%.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=quotafii&criteria=pricevariance&timeRange=yearly',
  },
  {
    name: 'Ranking de Cotas de FII por Dividend Yield Realizado em 2024',
    details: {
      title: 'Ranking de Cotas de FII por Dividend Yield Realizado em 2024',
      texts: [
        'O Ranking de Cotas de FII por Dividend Yield (DY) Realizado conta na primeira posição o Ourinvest RE I, com um DY realizado de 45,6% ao ano. Em segundo lugar, está o FIAGRO JGP Crédito , que apresenta um retorno de 40,4% ao ano. Fechando o top 3, o RBR Desenvolvimento Comercial Feeder FOF registra um DY de 24,4% ao ano.',
        'O ranking conta com 284 participantes ranqueados, e a concentração dos três primeiros colocados soma 4,12% do total.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=quotafii&criteria=dividendyield&timeRange=yearly',
  },
  {
    name: 'Ranking de Cotas de FII por Rentabilidade Efetiva em 2024',
    details: {
      title: 'Ranking de Cotas de FII por Rentabilidade Efetiva em 2024',
      texts: [
        'A rentabilidade efetiva, correspondente à taxa interna de retorno (TIR), destaca os fundos com melhor desempenho. No topo do ranking, o fundo Edifício Almirante Barroso lidera com uma rentabilidade de 95,61%. Em segundo lugar, Ourinvest RE I registra 74,99%, seguido pelo REAG Multi Ativos Imobiliários, ocupando a terceira posição com 36,45%.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=quotafii&criteria=effectivereturn&timeRange=yearly',
  },
  {
    name: 'Ranking de Cotas de FII por Capitalização de Mercado',
    details: {
      title: 'Ranking de Cotas de FII por Capitalização de Mercado',
      texts: [
        'A rentabilidade efetiva é equivalente à taxa interna de retorno (TIR). Na primeira posição, está o Kinea Rendimentos Imobiliários, que acumula um montante de R$ 7,59 bilhões, representando 4,60% do total. Em segundo lugar, encontra-se o Kinea Índices de Preços, com um volume de R$ 7,02 bilhões, correspondendo a 4,26% do mercado. Fechando o top 3, o XP Malls possui um montante de R$ 5,33 bilhões, equivalente a 3,23% do total.',
        'O ranking conta com 311 participantes ranqueados, e a concentração dos três primeiros colocados soma 12,09% do montante total de R$ 164,91 bilhões.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=quotafii&criteria=capitalization&timeRange=yearly',
  },
  {
    name: 'Ranking de Cotas de FII por Capitalização de Mercado sobre Patrimônio Líquido',
    details: {
      title:
        'Ranking de Cotas de FII por Capitalização de Mercado sobre Patrimônio Líquido',
      texts: [
        'No topo do ranking, encontra-se o Kinea Rendimentos Imobiliários, com um montante de R$ 7,59 bilhões, representando 4,60% do total. Em segundo lugar, está o Kinea Índices de Preços, que acumula R$ 7,02 bilhões em valor de mercado, correspondendo a 4,26% do total. Fechando o pódio, o XP Malls ocupa a terceira posição, com R$ 5,33 bilhões, equivalente a 3,23% do mercado. Juntos, os três maiores fundos representam 12,09% do montante total de R$ 164,91 bilhões, considerando os 311 fundos ranqueados.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=quotafii&criteria=capitalization&timeRange=yearly',
  },
  {
    name: 'Ranking de Administradores por Capitalização de Mercado',
    details: {
      title: 'Ranking de Administradores por Capitalização de Mercado',
      texts: [
        'O Ranking de Administradores por Capitalização de Mercado destaca o BTG Pactual Serviços Financeiros DTVM como líder do setor, com uma participação de 25,05% e um montante de R$ 48,40 bilhões. Na segunda posição, a Intrag DTVM detém 17,20% do mercado, administrando R$ 33,24 bilhões, enquanto a BRL Trust DTVM ocupa o terceiro lugar, com 11,14% e um total de R$ 21,53 bilhões sob gestão.',
        'Juntos, os três principais administradores concentram 53,37% do mercado dentre os 37 participantes, refletindo sua relevância no setor.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=admin&criteria=capitalization&timeRange=yearly',
  },
  {
    name: 'Ranking de Administradores por Número de Fundos Negociados em 2024',
    details: {
      title:
        'Ranking de Administradores por Número de Fundos Negociados em 2024',
      texts: [
        'O Ranking de Administradores por Número de Fundos Negociados registrou em 2024 um total de 455 fundos imobiliários negociados com o grande destaque foi o BTG Pactual Serviços Financeiros DTVM, que liderou com 111 fundos negociados, consolidando sua posição de referência no setor. Na segunda colocação, a BRL Trust DTVM movimentou 59 fundos, enquanto a Vórtx ocupou o terceiro lugar, com 32 fundos negociados. Juntas, essas três administradoras representam 44,39% do total de negócios no mercado.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=admin&criteria=negotiatedefundnumber&timeRange=yearly',
  },
  {
    name: 'Ranking de Gestores por Capitalização de Mercado em 2024',
    details: {
      title: 'Ranking de Gestores por Capitalização de Mercado em 2024',
      texts: [
        'O Ranking de Gestores por Capitalização de Mercado totalizou R$ 270,03 bilhões, com 204 gestores ranqueados. Os três primeiros colocados somam 22,26% de participação. A Kinea Investimentos lidera o ranking, administrando R$ 29,61 bilhões, consolidando sua posição de destaque. Em segundo lugar, a XP Vista Asset Management detém R$ 17,15 bilhões, seguida pela XP Gestão de Recursos, que ocupa a terceira posição com R$ 13,31 bilhões sob gestão.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=manager&criteria=capitalization&timeRange=yearly',
  },
  {
    name: 'Ranking de Gestores por Número de Fundos Negociados em 2024',
    details: {
      title: 'Ranking de Gestores por Número de Fundos Negociados em 2024',
      texts: [
        'O Ranking de Gestores por Número de Fundos Negociados registrou um total de 518 fundos imobiliários em 2024, distribuídos entre 204 gestores ranqueados. Os três primeiros colocados somam 9,09% de participação. A XP Vista Asset Management lidera o ranking com 16 fundos negociados, compartilhando a primeira posição com a Hedge Investments Real Estate Gestão de Recursos, que também administra 16 fundos. Na terceira posição, a Kinea Investimentos gerencia 15 fundos, consolidando sua presença entre os principais gestores do setor.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=manager&criteria=negotiatedefundnumber&timeRange=yearly',
  },
  {
    name: 'Ranking de FII por Patrimônio Líquido em 2024',
    details: {
      title: 'Ranking de FII por Patrimônio Líquido em 2024',
      texts: [
        'O Ranking de Fundos Imobiliários por Patrimônio Líquido (PL) registrou um total de R$ 16,06 bilhões em 2024, distribuídos entre 202 fundos ranqueados. Os três primeiros colocados representam 14,48% do total. O BB Premium Malls RLtda lidera o ranking, com um patrimônio líquido de R$ 991,21 milhões, equivalente a 6,17% de participação. Em segundo lugar, o NEOMALL RLtda detém R$ 712,13 milhões, correspondendo a 4,43% do total. Já o Itaú Retorno Absoluto ocupa a terceira posição, com R$ 625,06 milhões e 3,89% de participação.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=stock&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Administradores por Patrimônio Líquido',
    details: {
      title: 'Ranking de Administradores por Patrimônio Líquido',
      texts: [
        'O Ranking de Administradores por Patrimônio Líquido (PL) em fundos imobiliários alcançou o montante de R$ 349,99 bilhões, com um total de 53 participantes. Os três principais administradores concentraram 44,97% desse valor. O BTG Pactual Serviços Financeiros DTVM lidera o ranking com um PL de R$ 77,18 bilhões, correspondendo a 22,05% do total. Em segundo lugar, a BRL Trust DTVM detém R$ 42,36 bilhões em PL, representando 12,10%. Já a Intrag DTVM ocupa a terceira posição, administrando R$ 37,87 bilhões e concentrando 10,82% do patrimônio total.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=admin&criteria=networthallmarket&timeRange=consolidated',
  },
  {
    name: 'Ranking de Administradores por Número de Fundos',
    details: {
      title: 'Ranking de Administradores por Número de Fundos',
      texts: [
        'O Ranking de Administradores por Número de Fundos Imobiliários apresentou um total de 1.041 fundos ativos, com 53 administradores ranqueados. A média por participante foi de aproximadamente 19,64 fundos. Os três principais administradores concentram 35,83% do total de fundos. O BTG Pactual Serviços Financeiros DTVM lidera com 183 fundos administrados, representando 17,58% do total. Em segundo lugar, a BRL Trust DTVM gerencia 108 fundos, com participação de 10,37%. Já a Vórtx ocupa a terceira posição, administrando 82 fundos, o que equivale a 7,88% do total.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=admin&criteria=fundnumber&timeRange=consolidated',
  },
  {
    name: 'Ranking de Auditores por Patrimônio Líquido',
    details: {
      title: 'Ranking de Auditores por Patrimônio Líquido',
      texts: [
        'O Ranking de Auditores por Montante de Patrimônio Líquido (PL) dos Fundos Imobiliários Ativos registrou um total de R$ 313,89 bilhões em 2024, distribuídos entre 37 auditores ranqueados. Os três primeiros colocados concentraram 67,49% do total do ranking. A Ernst & Young lidera o ranking, sendo responsável pela auditoria de um montante de R$ 88,95 bilhões, o que representa 28,34% da participação total. Em segundo lugar, aparece a PwC, com um PL de R$ 73,01 bilhões e 23,26% de participação. Já a KPMG ocupa a terceira posição, com R$ 49,85 bilhões em PL, o que equivale a 15,88% de participação no ranking.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=auditor&criteria=networthallmarket&timeRange=consolidated',
  },
  {
    name: 'Ranking de Auditores por Número de Fundos',
    details: {
      title: 'Ranking de Auditores por Número de Fundos',
      texts: [
        'O Ranking de Auditores por Número de Fundos Imobiliários contabilizou 867 fundos, distribuídos entre 37 empresas ranqueadas. As três primeiras colocadas no ranking representam 48,07% de participação no total. A Ernst & Young lidera o ranking, sendo responsável por 162 fundos, o que corresponde a 18,69% da participação total. Em segundo lugar, aparece a Next Auditores, com 139 fundos, correspondendo a 16,03% da participação. Fechando o top 3, a PwC ocupa a terceira posição, com 13,38% de participação e 116 fundos sob sua auditoria.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=auditor&criteria=fundnumber&timeRange=consolidated',
  },
  {
    name: 'Ranking de Gestores por Patrimônio Líquido',
    details: {
      title: 'Ranking de Gestores por Patrimônio Líquido',
      texts: [
        'O Ranking de Gestoras de Fundos Imobiliários registrou um total de R$ 340,5 bilhões em patrimônio líquido em 2024, distribuídos entre 272 empresas ranqueadas. E As três primeiras colocadas somaram 22,44% de participação. A Kinea Investimentos liderou o ranking com R$ 33,44 bilhões em PL (9,82%), seguida pela XP Vista Asset Management, com R$ 22,01 bilhões (6,46%) e, em terceiro lugar, a BTG Pactual Gestora de Recursos, que somou R$ 20,97 bilhões (6,16%).',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=manager&criteria=networthallmarket&timeRange=consolidated',
  },
  {
    name: 'Ranking de Gestores por Número de Fundos',
    details: {
      title: 'Ranking de Gestores por Número de Fundos',
      texts: [
        'O Ranking de Gestores por Número de Fundos Imobiliários atingiu um total de 999 fundos ativos, com 272 gestores ranqueados. A concentração dos três primeiros colocados foi de 11,11%. A liderança ficou com a REAG Trust Administradora, que administra 44 fundos, representando 4,40% do total. Em segundo lugar, a Genial Gestão gerencia 41 fundos, correspondendo a 4,10%. Já a BTG Pactual Gestora de Recursos ocupa a terceira posição, administrando 26 fundos, o equivalente a 2,60% do ranking.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FII&year=2024&ranking=manager&criteria=fundnumber&timeRange=consolidated',
  },
];
