import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import placeHolder from 'src/assets/imgs/yearbook/2025/placeholder.svg';

import { colors } from '../../../constants';
import { YearbookMarketVideo } from '../../../types';

type VideoCardProps = {
  video: YearbookMarketVideo;
  handleVideoClick: (video: YearbookMarketVideo) => void;
  thumbnail?: string;
};

export function Video({ video, handleVideoClick, thumbnail }: VideoCardProps) {
  return (
    <Card
      sx={{
        bgcolor: 'grey.800',
        cursor: 'pointer',
        height: '100%',
      }}
      onClick={() => handleVideoClick(video)}>
      {thumbnail ? (
        <CardMedia
          component="img"
          height="140"
          image={thumbnail}
          alt={video.title}
        />
      ) : (
        <Box
          component={'img'}
          src={placeHolder}
          height="140px"
          width="100%"
          sx={{
            objectFit: 'cover',
          }}
        />
      )}
      <CardContent sx={{ p: 1 }}>
        <Typography
          variant="body2"
          component="div"
          fontWeight="bold"
          sx={{ fontSize: '0.8rem', color: colors.white }}>
          {video.title}
        </Typography>
        <Typography variant="caption" color={colors.white}>
          {video.author}
        </Typography>
      </CardContent>
    </Card>
  );
}
