import { Avatar, Box, Typography } from '@mui/material';

import { letterToReader } from '../../data/presentation';

export function LetterToReader() {
  function handleAuthorLink() {
    window.open(letterToReader.author.link, '_blank');
  }
  return (
    <Box
      component="section"
      data-yearbook-section="Carta ao leitor"
      bgcolor="#131313"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 4,
        mt: 4,
        p: 4,
        color: 'white',
      }}>
      <Box
        sx={({ breakpoints }) => ({
          maxWidth: breakpoints.values.lg,
          display: 'flex',
          flexDirection: 'column',
          mx: 'auto',
          [breakpoints.down(1480)]: {
            ml: '130px',
          },
          [breakpoints.down('lg')]: {
            ml: 0,
          },
        })}>
        <Box>
          <Typography variant="h2" fontWeight={900}>
            {letterToReader.title}
          </Typography>
        </Box>
        <Box>
          {letterToReader.content.map((content, index) => (
            <Typography component="p" mt={2} key={index} fontSize="1.25rem">
              {content.paragraph}
            </Typography>
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="space-between"
          width="100%"
          gap={2}>
          <Box>
            <Typography variant="h5" mt={2} sx={{ fontWeight: 'bold' }}>
              {letterToReader.greeting}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 1,
              cursor: 'pointer',
            }}
            onClick={handleAuthorLink}>
            <Box
              sx={{
                border: '2px solid #ff8c00',
                borderRadius: '50%',
                padding: '4px',
              }}>
              <Avatar src={letterToReader.author.image} />
            </Box>
            <Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                {letterToReader.author.name}
              </Typography>
              <Typography sx={{ fontSize: '12px', color: '#ff8c00' }}>
                {letterToReader.author.role}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
