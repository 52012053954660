import { useEffect, useMemo, useState } from 'react';

import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, Button, IconButton, Tab, Tabs, Typography } from '@mui/material';

import ColumnChart from '../../../components/Charts/ColumnChart';
import LineColumnChart from '../../../components/Charts/LineColumnChart';
import PieChart from '../../../components/Charts/PieChart';
import { descriptions, marketData } from '../../../data/presentation';
import { withLinks } from '../../../link-mapper';
import {
  ChartInfoSection,
  MarketName,
  YearbookMarketChartInfo,
} from '../../../types';

type SectionMarketProps = {
  market: MarketName;
  section: ChartInfoSection;
  title: string;
};

export function SectionMarket({ market, section, title }: SectionMarketProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedChartInfo, setSelectedChartInfo] =
    useState<YearbookMarketChartInfo | null>(null);

  const items = useMemo(
    () => marketData[market].filter((info) => info.section === section),
    [market, section],
  );

  const description = useMemo(
    () => descriptions[market][section],
    [market, section],
  );

  const ChartComponent = useMemo(() => {
    switch (selectedChartInfo?.chart?.chartType) {
      case 'line-column':
        return LineColumnChart;
      case 'column':
        return ColumnChart;
      default:
        return PieChart;
    }
  }, [selectedChartInfo]);

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setSelectedTab(newValue);
    setSelectedChartInfo(items[newValue]);
  }

  function pieFormatter(value: number) {
    const summedValue = selectedChartInfo?.chart?.data.reduce(
      (acc: number, curr: number) => acc + curr,
      0,
    );

    return `${((value / (summedValue > 0 ? summedValue : 1)) * 100).toFixed(2)} %`;
  }

  const handleScrollButtonClick = (direction: 'left' | 'right') => {
    const nextTab =
      direction === 'right'
        ? Math.min(selectedTab + 1, items.length - 1)
        : Math.max(selectedTab - 1, 0);

    setSelectedTab(nextTab);
    setSelectedChartInfo(items[nextTab]);
  };

  useEffect(() => {
    setSelectedTab(0);
    setSelectedChartInfo(items[0]);
  }, [market, items]);

  return (
    <Box
      component="section"
      data-yearbook-section={title}
      sx={({ breakpoints }) => ({
        px: 3,
        py: 6,
        [breakpoints.down(1480)]: {
          ml: '130px',
        },
        [breakpoints.down('lg')]: {
          ml: 0,
        },
      })}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}>
        <Box
          sx={({ breakpoints }) => ({
            gap: 3,
            display: 'flex',
            flexDirection: 'column',
            mx: 'auto',
            maxWidth: breakpoints.values.lg,
          })}>
          <Typography variant="h2">{title}</Typography>
          {description.map((text, idx) => (
            <Typography
              component="p"
              key={idx}
              variant="body1"
              fontWeight={600}
              color="#585858"
              fontSize="1.25rem">
              {text}
            </Typography>
          ))}
        </Box>
        <Box
          sx={({ breakpoints }) => ({
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            mt: 4,
            mx: 'auto',
            width: '100%',
            maxWidth: breakpoints.values.lg,
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
          })}>
          {/* Chart Info */}
          <Box
            bgcolor="primary.main"
            sx={({ breakpoints }) => ({
              p: 2,
              borderRadius: 2,
              width: '100%',
              maxWidth: {
                md: '100%',
                lg: breakpoints.values.sm,
              },
            })}>
            <Box
              bgcolor="white"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 2,
                borderRadius: 2,
                height: '100%',
              }}>
              {selectedChartInfo && (
                <>
                  <Typography
                    variant="body1"
                    textTransform="uppercase"
                    sx={{ fontWeight: 900 }}>
                    {selectedChartInfo.details.title}
                  </Typography>
                  {selectedChartInfo.details.texts.map((text, idx) => (
                    <Typography
                      key={idx}
                      variant="body2"
                      fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: withLinks(text),
                      }}></Typography>
                  ))}
                </>
              )}
            </Box>
          </Box>

          {/* Chart */}
          <Box
            bgcolor="#131313"
            sx={({ breakpoints }) => ({
              p: 2,
              borderRadius: 2,
              maxWidth: {
                md: '100%',
                lg: '680px',
              },
            })}>
            <Box
              sx={{
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
              }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                ScrollButtonComponent={({ direction, ...props }) => (
                  <IconButton
                    {...props}
                    onClick={() => handleScrollButtonClick(direction)}>
                    {direction === 'left' ? (
                      <ArrowLeft color="secondary" fontSize="large" />
                    ) : (
                      <ArrowRight color="secondary" fontSize="large" />
                    )}
                  </IconButton>
                )}
                sx={{
                  maxWidth: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  '& .MuiTabs-flexContainer': {
                    gap: 1,
                    '& > button': {
                      maxWidth: '100%',
                      borderRadius: 6,
                      minHeight: '40px',
                      py: 1,
                    },
                  },
                  '& .MuiTabs-indicator': {
                    display: 'none',
                  },
                  '& .MuiTabs-scrollButtons': {
                    color: 'secondary.main',
                    opacity: 1,
                    borderRadius: 24,
                    bgcolor: 'primary.main',
                    width: '40px',
                    height: '40px',
                    '&:hover': {
                      bgcolor: 'primary.main',
                      color: 'secondary.main',
                    },
                  },
                  '& .MuiTabs-tab': {
                    minWidth: 'auto',
                    padding: 0,
                    margin: 0,
                    borderRadius: 2,
                  },
                }}>
                {items.map((item) => (
                  <Tab
                    key={item.name}
                    label={item.name}
                    sx={{
                      color: 'white',
                      backgroundColor: '#ffffff1a',
                      '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: 'primary.main',
                      },
                    }}
                  />
                ))}
              </Tabs>
              <Typography
                variant="body1"
                sx={{ fontWeight: 600, color: 'white', mt: 4 }}>
                Passe o mouse sobre o gráfico para visualizar a legenda dos
                dados.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}>
                {selectedChartInfo?.chart?.chartType && (
                  <ChartComponent
                    data={selectedChartInfo.chart.data as any}
                    categories={selectedChartInfo.chart.categories}
                    numeric={selectedChartInfo.chart.numeric}
                    stacked={selectedChartInfo.chart.stacked}
                    monetary={selectedChartInfo.chart.monetary}
                    animationEnabled={selectedChartInfo.chart.animated ?? true}
                    formatter={
                      selectedChartInfo.chart.chartType === 'pie'
                        ? pieFormatter
                        : undefined
                    }
                  />
                )}
                {selectedChartInfo?.link && (
                  <Button href={selectedChartInfo.link}>
                    Acessar plataforma Uqbar
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
