/**
 * Gets the contrast color for a given background color
 * @param backgroundColor - The background color in hex format
 * @returns The contrast color (black or white)
 */
export function getContrastColor(backgroundColor: string) {
  const r = parseInt(backgroundColor.slice(1, 3), 16);
  const g = parseInt(backgroundColor.slice(3, 5), 16);
  const b = parseInt(backgroundColor.slice(5, 7), 16);

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? '#000000' : '#FFFFFF';
}
