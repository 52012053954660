import { Box, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { EventSponsor, EventSponsorCategory } from 'src/types/event';
import { getSmallestImageUrl } from 'src/utils/image';

const mapLogoSizeByCategory: Record<EventSponsorCategory, string> = {
  Diamond: '160px',
  Infraestrutura: '130px',
  Platinum: '130px',
  Gold: '110px',
  Silver: '100px',
  Bronze: '90px',
  Expositores: '90px',
  Copatrocinador: '90px',
  Apoiadores: '90px',
};

export default function SponsorCards({
  data,
  category,
  mode,
}: {
  data: EventSponsor[];
  category: EventSponsorCategory;
  mode: 'preview' | 'interaction';
}) {
  const history = useHistory();
  if (!data) return null;

  const handleClick = (sponsor: EventSponsor) => {
    if (mode === 'preview') return;
    if (sponsor.link) {
      window.open(sponsor.link, '_blank', 'noopener noreferrer');
    } else {
      history.push(
        `/instituicao/${sponsor?.institution?.slug}/${sponsor?.institution?.id}`,
      );
    }
  };

  return (
    <Box sx={{ margin: '27px 0' }}>
      <Typography
        sx={{
          fontSize: '14px',
          color: '#828282',
        }}>
        {category}
      </Typography>
      <Grid
        container
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '16px',
        }}>
        {data?.length ? (
          data?.map((sponsor: EventSponsor) => (
            <Grid
              container
              item
              key={`sponsor-${sponsor.id}`}
              onClick={() => handleClick(sponsor)}
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 'fit-content',
                padding: '45px',
                borderRadius: '10px',
                '&:hover': {
                  boxShadow:
                    mode === 'interaction'
                      ? '0px 4px 4px 0px rgba(0, 0, 0, 0.12)'
                      : 'none',
                  border:
                    mode === 'interaction'
                      ? '1px solid var(--Gray-5, #E0E0E0)'
                      : 'none',
                  cursor: mode === 'interaction' ? 'pointer' : 'normal',
                },
              }}>
              <Box
                component="img"
                sx={{
                  width: mapLogoSizeByCategory[category],
                  minHeight: mapLogoSizeByCategory[category],
                  objectFit: 'contain',
                }}
                src={getSmallestImageUrl(sponsor.logo)}
                draggable={false}
                alt={sponsor.institution?.fantasyName}
              />
              <Typography
                sx={(theme) => ({
                  fontSize: '14px',
                  fontWeight: '500',
                  width: '100%',
                  textAlign: 'center',
                  paddingTop: '10px',
                  color: theme.palette.text.secondary,
                })}>
                {sponsor.freeText}
              </Typography>
            </Grid>
          ))
        ) : (
          <Typography
            sx={{
              fontSize: '14px',
              color: '#828282',
              fontWeight: '500',
              width: '100%',
              textAlign: 'center',
            }}>
            Não perca a oportunidade de ser um dos nossos patrocinadores na
            categoria
            <Typography sx={{ fontWeight: '700', fontSize: '18px' }}>
              &nbsp;{category}
            </Typography>
          </Typography>
        )}
      </Grid>
    </Box>
  );
}
