import React from 'react';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import { MenuProps } from '../../../types';

const LateralMenu = (props: MenuProps) => {
  const history = useHistory();
  const [alreadyOpened, setAlreadyOpenend] = React.useState(false);

  const handleTransitionEnd = () => {
    if (props.open) {
      setAlreadyOpenend(true);
    } else {
      setAlreadyOpenend(false);
    }
  };

  const handleClick = () => {
    if (!props.onTransition) {
      props.handleOpen(!props.open);
    }
  };

  const handleGoBack = () => {
    history.push('/plataforma');
  };

  return (
    <S.Wrapper
      open={props.open}
      hide={props.onTransition}
      onTransitionEnd={handleTransitionEnd}>
      <S.BackIconWrapper onClick={handleClick} show={props.open}>
        <ArrowBackIosIcon />
      </S.BackIconWrapper>

      <S.Items open={props.open} hide={props.onTransition}>
        {props.pageIndexes.map(({ title, ref }, index) => (
          <S.Item
            show={props.open}
            notransition={alreadyOpened}
            key={title}
            selected={props.selectedItem === index}
            onClick={() => {
              props.setSelectedItem(index);
              ref.current.scrollIntoView({ behavior: 'smooth' });
            }}>
            {title}
          </S.Item>
        ))}
        <S.BackButton show={props.open}>
          <S.BackButtonText onClick={handleGoBack}>
            Voltar para plataforma
          </S.BackButtonText>
        </S.BackButton>
      </S.Items>
    </S.Wrapper>
  );
};

export default LateralMenu;
