import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { cancelSubscription } from 'src/services/subscription';

import planExpire from '../../assets/imgs/planExpired.png';

type Props = {
  open: boolean;
};

function PlanExpiredDialog({ open }: Props) {
  const { reloadUser } = useAuthContext();
  const history = useHistory();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const cancelSubscriptionMutation = useMutation(() => cancelSubscription(), {
    onSuccess: async () => {
      history.push('/plataforma');
      reloadUser();
    },
  });

  return (
    <Dialog
      open={open}
      maxWidth="md"
      PaperProps={{ sx: { height: '600px', maxHeight: '650px' } }}>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '32px 20px 0',
          overflow: 'hidden',
          minHeight: '465px',
        }}>
        <img src={planExpire} width={185} height={185} />
        <Typography
          sx={{ padding: '0px 64px' }}
          variant="h4"
          textAlign="center">
          Ops! Seu período de teste chegou ao fim.
        </Typography>
        <Typography
          maxWidth={430}
          textAlign="center"
          variant="subtitle1"
          sx={{ fontWeight: 'bold' }}>
          Mas não se preocupe! Você pode continuar usando a plataforma com o
          plano gratuito.
          <Typography sx={{ fontSize: '0.875rem', marginTop: '8px' }}>
            Ao assinar o plano gratuito você deixará de aproveitar os benefícios
            do plano pago, deseja continuar?
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          padding: isMobile ? '0 0 20px 0' : '0 0 40px 0',
        }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="16px"
          sx={{
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? '8px' : '16px',
          }}>
          <Button
            size="large"
            variant="contained"
            onClick={() => history.push('/planos')}
            sx={{
              fontSize: '0.875rem',
              width: isMobile ? '100%' : 'auto',
            }}>
            Assinar plano
          </Button>
          <Button
            size="large"
            variant="outlined"
            onClick={() => cancelSubscriptionMutation.mutate()}
            sx={{
              fontSize: '0.875rem',
              width: isMobile ? '100%' : 'auto',
            }}>
            Continuar gratuitamente
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default PlanExpiredDialog;
