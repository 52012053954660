import { useEffect, useMemo, useState } from 'react';

import { Close } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Grid,
  Modal,
  Snackbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as DOMPurify from 'dompurify';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { api } from 'src/api/api';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getAdvertisementPopUp } from 'src/services/advertisement';
import { loggedUserIsSubscribed } from 'src/services/events';
import { CTAPopUpType } from 'src/types/advertisement-pop-up';
import { useLocalStorage } from 'src/utils/hooks';
import { isInYearbookPage } from 'src/utils/url';

import { LOCAL_STORAGE_NO_SHOW_KEY } from './constants';
import { Styled } from './styles';

const purifyContent = (content: string) =>
  DOMPurify.sanitize(content, { USE_PROFILES: { html: true } });

export default function AdvertisementPopUp() {
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const { auth } = useAuthContext();
  const history = useHistory();
  const location = useLocation();
  const { data, isLoading } = useQuery(
    'advertisement-pop-up',
    getAdvertisementPopUp,
    {
      retry: false,
    },
  );

  const [closed, setClosed] = useState(false);
  const [noShow, setNoShow] = useLocalStorage(LOCAL_STORAGE_NO_SHOW_KEY);
  const [noShowChecked, setNoShowChecked] = useState(false);
  const [checkingSubscription, setCheckingSubscription] = useState(true);
  const [actionMessage, setActionMessage] = useState({
    text: '',
    visible: false,
  });
  const [redirectedTo, setRedirectedTo] = useState('');
  const inYearbookPage = useMemo(
    () => isInYearbookPage(location.pathname),
    [location],
  );
  const isInEventsPage = useMemo(
    () => location.pathname.startsWith('/evento'),
    [location],
  );

  const saveNoShowCheck = () => {
    setNoShow(Date.now());
  };

  useEffect(() => {
    const checkUserSubscription = async () => {
      setCheckingSubscription(true);
      try {
        if (data && data.event && auth.isLoggedIn) {
          const alreadySubscribed = await loggedUserIsSubscribed(data.event.id);
          if (alreadySubscribed) {
            setClosed(true);
          } else {
            setClosed(false);
          }
        }
      } catch {
        setClosed(true);
      } finally {
        setCheckingSubscription(false);
      }
    };
    checkUserSubscription();
  }, [data, auth.isLoggedIn]);

  const handleClose = () => {
    if (noShowChecked) {
      saveNoShowCheck();
    }
    setClosed(true);
  };

  const handleClick = async (buttonType: CTAPopUpType, url: string) => {
    if (buttonType === CTAPopUpType.BUTTON_API) {
      if (!auth.isLoggedIn) {
        setActionMessage({
          text: 'Antes de seguir, será necessário fazer login.',
          visible: true,
        });
        setRedirectedTo('/login');
        history.replace('/login');
      }
      try {
        await api.patch(url);
      } catch {
        setActionMessage({
          text: 'Não foi possível agora, tente novamente mais tarde.',
          visible: true,
        });
      }
    } else {
      setRedirectedTo(url);
      history.replace(url);
    }
    setClosed(true);
  };

  if (
    isLoading ||
    !data ||
    closed ||
    location.pathname === redirectedTo ||
    checkingSubscription ||
    inYearbookPage ||
    isInEventsPage
  ) {
    return null;
  }

  const publishedAt = new Date(data.publishedAt);
  const noShowLastCheckAt = new Date(Number(noShow) || '');

  const isNewAdvPopUp = noShow ? publishedAt > noShowLastCheckAt : true;

  const notInterestedButtonCTA = data?.CTAs.find(
    ({ type }) => type === CTAPopUpType.NOT_INTERESTED_CHECK,
  );
  const otherCTAs = data?.CTAs.filter(
    ({ type }) => type !== CTAPopUpType.NOT_INTERESTED_CHECK,
  );

  const getDeviceWidthAndHeight = () => {
    if (isLandscape) {
      return { width: '75vw', height: '80vh' };
    }

    return { width: '80vw', height: '80vh' };
  };

  return (
    <Modal
      open={isNewAdvPopUp && !closed}
      onClose={handleClose}
      aria-labelledby="anuncio-pop-up-title"
      aria-describedby="anuncio-pop-up-description"
      slots={{ backdrop: Backdrop }}>
      <Styled.Wrapper>
        <Styled.Content style={getDeviceWidthAndHeight()}>
          <Box
            sx={{
              position: 'absolute',
              top: 15,
              right: 15,
              cursor: 'pointer',
            }}>
            <Close onClick={() => handleClose()} />
          </Box>
          <Styled.SideWrapper imgURL={data.image.url} />
          <Styled.SideWrapper>
            <Box
              sx={{
                p: { xs: '15px', md: '30px', lg: '60px' },
                overflowY: 'auto',
              }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    id="anuncio-pop-up-title"
                    variant="h5"
                    component="h1">
                    {data.title}
                  </Typography>
                  <Typography id="anuncio-pop-up-description" sx={{ mt: 2 }}>
                    {data.description}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    mb={3}
                    dangerouslySetInnerHTML={{
                      __html: purifyContent(data.content),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent="space-around">
                    {otherCTAs.map((cta) => (
                      <Grid item key={cta.label}>
                        <Styled.ButtonCTALink
                          {...cta}
                          onClick={() => handleClick(cta.type, cta.actionURL)}
                          fullWidth
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      {notInterestedButtonCTA && (
                        <Styled.ButtonCTANotInterested
                          {...notInterestedButtonCTA}
                          onChange={(checked: boolean) =>
                            setNoShowChecked(checked)
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Styled.SideWrapper>
        </Styled.Content>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={actionMessage.visible}
          autoHideDuration={5000}
          onClose={() => setActionMessage({ text: '', visible: false })}
          message={actionMessage.text}
        />
      </Styled.Wrapper>
    </Modal>
  );
}
