import { Box } from '@mui/material';

import { AdvertisementBanner } from '../../types';

type AdversimentBannerProps = {
  advertisement?: AdvertisementBanner;
};

export function AdversimentBanner({ advertisement }: AdversimentBannerProps) {
  const getAdvertisementImage = (imageName: string) => {
    try {
      const image = require(
        `src/assets/imgs/yearbook/2025/banners/${imageName}.png`,
      );
      if (image) {
        return image;
      }
    } catch (error) {
      if (advertisement) {
        advertisement.url = 'https://uqbar.com.br';
      }
      return require('src/assets/imgs/yearbook/2025/banners/be-a-sponsor.png');
    }
  };

  if (!advertisement) return null;

  return (
    <Box
      component="a"
      href={advertisement.url}
      target="_blank"
      sx={({ breakpoints }) => ({
        display: 'flex',
        position: 'relative',
        backgroundImage: `url(${getAdvertisementImage(advertisement.banner).default})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
        width: '100%',
        height: '380px',
        [breakpoints.down('lg')]: {
          backgroundSize: '100%',
          height: '240px',
        },
        [breakpoints.down('md')]: {
          height: '200px',
        },
        '&::after': {
          position: 'absolute',
          content: '"Publicidade"',
          color: '#767676',
          right: 0,
          top: 0,
          backgroundColor: 'white',
          padding: '0.5rem 1rem',
          borderRadius: '0 0 0 0.5rem',
          [breakpoints.down('sm')]: {
            padding: '0.25rem 0.5rem',
          },
        },
      })}
    />
  );
}
