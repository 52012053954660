import { useMemo, useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  lighten,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { withLinks } from 'src/pages/Yearbooks/2024/link-mapper';
import { findProfessionalById } from 'src/services/professional';

import { AdversimentBanner } from '../../../components/Adversiment';
import { YearbookMarketArticle } from '../../../types';
import { getArticleAuthorId } from '../../../utils';

type ArticleSummaryProps = {
  article: YearbookMarketArticle;
};

export function Article({ article }: ArticleSummaryProps) {
  const [expanded, setExpanded] = useState(false);

  const authorId = useMemo(() => {
    return getArticleAuthorId(article.authorLink);
  }, [article.authorLink]);

  const { data: author } = useQuery({
    queryKey: ['article-author', authorId],
    queryFn: () => findProfessionalById(authorId),
    enabled: !!authorId,
    refetchOnWindowFocus: false,
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Typography
          sx={({ breakpoints }) => ({
            maxWidth: breakpoints.values.lg,
            fontSize: '40px',
            mx: 'auto',
            p: 2,
            fontWeight: 'bold',
            [breakpoints.down(1480)]: {
              ml: '130px',
              width: 'calc(100% - 130px)',
            },
            [breakpoints.down('lg')]: {
              ml: 0,
              width: '100%',
            },
          })}>
          {article.title}
          <Typography
            component={'a'}
            href={article.authorLink}
            sx={{
              bgcolor: lighten('#767676', 0.8),
              cursor: 'pointer',
              ml: 1,
              textDecoration: 'none',
              color: '#767676',
              fontSize: '16px',
              px: 1,
              py: 0.25,
              borderRadius: 24,
            }}
            fontWeight={'bold'}>
            {author?.fullName}
          </Typography>
        </Typography>

        <Accordion
          elevation={0}
          sx={{ border: 'none', backgroundColor: 'transparent' }}
          expanded={expanded}>
          <AccordionSummary onClick={() => setExpanded(!expanded)}>
            <Box
              sx={({ breakpoints }) => ({
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                [breakpoints.down(1480)]: {
                  ml: '130px',
                  width: 'calc(100% - 130px)',
                },
                [breakpoints.down('lg')]: {
                  ml: 0,
                  width: '100%',
                },
              })}>
              <Box
                sx={({ breakpoints }) => ({
                  maxWidth: breakpoints.values.lg,
                  mx: 'auto',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 2,
                })}>
                <Typography
                  onClick={() => window.open(article.partner.link, '_blank')}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: 'primary.main',
                    backgroundColor: lighten('#ff8c00', 0.8),
                    fontWeight: 'bold',
                    fontSize: '16px',
                    p: 2,
                    borderRadius: 24,
                  }}>
                  {article.partner.name}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    fontSize={12}
                    mr={1}
                    textTransform={'uppercase'}
                    fontWeight={'bold'}>
                    {expanded ? 'Ver menos' : 'Ver mais'}
                  </Typography>
                  {expanded ? <ExpandLess /> : <ExpandMore />}
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            {article?.ads && (
              <AdversimentBanner
                advertisement={{
                  url: article.ads.link,
                  banner: article.ads.image,
                  article: article?.title,
                }}
              />
            )}
            <Box
              sx={({ breakpoints }) => ({
                maxWidth: breakpoints.values.lg,
                margin: '0 auto',
                mt: 2,
                p: 2,
                [breakpoints.down(1480)]: {
                  ml: '130px',
                  width: 'calc(100% - 130px)',
                },
                [breakpoints.down('lg')]: {
                  ml: 0,
                  width: '100%',
                },
              })}>
              {article.texts.map((text, idx) => (
                <Typography
                  key={idx}
                  variant="body2"
                  fontSize={20}
                  fontWeight={600}
                  sx={{
                    '& img': {
                      display: 'flex',
                      mx: 'auto',
                      width: '600px',
                      maxWidth: '100%',
                      height: 'auto',
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: withLinks(text),
                  }}
                />
              ))}
            </Box>
            <Box
              sx={({ breakpoints }) => ({
                mt: 1,
                maxWidth: breakpoints.values.lg,
                mx: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                p: 2,
                [breakpoints.down(1480)]: {
                  ml: '130px',
                  width: 'calc(100% - 130px)',
                },
                [breakpoints.down('lg')]: {
                  ml: 0,
                  width: '100%',
                },
              })}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.open(article.authorLink, '_blank');
                }}>
                <Box
                  sx={{
                    border: '4px solid #ff8c00',
                    borderRadius: '50%',
                    padding: '4px',
                    mr: 1,
                  }}>
                  <Avatar
                    src={author?.photo?.url}
                    sx={{ width: 80, height: 80 }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    ml: 1,
                    maxWidth: '50%',
                  }}>
                  <Typography fontSize={20} fontWeight={'bold'}>
                    {author?.fullName}
                  </Typography>
                  <Typography fontSize={12} fontWeight={'bold'}>
                    {author?.profileText}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
