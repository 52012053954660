import { css, styled } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { abbreviatedNumber } from 'src/pages/Yearbooks/presentation/components/Charts/utils';

interface LineChartProps {
  data: {
    name: string;
    type: 'column' | 'line';
    data: number[];
  }[];
  animationEnabled?: boolean;
  categories: string[];
  width?: number;
}

export default function LineColumnChart(props: LineChartProps) {
  const StyledChart = styled(ReactApexChart)`
    ${({ theme }) => css`
      width: ${theme.breakpoints.values.sm}px;

      @media (max-width: ${theme.breakpoints.values.md}px) {
        width: ${theme.breakpoints.values.sm}px;
      }

      @media (max-width: ${theme.breakpoints.values.sm}px) {
        width: 100%;
      }
    `}
  `;

  return (
    <StyledChart
      series={props.data}
      type="line"
      width={props.width ?? '100%'}
      options={{
        chart: {
          type: 'line',
          stacked: false,
          toolbar: {
            show: false,
          },
          animations: {
            enabled: props.animationEnabled ?? true,
          },
        },

        dataLabels: {
          formatter: (val: any) => {
            if (isNaN(Number(val))) {
              return val;
            }

            return Number(val).toFixed(2);
          },
        },
        stroke: {
          width: [0, 6, 5],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {},
        },
        tooltip: {
          y: {
            formatter: function (value: any, opts: any) {
              if (isNaN(Number(value))) {
                return value;
              }

              if (opts.seriesIndex === 1) {
                return value.toLocaleString('pt-BR', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                });
              }

              const formatedValue = abbreviatedNumber(value);

              return `R$ ${formatedValue}`;
            },
          },
        },
        fill: {
          opacity: [0.85, 1, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: props.categories,
        markers: {
          size: 0,
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          type: 'category',
          labels: {
            show: props.categories.length <= 6 ? true : false,
            style: {
              fontWeight: 'bold',
              colors: 'white',
            },
          },
        },
        yaxis: [
          {
            labels: {
              show: false,
            },
            min: 0,
          },
          {
            opposite: true,
            labels: {
              show: false,
            },
          },
        ],
        responsive: [
          {
            breakpoint: 1000,
            options: {
              chart: {
                animations: {
                  enabled: false,
                },
              },
            },
          },
        ],
        colors: [
          '#A61F69',
          '#FD841F',
          '#600446',
          '#F2CD5C',
          '#35D0BA',
          '#CD104D',
        ],
      }}
    />
  );
}
