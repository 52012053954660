import { useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import {
  Button,
  CircularProgress,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CustomTooltip } from 'src/components/CustomTooltip';
import SubscribeToEventDialog from 'src/components/SubscribeToEventDialog';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { subscribeToEvent } from 'src/services/events';
import { setBannerMessage } from 'src/store/display';
import themev5 from 'src/themev5';
import { Event } from 'src/types/event';
import { useQueryParams } from 'src/utils/hooks';

import { EventSubscribeButton, EventSubscribeButtonProps } from './styles';
import SubscribeToEventParticipantNameDialog from '../../../../components/SubscribeToEventParticipantNameDialog';
import { useEventContext } from '../../EventContextProvider';

type SubscribeButtonProps = EventSubscribeButtonProps & {
  refetch?: () => void;
  variant?: 'contained' | 'text';
};

export default function SubscribeButton({
  event,
  refetch,
  variant = 'contained',
  typePosition = 'header',
}: SubscribeButtonProps) {
  const [, setQuery] = useQueryParams<{
    subscribed: boolean | 'third_party' | '';
  }>();
  const { auth } = useAuthContext();
  const { acceptTermsAndConditions, scrollToRefElement } = useEventContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const getButtonLabel = () => {
    if (event?.subscribed) {
      if (!event?.isLive) {
        return (
          <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            Inscrito
            <CheckCircleIcon sx={{ fontSize: '16px' }} />
          </Typography>
        );
      } else {
        return 'Acessar agora';
      }
    } else {
      return 'Inscrever-se';
    }
  };

  const subscribeToEventMutation = useMutation(subscribeToEvent, {
    onSuccess: () => handleSuccess(),
    onError: () => {
      if (variant === 'text') {
        dispatch(
          setBannerMessage({
            message: 'Não foi possível realizar a inscrição.',
            type: 'error',
            autoclose: true,
          }),
        );
      }
    },
  });

  const handleSuccess = () => {
    if (variant === 'text') {
      dispatch(
        setBannerMessage({
          message: 'Inscrição realizada com sucesso.',
          type: 'success',
          autoclose: true,
        }),
      );
    }
    if (refetch) {
      refetch();
    }

    setQuery({ subscribed: true });
  };

  const handleClick = () => {
    if (!auth.isLoggedIn) {
      history.push(`/login?redirectUrl=/evento/${event?.slug}/${event?.id}`);
      return;
    }
    if (
      typePosition === 'header' &&
      event?.termsOfUseLink &&
      !acceptTermsAndConditions
    ) {
      history.push(`/evento/${event?.slug}/${event?.id}/visao-geral`);
      scrollToRefElement();
      return;
    }

    if (event?.termsOfUseLink && !acceptTermsAndConditions) {
      toast.warn(
        'É necessário aceitar os termos de uso para realizar a inscrição.',
        {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          style: {
            backgroundColor: themev5.palette.primary.main,
            color: themev5.palette.primary.contrastText,
          },
        },
      );
      return;
    }

    if (!auth.isLoggedIn || subscribeToEventMutation.isLoading) return;

    if (event?.isUqbar && event?.subscribeUrl) {
      setQuery({ subscribed: true });
      window.open(event?.subscribeUrl, '_blank', 'noopener noreferrer');
      return;
    }

    if (!event?.isUqbar) {
      setQuery({ subscribed: 'third_party' });
      window.open(event?.subscribeUrl, '_blank', 'noopener noreferrer');
      return;
    }

    if (!event.subscribed) {
      if (event.hasPermission && auth.user?.type === 'private_person') {
        subscribeToEventMutation.mutate({
          id: event.id,
          acceptTermsAndConditions,
        });
        return;
      }
      setOpenSubscribeDialog(true);
      return;
    }

    if (event.subscribed && event?.isLive && event?.hasPermission) {
      window.open(`/evento/${event.slug}/${event.id}/programacao`, '_blank');
    }
  };

  return (
    <CustomTooltip
      disableFocusListener
      disableTouchListener={auth.isLoggedIn}
      disableHoverListener={auth.isLoggedIn}
      arrow
      title={
        !auth.isLoggedIn ? 'Faça login para acessar esta funcionalidade' : ''
      }
      placement="top"
      followCursor={true}>
      <span style={{ marginLeft: 'auto' }}>
        {variant === 'contained' ? (
          <EventSubscribeButton
            typePosition={typePosition}
            event={event}
            auth={auth}
            disabled={event?.subscribed}
            size={isMobile ? 'medium' : 'large'}
            disableRipple={!auth.isLoggedIn || event?.subscribed}
            onClick={handleClick}>
            {subscribeToEventMutation.isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              getButtonLabel()
            )}
          </EventSubscribeButton>
        ) : (
          <Button
            startIcon={
              subscribeToEventMutation.isLoading ? undefined : (
                <PlayCircleFilledRoundedIcon color="primary" fontSize="small" />
              )
            }
            color="primary"
            onClick={handleClick}
            disabled={
              !auth.isLoggedIn ||
              (event?.termsOfUseLink && !acceptTermsAndConditions) ||
              event?.subscribed
            }
            sx={{
              padding: 0,
              minWidth: 'unset',
              textTransform: 'none',
              textDecoration: 'underline !important',
              textWrap: 'nowrap',
              letterSpacing: 'unset',
              fontSize: { xs: 12, sm: 14 },
              fontWeight: 700,
              display: event?.subscribed ? 'none' : 'inline-flex',
            }}>
            {subscribeToEventMutation.isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              'Inscrever-se'
            )}
          </Button>
        )}
        {event && event.hasPermission && auth?.user?.type === 'legal_person' ? (
          <SubscribeToEventParticipantNameDialog
            acceptTermsAndConditions={acceptTermsAndConditions}
            isOpen={openSubscribeDialog}
            handleClose={() => setOpenSubscribeDialog(false)}
            handleSuccess={handleSuccess}
            event={event}
          />
        ) : (
          <SubscribeToEventDialog
            acceptTermsAndConditions={acceptTermsAndConditions}
            isOpen={openSubscribeDialog}
            handleClose={() => setOpenSubscribeDialog(false)}
            handleSuccess={handleSuccess}
            event={event as Event}
          />
        )}
      </span>
    </CustomTooltip>
  );
}
