import { useEffect, useMemo, useState } from 'react';

import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, Button, IconButton, Tab, Tabs, Typography } from '@mui/material';

import RankingsWidget from '../../components/RankingsWidget';
import { descriptions, marketRankings } from '../../data/presentation';
import { withLinks } from '../../link-mapper';
import { MarketName, YearbookMarketRanking } from '../../types';
import { extractRankingParams } from '../../utils';

type RankingsProps = {
  market: MarketName;
};

export function Rankings({ market }: RankingsProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedRanking, setSelectedRanking] =
    useState<YearbookMarketRanking | null>(null);
  const rankings = useMemo(() => marketRankings[market], [market]);

  const description = useMemo(() => descriptions[market].rankings, [market]);

  function openRanking() {
    if (!selectedRanking || !selectedRanking.endpointUrl) return;
    const params = extractRankingParams(selectedRanking.endpointUrl);
    if (!params) return;

    window.open(
      `/rankings/dados?market=${params.market}&year=${params.year}&ranking=${params.ranking}&criteria=${params.criteria}&timeRange=${params.timeRange}`,
      '_blank',
    );
  }

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setSelectedTab(newValue);
    setSelectedRanking(rankings[newValue]);
  }

  const handleScrollButtonClick = (direction: 'left' | 'right') => {
    const nextTab =
      direction === 'right'
        ? Math.min(selectedTab + 1, rankings.length - 1)
        : Math.max(selectedTab - 1, 0);

    setSelectedTab(nextTab);
    setSelectedRanking(rankings[nextTab]);
  };

  useEffect(() => {
    setSelectedTab(0);
    setSelectedRanking(rankings[0]);
  }, [rankings]);

  return (
    <Box component="section" data-yearbook-section="Rankings">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mx: 'auto',
          width: '100%',
        }}>
        {/* Description */}
        <Box
          sx={({ breakpoints }) => ({
            display: 'flex',
            maxWidth: breakpoints.values.lg,
            flexDirection: 'column',
            mx: 'auto',
            px: 4,
            py: 6,
            [breakpoints.down(1480)]: {
              ml: '130px',
            },
            [breakpoints.down('lg')]: {
              ml: 0,
            },
          })}>
          <Typography variant="h2">Rankings</Typography>
          {description.map((text, idx) => (
            <Typography
              component="p"
              fontSize="1.25rem"
              fontWeight={600}
              color="#585858"
              key={`${market}-${idx}`}
              dangerouslySetInnerHTML={{
                __html: withLinks(text),
              }}
            />
          ))}
        </Box>
        {/* Rankings */}
        <Box
          sx={({ breakpoints }) => ({
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            mt: 4,
            px: 4,
            py: 6,
            mx: 'auto',
            width: '100%',
            [breakpoints.down(1480)]: {
              ml: '130px',
              width: 'calc(100% - 130px)',
            },
            [breakpoints.down('lg')]: {
              mx: 'auto',
              flexDirection: 'column',
              width: '100%',
            },
            [breakpoints.up('lg')]: {
              maxWidth: breakpoints.values.lg,
            },
          })}>
          {/* Ranking details */}
          <Box
            sx={({ breakpoints }) => ({
              p: 2,
              borderRadius: 2,
            })}>
            <Typography variant="h6" textTransform="uppercase" fontWeight={900}>
              {selectedRanking?.details.title}
            </Typography>
            {selectedRanking?.details.texts.map((text, idx) => (
              <Typography
                key={idx}
                variant="body1"
                fontWeight={600}
                color="#585858"
                dangerouslySetInnerHTML={{
                  __html: withLinks(text),
                }}
              />
            ))}
          </Box>
          {/* Ranking tabs */}
          <Box
            sx={({ breakpoints }) => ({
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              p: 2,
              borderRadius: 2,
              [breakpoints.up('lg')]: {
                maxWidth: '60%',
              },
            })}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              ScrollButtonComponent={({ direction, ...props }) => (
                <IconButton
                  {...props}
                  onClick={() => handleScrollButtonClick(direction)}>
                  {direction === 'left' ? (
                    <ArrowLeft color="primary" fontSize="large" />
                  ) : (
                    <ArrowRight color="primary" fontSize="large" />
                  )}
                </IconButton>
              )}
              sx={{
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                '& .MuiTabs-flexContainer': {
                  gap: 1,
                  '& > button': {
                    backgroundColor: '#cfcfcf',
                    maxWidth: '100%',
                    borderRadius: 6,
                    minHeight: '40px',
                    py: 1,
                    color: '#666666',
                    '&.Mui-selected': {
                      color: 'white',
                      backgroundColor: 'primary.main',
                    },
                  },
                },
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
                '& .MuiTabs-tab': {
                  minWidth: 'auto',
                  padding: 0,
                  margin: 0,
                  borderRadius: 2,
                },
              }}>
              {rankings.map((ranking) => (
                <Tab
                  key={ranking.name}
                  label={ranking.name}
                  sx={{
                    color: 'white',
                    backgroundColor: '#ffffff1a',
                    '&.Mui-selected': {
                      color: 'white',
                      backgroundColor: 'primary.main',
                    },
                  }}
                />
              ))}
            </Tabs>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
                bgcolor: 'primary.main',
                width: '100%',
                borderRadius: 2,
                p: 2,
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                  bgcolor: 'white',
                  width: '100%',
                  borderRadius: 2,
                  p: 2,
                }}>
                <RankingsWidget
                  market={selectedRanking?.queryData?.market}
                  urlSource={selectedRanking?.endpointUrl}
                  data={selectedRanking?.queryData}
                  criteria={selectedRanking?.criteria}
                />
              </Box>
              {selectedRanking?.endpointUrl && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>
                  <Button
                    onClick={openRanking}
                    sx={{
                      backgroundColor: 'white',
                      color: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'white',
                      },
                    }}>
                    Acessar plataforma Uqbar
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
