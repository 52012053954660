import qs from 'qs';

import { api, embedApi, publicApi } from '../../api/api';

export const findSummaryRanking = async (market) => {
  const query = qs.stringify(
    {
      populate: {
        button1: true,
        button2: true,
        [market]: {
          populate: '*',
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/rank?${query}`);
  return data;
};

export const findRankingFormSheet = async () => {
  const query = qs.stringify(
    {
      populate: ['rankFormSheet'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/rank?${query}`);
  return data;
};

export const findFilters = async (institutionId = undefined, isLoggedIn) => {
  const query = qs.stringify(
    {
      institutionId,
    },
    {
      encodeValuesOnly: true,
    },
  );
  if (isLoggedIn) {
    const { data } = await api.get(`/rankings-filters?${query}`);
    return data;
  } else {
    const { data } = await publicApi.get(`/rankings-filters?${query}`);
    return data;
  }
};

export const findRanking = async ({
  filters,
  isLoggedIn,
  widgetKey,
  page = null,
  pageSize = null,
}) => {
  const query = qs.stringify(
    {
      ...filters,
      page,
      pageSize,
    },
    {
      encodeValuesOnly: true,
    },
  );
  if (isLoggedIn) {
    const { data } = await api.get(`/rankings?${query}`);
    return data;
  } else if (widgetKey) {
    const { data } = await embedApi.get(`/rankings?${query}`);
    return data;
  } else {
    const { data } = await publicApi.get(`/rankings?${query}`);
    return data;
  }
};

export const findStatisticsRanking = async (filters) => {
  const query = qs.stringify(
    {
      ...filters,
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/statistics-ranking?${query}`);

  return data;
};

export const findRankingStatisticsResume = async (filters, isLoggedIn) => {
  const query = qs.stringify(
    {
      ...filters,
    },
    {
      encodeValuesOnly: true,
    },
  );

  if (isLoggedIn) {
    const { data } = await api.get(`/ranking-statistics-resume?${query}`);
    return data;
  } else {
    const { data } = await publicApi.get(`/ranking-statistics-resume?${query}`);
    return data;
  }
};

export const findRankItemHistory = async ({
  institutionId,
  market,
  type,
  criteria,
  timeRange,
}) => {
  const query = qs.stringify(
    {
      populate: {
        rank: {
          fields: ['baseYear', 'baseMonth'],
        },
        criteria: {
          fields: ['label'],
        },
      },
      sort: ['rank.baseYear:desc'],
      filters: {
        institution: {
          id: institutionId,
        },
        criteria: {
          slug: criteria,
        },
        rank: {
          market: {
            name: market,
          },
          type,
          timeRange,
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/rank-items?${query}`);

  return data;
};

export const requestRanking = async (formData) => {
  const { data } = await api.post('/solicitacoes-rankings', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });

  return data;
};

export const findInstitutionRankingCount = async (institutionId) => {
  const query = qs.stringify(
    {
      filters: {
        institution: {
          id: institutionId,
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/rank-item-count?${query}`);

  return data;
};

export const findRankingToHome = async () => {
  return publicApi.get('/rankings-home');
};
