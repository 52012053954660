import { useHistory } from 'react-router-dom';

import * as S from './styles';
import { MenuProps } from '../../../types';

const LateralMenu = (props: MenuProps) => {
  const history = useHistory();

  const handleClick = () => {
    if (!props.onTransition) {
      props.handleOpen(!props.open);
    }
  };

  const handleGoBack = () => {
    history.push('/plataforma');
  };

  return (
    <S.Wrapper open={props.open}>
      <S.Items open={props.open}>
        {props.pageIndexes.map(({ title, ref }, index) => (
          <S.Item
            show={props.open}
            key={title}
            selected={props.selectedItem === index}
            onClick={() => {
              props.setSelectedItem(index);
              ref.current.scrollIntoView({ behavior: 'smooth' });
            }}>
            {title}
          </S.Item>
        ))}
        <S.Item show={props.open} selected={false} onClick={handleGoBack}>
          Voltar para plataforma
        </S.Item>
      </S.Items>
      <S.CloseButton onClick={handleClick} open={props.open}>
        <S.ArrowIcon />
      </S.CloseButton>
    </S.Wrapper>
  );
};

export default LateralMenu;
