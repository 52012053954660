import { useEffect } from 'react';

import { Box, Skeleton } from '@mui/material';
import { useQuery } from 'react-query';
import { useEmbedContext } from 'src/context/EmbedContextProvider';
import { TableRanking } from 'src/pages/Rankings/TableRanking';
import { findRanking } from 'src/services/ranking';

import { YearbookMarketRankingQueryData } from '../../types';
import { extractRankingParams } from '../../utils';

interface WidgetProps {
  urlSource?: string;
  data?: YearbookMarketRankingQueryData;
  criteria?: string;
  market?: 'CR' | 'CRA' | 'CRI' | 'FII' | 'FIDC' | 'FIAGRO';
}

export default function RankingsWidget(props: WidgetProps) {
  const hasConsolidated = true;

  const params = props.urlSource
    ? extractRankingParams(props.urlSource)
    : ({
        market: props.market ?? 'FIAGRO',
      } as Record<string, any>);

  const { widgetKey } = useEmbedContext();

  const {
    refetch,
    data: queryData,
    isLoading,
  } = useQuery(['rankings', params], () =>
    props.urlSource
      ? findRanking({
          filters: {
            market: params?.market?.toLowerCase(),
            year: String(params?.year),
            type: params?.ranking,
            criteria: params?.criteria,
            timeRange: params?.timeRange,
          },
          isLoggedIn: true,
          widgetKey,
        })
      : new Promise((resolve) => resolve(props.data)),
  );

  useEffect(() => {
    if (props.data) refetch();
  }, [props.data]);

  if (!params) return null;

  if (isLoading)
    return (
      <Skeleton
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
        variant="rounded"
        height={720}
        animation="wave"
      />
    );

  const tableProps = {
    hasConsolidated,
    openLinkNewTab: true,
    searchName: '',
    criteriaLabel: undefined,
    rankingLabel: undefined,
    numberCriterias: ['numeric'],
    loading: undefined,
    percentageCriterias: undefined,
    handlePrint: undefined,
  };
  return (
    <Box
      sx={{
        maxWidth: '100%',
      }}>
      <TableRanking
        rank={queryData?.ranks}
        valueLabel={queryData?.valueLabel}
        state={{ ...params, criteria: props.criteria ?? params.criteria }}
        month={queryData?.month}
        yearbookMode
        exportDisabled
        {...tableProps}
      />
    </Box>
  );
}
