import {
  fidcPrimaryMarketCharts,
  fidcSecondaryMarketCharts,
  fidcStockMarketCharts,
} from './charts/FIDC';
import {
  YearbookDescriptions,
  YearbookMarketArticle,
  YearbookMarketChartInfo,
  YearbookMarketRanking,
} from '../types';

export const fidcOpeningTexts = [
  'O mercado de Fundos de Investimento em Direitos Creditórios (FIDC) registrou um desempenho notável em 2024, refletindo um vigor crescente na captação de recursos e uma evolução significativa em sua dinâmica operacional. Ao longo do ano, tanto as emissões no mercado primário quanto as negociações no mercado secundário avançaram de maneira expressiva, refletindo um cenário em que investidores buscam rentabilidade e diversificação em meio a juros persistentemente elevados. Essa combinação de fatores criou um ambiente favorável ao fortalecimento contínuo dos FIDCs como alternativa robusta ao crédito bancário tradicional.',
  'No mercado primário, os FIDCs atingiram cifras históricas, com mais de R$ 212 bilhões emitidos, indicando um aumento superior a 30% comparado ao ano anterior. Destacaram-se os fundos de condomínio fechado, levando, consequentemente, a um crescimento significativo dos fundos abertos. Além disso, as operações envolvendo recebíveis comerciais, crédito pessoal e crédito para pessoas jurídicas mantiveram sua dominância, demonstrando a preferência consolidada dos investidores por ativos mais tradicionais.',
  'Já no mercado secundário, os indicadores de liquidez registraram números notáveis. O volume financeiro movimentado e o número de transações atingiram patamares recordes, ilustrando claramente um amadurecimento e uma maior liquidez nesse mercado. Destacaram-se as cotas de subclasse sênior, enquanto as cotas subordinadas seguiram proporcionando diversificação e oportunidades de retornos diferenciados.',
  'Outro fenômeno relevante observado em 2024 foi a expressiva expansão das negociações em ambiente de balcão em contraste às transações em bolsa. Enquanto a bolsa registrou um número considerável de operações, principalmente devido à popularidade dos FIAGROs, o balcão concentrou valores significativamente maiores em menos transações, indicando perfis distintos entre os investidores nos dois ambientes.',
  'Paralelamente, o mercado de FIDCs também se destacou pelo crescimento robusto do patrimônio líquido dos fundos, que atingiu a marca histórica de R$ 635,74 bilhões em 2024. Esse desempenho representa um aumento de mais de 42% em relação ao ano anterior, ressaltando a relevância dos FIDCs na estrutura do mercado de capitais brasileiro e a sua posição consolidada como importante veículo de securitização.',
  'Em relação à prestação de serviços, percebe-se um alto grau de concentração em diversos segmentos, especialmente entre agências de classificação de risco e assessores jurídicos. Entretanto, o mercado apresentou uma interessante pulverização no campo dos gestores, com múltiplos players disputando posições e oferecendo diversidade de estratégias e abordagens para investidores. Esse contraste ilustra a complexidade e a maturidade do mercado de FIDCs, refletindo diferentes graus de especialização e competição entre seus agentes.',
  'Ao longo deste anuário, os leitores terão a oportunidade de aprofundar essas análises, explorando dados detalhados, rankings estratégicos e insights que ajudarão a compreender melhor as forças que impulsionam o mercado de FIDCs e as perspectivas futuras dessa indústria essencial para o mercado de capitais brasileiro.',
];

export const fidcArticles: YearbookMarketArticle[] = [
  {
    title: 'Os FIDCs e o seu papel na desconcentração do mercado de crédito',
    ads: {
      image: 'ouro',
      link: 'https://www.ouropretoinvestimentos.com.br/?utm_source=Uqbar&utm_medium=Banner&utm_campaign=UqbarAnuarios',
    },
    authorLink:
      'https://uqbar.com.br/profissional/joao-baptista-peixoto-neto-6709/1370',
    partner: {
      name: 'Ouro Preto Investimentos',
      link: 'https://uqbar.com.br/instituicao/ouro-preto-gestao-de-recursos-1656120235744/7916/perfil',
    },
    texts: [
      'Por décadas, os grandes bancos dominaram a originação de crédito no mercado financeiro brasileiro. No entanto, esse cenário está mudando rapidamente. O avanço das fintechs e a sofisticação do mercado de capitais estão promovendo uma transformação estrutural no setor, tornando o crédito cada vez mais descentralizado e acessível.',
      'Os Fundos de Investimento em Direitos Creditórios (FIDCs) desempenham um papel fundamental nesse movimento. À medida que mais empresas buscam alternativas ao sistema bancário tradicional para financiar suas operações, os FIDCs surgem como uma solução eficiente para originar, estruturar e distribuir crédito no mercado.',
      'A perda de protagonismo dos bancos na concessão de crédito já é uma realidade. O capital está migrando para veículos mais especializados e flexíveis, permitindo que fintechs e outras instituições inovadoras criem produtos sob medida para diferentes perfis de tomadores. Essa fragmentação do setor não só amplia o acesso ao crédito, mas também fomenta a competitividade e reduz a concentração nas mãos de poucos players.',
      'As fintechs, em particular, têm acelerado essa revolução. Com o uso de tecnologia e análise avançada de dados, essas empresas conseguem avaliar riscos de forma mais ágil e precisa, proporcionando crédito de maneira mais eficiente do que os bancos tradicionais. A tendência é que, em um futuro próximo, a originação de crédito esteja pulverizada entre diversas instituições especializadas, cada uma focada em nichos específicos do mercado.',
      'Dentro desse novo cenário, os FIDCs ganham ainda mais relevância. Eles permitem que investidores acessem diretamente esse mercado, oferecendo liquidez e segurança para operações estruturadas. Além disso, a nova regulamentação da CVM, por meio da Resolução 175, amplia as possibilidades para o investidor de varejo participar desse segmento, tornando os FIDCs ainda mais populares.',
      'O impacto dessa mudança vai além da maior diversificação de fontes de crédito. Com mais players competindo pela originação, as taxas tendem a se tornar mais justas, a burocracia diminui e a eficiência do sistema financeiro aumenta. O resultado é um ambiente mais dinâmico, que beneficia tanto as empresas tomadoras de crédito quanto os investidores.',
      'O Brasil está apenas no início desse movimento de descentralização do crédito. Nos próximos anos, veremos um aumento significativo na participação do mercado de capitais nesse setor, impulsionado por novas regulamentações, avanços tecnológicos e um apetite crescente dos investidores por ativos de crédito estruturado.',
      'Diante desse cenário, os FIDCs se consolidam como uma das principais ferramentas para viabilizar essa transformação, ajudando a construir um mercado de crédito mais acessível, dinâmico e competitivo.',
    ],
  },
  {
    title:
      'O futuro do crédito: como as novas resoluções estão transformando o mercado',
    ads: {
      image: 'celcoin',
      link: 'https://www.celcoin.com.br/?utm_term=celcoin&utm_campaign=Celcoin+Branding+-+Marca&utm_source=google&utm_medium=cpc&hsa_acc=2473616689&hsa_cam=836168572&hsa_grp=141594608800&hsa_ad=611242820048&hsa_src=g&hsa_tgt=kwd-301855433431&hsa_kw=celcoin&hsa_mt=b&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=EAIaIQobChMIkrHEpPiijAMVkC1ECB1AhhZbEAAYASAAEgI_4_D_BwE',
    },
    authorLink:
      'https://uqbar.com.br/profissional/celcoin-instituicao-de-pagamento-s-a-10322/5117',
    partner: {
      name: 'Celcoin',
      link: 'https://uqbar.com.br/instituicao/celcoin/8932',
    },
    texts: [
      'O mercado de crédito brasileiro atravessa um momento singular, marcado por avanços regulatórios acelerados e uma iminente desconcentração bancária. As novas resoluções, aprovadas recentemente e impulsionadas pela tecnologia e pela diversificação dos agentes financeiros, estão abrindo caminhos para soluções inovadoras e mais eficientes, especialmente no que tange a garantias de operações de crédito.',
      'Segundo dados da FTI encomendados pelo Valor Econômico, os títulos do mercado de capitais saltaram de 15% do endividamento das empresas há 10 anos para 33% atualmente, com crescimento médio anual de 16,6%. Como consequência dessa evolução, o patrimônio líquido dos FIDCs aumentou de R$ 226 bilhões em 2021 para R$ 589 bilhões em 2024, com projeção de atingir R$ 811,7 bilhões no final de 2025.',
      'Entre as inovações mais promissoras destaca-se o Pix como Garantia, que promete reduzir o custo de crédito e promover maior inclusão financeira a pequenas e médias empresas. A nova resolução permite que lojistas solicitem crédito junto a instituições financeiras e, como garantia do pagamento, utilizem uma chave específica para redirecionar o fluxo das transações Pix para contas pré-determinadas, garantindo ao credor maior segurança nos recebíveis. Além de agilizar o processo de concessão de crédito, essa funcionalidade, integrada a plataformas de crédito automatizadas, reduz custos operacionais e garante a segurança das operações. Segundo dados do próprio Banco Central, em dezembro de 2024 o volume de transações do Pix foi de R$6.439.199.000,00, sendo visível o aumento do uso deste método de pagamento desde a sua criação.',
      'Outro ponto que merece destaque é o Drex, a moeda digital brasileira desenvolvida pelo Banco Central do Brasil (BCB). O BCB, reconhecido por sua postura independente e inovadora, tem implementado diversas iniciativas para modernizar o sistema financeiro nacional. O seu maior diferencial é a possibilidade de desenvolvimento de smart contracts (contratos inteligentes) que trazem o Banco Central como agente de garantia fiduciário. Para as operações estruturadas, o Drex será de uso quase imediato. Por exemplo, em uma operação imobiliária, o Drex permitirá a tokenização de matrículas de imóveis, agilizando a transferência de propriedade no momento da liquidação financeira. Isso eliminará a demora atual de até 60 dias para a efetivação do registro em cartório. Com o imóvel tokenizado, o proprietário poderá utilizar o token como garantia em operações de crédito ou para novas aquisições, simplificando transações e ampliando as possibilidades no mercado imobiliário. Se a parcela não é paga, o token retorna a ser de propriedade do credor.',
      'Ainda no setor imobiliário, a Lei 14.711/23, também conhecida como Marco Legal das Garantias, trouxe inovações significativas para este mercado, permitindo a criação de alienações fiduciárias sucessivas sobre um mesmo imóvel. Essa possibilidade amplia a capacidade de captação de recursos, permitindo que um imóvel sirva como garantia para múltiplas operações de crédito, desde que respeitadas as condições legais. Essa inovação amplia significativamente a capacidade de captação de recursos para empresas e investidores, oferecendo uma nova dimensão para operações de crédito que podem contar com garantias de imóveis.',
      'Já o E-Consignado, que será em breve regulamentado, representa um avanço significativo para o crédito consignado privado. A nova regra permite que empresas ofereçam crédito consignado diretamente para seus funcionários, retendo as parcelas em folha de pagamento - tudo isso ligado ao sistema do E-Social. Essa iniciativa promete impulsionar a economia ao colocar mais dinheiro em circulação, além de beneficiar as empresas que podem oferecer um diferencial competitivo para seus colaboradores, transformando o crédito consignado em uma alternativa atrativa para a gestão de benefícios.',
      'Por fim, mas não menos importante, está a regulamentação das Duplicatas Escriturais, que trouxe mais transparência e segurança jurídica para operações de antecipação de recebíveis. Com a padronização desses documentos em formato eletrônico e seu registro obrigatório, o risco de fraudes diminui substancialmente, tornando mais segura a concessão de crédito para empresas que utilizam seus recebíveis como garantia. Para instituições que operam com grandes volumes de crédito, como as fintechs e securitizadoras, essa regulamentação facilita a análise e o monitoramento dos riscos associados.',
      'A evolução do mercado de crédito, somada a legislações que corroboram o avanço tecnológico, está criando um terreno fértil para a inovação no mercado de crédito brasileiro. Nesse contexto, o conceito de Embedded Finance surge como uma oportunidade estratégica para empresas que desejam integrar serviços financeiros diretamente aos seus produtos e plataformas, oferecendo experiências mais fluídas e convenientes aos clientes. A Celcoin, a plataforma de crédito mais completa do mercado, está preparada para atender a essas demandas, oferecendo formalização automatizada de títulos, gestão integrada de carteira e ferramentas de análise de crédito com base nas novas resoluções.',
      'A capacidade de adaptar-se rapidamente a essas mudanças e de contar com soluções que combinam compliance, eficiência e automação será fundamental para instituições que desejam se destacar em um mercado cada vez mais competitivo e dinâmico.',
    ],
  },
  {
    title: 'FIDCs na gestão financeira das empresas ',
    ads: { image: 'oslo', link: 'https://www.oslodtvm.com/' },
    authorLink: 'https://uqbar.com.br/profissional/profissional-25/5165',
    partner: {
      name: 'Oslo',
      link: 'https://uqbar.com.br/instituicao/oslo-capital-distribuidora-de-titulos-e-valores-mobiliarios-sa/9095/perfil',
    },
    texts: [
      'A vasta literatura, reforçada por evidência acumulada ao longo dos anos, diz que investidores e empresas se beneficiam da coexistência do mercado de capitais com o mercado bancário. FIDCs são uma expressão desse mercado de capitais e contribuem tanto para investidores como para empresas.',
      'Os Fundos de Investimentos em Direitos Creditórios foram instituídos no final dos anos 90, e tem a função de financiar empresas pela antecipação de recebíveis. Para os investidores, esses fundos representam uma alternativa de buscar retorno com crédito securitizado e risco consistente com o perfil desejado. Os ativos do FIDC podem ser variados (imobiliário, agro, comercial, entre outros). Os investidores estão usualmente nas cotas sênior, com retorno fixo e risco mitigado pela prioridade de proteção de eventual perda na carteira até o limite da cota subordinada, em diferentes classes de ativos, como mostra a tabela abaixo:',
      'Tabela 1: Evolução do cenário de FIDCs no Brasil	',
      '![Evolução do cenário de FIDCs no Brasil](https://static.uqbaronline.com/production-backend/b2dfa7c409c87ff608cdcc6b9619bd8d.png)',
      'Fonte: UQBAR',
      'Para as empresas em geral, os Fundos de Direito Creditório são alternativas de financiamento da própria empresa através dos fornecedores (contas a pagar) e de seus clientes (contas a receber).',
      'Há uma construção híbrida, em que a empresa constitui um FIDC para fazer a gestão do suas contas a receber (às vezes a pagar) e ao mesmo tempo subscreve cotas subordinadas, tornando-se investidor com perfil de risco / retorno mais elevado.',
      'Ao subscrever cotas subordinadas, a empresa - ou seus acionistas – abrem espaço para captar cotas sênior com custo comparável ou algo mais reduzido que o mercado bancário. A experiência indica que, em CNTP, estes FIDCs podem ser um veículo estável de financiamento, dada a baixa volatidade de cotistas sênior. Os gráficos 1 e 2 indicam que existe um bom apetite, por parte dos investidores, para a cota sênior de FIDCs, com grau adequado de subordinação, formalização robusta e carteira de recebíveis com histórico conhecido.',
      'Gráfico 1: composição de FIDCs (pessoa física)',
      '![composição de FIDCs (pessoa física)](https://static.uqbaronline.com/production-backend/20e19f2c17ff28221d4fbd546beb8ecf.png)',
      'Fonte: Anbima',
      'Gráfico 2: composição de FIDCs (pessoa jurídica)',
      '![composição de FIDCs (pessoa jurídica)](https://static.uqbaronline.com/production-backend/b3f55a32b27732f5f9befd30d4bcb16e.png)',
      'Fonte: Anbima',
      'Ao mesmo tempo, o investidor (recursos da empresa ou dos acionistas) recebe eventual excesso de retorno oriundo da alavancagem em uma carteira de recebíveis de fornecedores e/ou clientes que a empresa conhece profundamente e, portanto, deveria ser uma boa avaliadora do risco da carteira.',
      'Os custos para constituição e operação continuada de um FIDC devem situar-se ao redor de 1,5 % a.a. do PL. Parte substancial destes custos decorre de obrigações regulatórias, que se ampliaram ao longo dos anos em resposta do regulador à eventos de não conformidade. Ainda assim, este custo representa uma fração do custo financeiro de uma empresa média, seguramente próximo de 18 - 20 % a.a. Neste sentido, e por paradoxal que pareça, o custo de intermediação no mercado de capitais brasileiro é elevado em termos absolutos, mas menos material na composição do custo total de financiamento.',
      'Em resumo, os FIDCs oferecem boa capacidade de captação de cota sênior com adequada subordinação, potencial estabilidade de financiamento, custo total não distante do mercado bancário, retorno adequado na cota subordinada com gestão de risco com histórico conhecido.',
    ],
  },
];

export const fidcYearbookDescriptions: YearbookDescriptions = {
  primary: [
    'Em 2024, o mercado primário de FIDCs seguiu a trajetória de crescimento observada em 2023, com um aumento de mais de 35% nas emissões. O cenário macroeconômico de juros elevados pode ajudar a explicar parcialmente esse aumento, uma vez que a taxa CDI, frequentemente utilizada como indexador em diversos títulos, combinada a um spread adicional, ofereceu atratividade para os investidores e impulsionou as emissões.',
    'Os fundos de condomínio fechado mantiveram destaque nas emissões frente aos fundos abertos assim como nos anos anteriores, mas a diferença diminuiu consideravelmente, o que pode indicar uma menor aversão a volatilidade desta categoria de fundo por parte dos investidores.',
    'Quanto aos ativos lastro, os Recebíveis Comerciais lideraram as emissões, seguidos por Crédito Pessoal e Crédito Pessoa Jurídica, mantendo o padrão de 2023. Em 2023, esses três tipos de FIDC somaram, juntos, cerca de 63,35% do total de emissões. Em 2024, o top 3 se manteve, representando 61% do total das emissões, evidenciando a relevância da captação de recursos via FIDC na estratégia dos mercados representados por esses ativos.',
  ],
  secondary: [
    `O mercado secundário de cotas de Fundos de Investimento em Direitos Creditórios (FIDCs) apresenta indicadores fundamentais sobre a liquidez e dinâmica da indústria.
    Os dados recentes revelam uma tendência expressiva de crescimento tanto no volume financeiro negociado quanto no número de transações ao longo de 2024, ampliando a evolução observada nos anos anteriores.
    Esta seção examinará os principais indicadores de liquidez do mercado secundário de FIDCs, com ênfase no aumento significativo das negociações e na distribuição das negociações por tipo de cota.`,

    `As cotas de subclasse sênior tiveram destaque em relação a quantidade de cotas negociadas e em relação ao volume de negociações, refletindo a preferência por menor risco e previsibilidade dos investidores.
    As cotas de subclasse subordinadas mezanino, subordinadas junior e únicas apresentaram menor volume de negociações, mas ainda assim contribuíram para uma maior diversificação, conforme corroboram os dados.`,
  ],
  stock: [
    `O Fundo de Investimento em Direitos Creditórios (FIDC) manteve sua trajetória de crescimento em 2024, consolidando-se como o principal veículo da securitização do Brasil e uma importante alternativa ao crédito bancário tradicional.
    O cenário macroeconômico reforçou o papel do FIDC como instrumento estratégico para investidores e instituições em busca de recursos. A alta da Taxa Selic e a dificuldade ao acesso a financiamentos impulsionaram a busca por diversificação e rentabilidade, fatores que podem ter contribuído para a expressiva expansão tanto do volume de patrimônio líquido quanto do número de operações.`,

    `Entre os segmentos de FIDCs, os fundos com ativo-lastro em recebíveis comerciais continuaram a liderar o mercado, seguidos pelos segmentos Multiclasse e Crédito Pessoal.
    A composição do mercado permaneceu relativamente estável ao longo do ano, indicando a consolidação dessas categorias como as mais representativas dentro da indústria.
    Esse comportamento pode refletir a preferência dos investidores por ativos com maior previsibilidade de fluxo de caixa e menor volatilidade, especialmente em um ambiente de juros elevados.`,

    `Os FIDCs Multicedente-Multissacado (MM) mantiveram sua tendência de crescimento, ampliando sua participação dentro do mercado e reforçando sua importância como instrumento de diversificação e pulverização de risco. Além disso, os FIDCs de condomínio fechado continuaram a representar a maioria das operações, seguindo o padrão observado nos últimos anos.
     Esse formato segue sendo o mais atrativo para investidores institucionais, principalmente devido à sua estruturação mais rígida e previsível em termos de gestão de riscos.`,
  ],
  rankings: [
    `É evidente a importância da boa prestação de serviços para o desenvolvimento do mercado de FIDC.
    A Uqbar monitora e mapeia as atividades e contribuições desses agentes para o mercado, a fim de fornecer maior transparência a todos os participantes, consolidando em rankings a posição dos prestadores de serviço de acordo com suas participações de mercado.`,

    `Alguns dos prestadores de serviço alcançaram um _market-share_ muito expressivo, o que aponta para uma alta concentração no mercado de FIDC, como é o caso da **agência de classificação de risco** Austin, que foi responsável pela classificação de risco de 46,68% dos títulos emitidos em 2024.
    O top 3 de maiores agentes de classificação de risco chega a 88,77% em relação a número de títulos classificados e a 73,11% do montante de PL total classificado.`,

    'A alta concentração também é realidade ao olharmos para o ranking de **assessores jurídicos**, no qual, de 15 instituições ranqueadas, o Machado Meyer lidera com 47,07% do montante total de PL assessorado.',

    'Alguns outros rankings apresentaram menores concentrações em uma só instituição, mas ainda assim, observando o top 3, é evidente que o mercado se concentra em poucos prestadores de serviço, como nos rankings de **administradores** e **custodiantes**.',

    `Dentre os prestadores de serviço ranqueados pela Uqbar, os **gestores** configuram maior pulverização.
    A primeira colocada do ranking por montante de PL, a Reag Jus Gestão de Ativos Judiciais, foi responsável pela gestão de 7,12% do PL da indústria.
    A mesma gestora liderou o ranking por número de operações, com 3,65% do total. O top 3 representou 19,07% do PL total e 10,14% do número de operações.`,

    'Das operações iniciadas em 2024, o FIDC Acr BEM Bancos Emissores de Cartao de Crédito RLtd foi o FIDC que fechou o ano com a maior cifra de **montante emitido**. No acumulado, a liderança permaneceu com o longevo FIDC Tapso.',
  ],
};
export const fidcPrimaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fidcPrimaryMarketCharts.emissionsFIDC,
    name: 'Emissões de FIDC',
    section: 'primary',
    details: {
      title: 'Emissões de FIDC',
      texts: [
        'As emissões de FIDCs no mercado primário apresentaram um crescimento em 2024, totalizando R$ 221,82 bilhões em montante emitido, o que representa uma elevação de 38,23% em relação a 2023, quando o valor foi de R$160,47 bilhões. Em comparação com 2022, o montante emitido em 2024 teve um aumento expressivo de 115,7%.',
      ],
      value: 221.81,
      valueTitle: 'Montante emitido 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fidcPrimaryMarketCharts.emissionsFIDCByCondominumType,
    name: 'Emissões de FIDC por Tipo de Condomínio',
    section: 'primary',
    details: {
      title: 'Emissões de FIDC por tipo de Condomínio',
      texts: [
        'Em 2024, o segmento de FIDCs do tipo "Condomínio Fechado" manteve sua posição predominante no mercado, sendo o mais representativo, com uma participação de 67,48% nas emissões. No entanto, observou-se uma redução em comparação a 2023, com uma diminuição de 8,11 pontos percentuais. Em contrapartida, os fundos classificados como "Condomínio Aberto" corresponderam a 32,52% das emissões em 2024, evidenciando um aumento de 8,11 pontos percentuais em relação ao ano anterior.',
      ],
    },
  },
  {
    chart: fidcPrimaryMarketCharts.emissionsFIDCByFundType,
    name: 'Emissões de FIDC por Tipo de Fundo',
    section: 'primary',
    details: {
      title: 'Emissões de FIDC Padronizados ganham espaço em 2024',
      texts: [
        `Com a consolidação da RCVM 175, os FIDCs deixaram de ser classificados como Não-Padronizados.
         Atualmente, apenas as operações e os recebíveis adquiridos pelos FIDCs são avaliados conforme esse critério.
         Nesse contexto, os FIDCs identificados como NPs pela Uqbar são aqueles que investem em direitos creditórios (DCs) Não-Padronizados.`,

        `Em 2024, a maior parte das emissões foi realizada por FIDCs que investem em direitos creditórios (DCs) Padronizados, os quais representaram 83,72% do valor total.
         Os 16,22% remanescentes referem-se a emissões realizadas por FIDCs que adquirem DCs Não Padronizados.
         Essa predominância registrou um aumento em relação a 2023, quando os FIDCs voltados para recebíveis Padronizados corresponderam a 71,94% do total das emissões.
         Há ainda uma fração de fundos que não revelam o tipo de investimento que fazem em seu regulamento.
         A histórica preferência pelos DCs Padronizados pode ser atribuída ao fato de serem mais atrativos para os investidores, além de os requisitos para o investimento em DCs Não Padronizados serem mais restritivos.`,
      ],
    },
  },
  {
    chart: fidcPrimaryMarketCharts.emissionsFIDCByProductType,
    name: 'Emissões de FIDC por Ativo-Lastro',
    section: 'primary',
    details: {
      title: 'Recebíveis Comerciais é o destaque como Ativo Lastro em 2024',
      texts: [
        `Em 2024, os Recebíveis Comerciais continuaram a se destacar como o principal ativo lastro no mercado de FIDCs, representando 32,36% do total emitido.
        O Crédito Pessoal permaneceu em segundo lugar, respondendo por 15,94%. O ativo lastro Crédito Pessoa Jurídica, por sua vez, alcançou uma participação de 11,71%.
        Como se pode observar, o top 3 permaneceu inalterado em relação a 2023, enquanto no top 5 a categoria Cotas de FIDCs ingressou entre os cinco primeiros, subindo da oitava posição em 2023 para o quinto lugar em 2024.`,
      ],
    },
  },
];
export const fidcSecondaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fidcSecondaryMarketCharts.negotiatedQuotasInFIDC,
    name: 'Negócios com cota de FIDC',
    section: 'secondary',
    details: {
      title: 'Indicadores de liquidez do secundário de FIDC',
      texts: [
        `Os dados do mercado secundário de cotas de FIDC, considerando tanto a bolsa quanto o balcão, indicam um crescimento expressivo no número de negócios realizados e no volume financeiro negociado nos últimos anos.
        Em 2020, foram registrados 19.985 negócios, totalizando R$ 17 bilhões em volume negociado. No ano seguinte, observou-se uma redução, com 14.049 transações e um volume financeiro de R$ 12,07 bilhões.
        Contudo, a partir de 2022, houve um aumento significativo, com 141.348 negociações e R$ 9,8 bilhões negociados. Em 2023, o número de negócios mais que dobrou, atingindo 298.987 transações, acompanhadas de um expressivo crescimento no volume financeiro, que alcançou R$ 18,72 bilhões.
        Em 2024, o mercado seguiu sua expansão, registrando 415.202 negócios e um volume total de R$ 19,11 bilhões. Esse crescimento foi impulsionado, em grande parte, por uma quantidade significativa de negociações, especialmente do FIAGRO Crédito Agrícola, administrado pelo BTG Pactual Serviços Financeiros, que contribuiu com 349.785 negociações em Bolsa em 2024.`,
      ],
      value: 19.11,
      valueTitle: 'Montante Negociado 2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fidcSecondaryMarketCharts.negotiatedQuotasInFIDCMonthly,
    name: 'Evolução Mensal dos Negócios com Cotas de FIDC',
    section: 'secondary',
    details: {
      title: 'Evolução Mensal dos Negócios com Cotas de FIDC',
      texts: [
        `O mercado secundário de FIDC apresentou variações expressivas ao longo de 2024. De janeiro a abril, o volume negociado registrou um crescimento de aproximadamente 150%, atingindo seu pico anual em abril, com um montante de R$ 2,44 bilhões.
        A partir de maio, no entanto, observou-se uma tendência de queda, com uma retração de cerca de 45% até setembro. Após uma leve recuperação em outubro, os valores voltaram a declinar, e dezembro registrou um volume de negociações de R$ 1,04 bilhão, o segundo menor do ano.
        Em relação ao número de negócios, o comportamento foi distinto: julho destacou-se como o mês com o maior número de negociações, registrando 52.386 transações, enquanto novembro apresentou a menor quantidade, com apenas 23.791 negócios.`,
      ],
      value: 2.44,
      valueTitle: 'Montante Negociado Abril/2024',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fidcSecondaryMarketCharts.negotiatedQuotasInFIDCExchange,
    name: 'Negócios com Cotas de FIDC em Ambiente de Bolsa e Balcão',
    section: 'secondary',
    details: {
      title: 'Negócios com Cotas de FIDC em Ambiente de Bolsa e Balcão',
      texts: [
        `Observa-se uma dicotomia entre os mercados de bolsa e balcão. Enquanto as cotas negociadas em bolsa em 2024 totalizam apenas R$ 168,5 milhões, mas com 368.344 transações, as cotas negociadas no balcão somam R$ 18,95 bilhões, distribuídos em apenas 46.858 negociações.
        Além disso, destaca-se que, em 2024, todos os FIDCs negociados em bolsa são do tipo FIAGRO, enquanto o mercado de balcão apresenta uma maior diversidade de fundos.
        Entre os fundos com os maiores volumes financeiros negociados, o FIDC Bancos Emissores de Cartão de Crédito - Stone IV se destaca, com R$ 848,22 milhões em 68 transações.
        Por outro lado, no que se refere ao maior número de negociações, o FIAGRO BTG Pactual Crédito Agrícola lidera, com 349.785 operações, embora seu montante negociado tenha sido relativamente baixo, somando R$ 151,81 milhões.`,
      ],
      value: 168.5,
      valueTitle: 'Montante Negociado Bolsa 2024',
      valueUnit: 'R$ (milhões)',
    },
  },
];
export const fidcStockMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fidcStockMarketCharts.plFidc,
    name: 'PL de FIDC',
    section: 'stock',
    details: {
      title: 'Patrimônio líquido dos FIDCs em 2024',
      texts: [
        `O mercado de FIDCs concluiu o ano de 2024 com um novo recorde tanto em termos de patrimônio líquido quanto no número de fundos ativos.
        Em dezembro, o PL acumulado alcançou R$ 635,74 bilhões, um expressivo aumento em relação aos R$ 447,24 bilhões registrados em 2023, o que representa um crescimento de 42,11%. Além disso, o número de fundos ativos também apresentou um crescimento significativo, passando de 2.354 para 3.043 em apenas um ano.`,
      ],
      value: 635.74,
      valueTitle: 'PL em dez/24',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fidcStockMarketCharts.plFIDCByCondominumType,
    name: 'PL de FIDC por Tipo de Condomínio',
    section: 'stock',
    details: {
      title: 'Condomínio fechado predomina o mercado de FIDCs',
      texts: [
        `Em 2024, o segmento de condomínio fechado continuou a dominar o mercado de FIDCs, correspondendo a 78,83% do patrimônio líquido, o que equivale a aproximadamente R$ 501,25 bilhões.
        Por sua vez, o tipo de condomínio aberto representou 21,17% do PL, totalizando cerca de R$ 134,64 bilhões. Embora, em termos percentuais, a participação de cada tipo de condomínio tenha apresentado uma variação discreta em relação ao ano anterior – em que o condomínio fechado correspondia a 81,04% e o aberto a 18,96% –, o valor absoluto do PL evidenciou um crescimento considerável.
        Em 2023, o PL dos fundos de condomínio fechado somava R$ 362,43 bilhões, enquanto o de condomínio aberto era de R$ 84,80 bilhões.`,
      ],
    },
  },
  {
    chart: fidcStockMarketCharts.plFIDCByFundType,
    name: 'PL de FIDC por Tipo de Fundo',
    section: 'stock',
    details: {
      title: 'FIDCs Padronizados representam a maior parte do patrimônio',
      texts: [
        `Com a consolidação da RCVM 175, os FIDCs deixaram de ser classificados como Não-Padronizados.
        Atualmente, apenas as operações e os recebíveis adquiridos pelos FIDCs são avaliados conforme esse critério.
        Nesse contexto, os FIDCs identificados como NPs pela Uqbar são aqueles que investem em direitos creditórios (DCs) Não-Padronizados.`,
        `Em dezembro de 2024, os FIDCs que investem em DCs Padronizados representaram 63,40% do patrimônio líquido (PL) total dos FIDCs, enquanto os FIDCs que adquirem recebíveis Não-Padronizados (cujos DCs possuem ao menos uma das características previstas no inciso XIII do art. 2º do Anexo II da RCVM 175) corresponderam a 36,42% do PL.
        Historicamente, os FIDCs que investem em DCs Padronizados mantêm a maior participação no mercado de FIDCs.`,
      ],
    },
  },
  {
    chart: fidcStockMarketCharts.plFIDCByProduct,
    name: 'PL de FIDC por Ativo-Lastro',
    section: 'stock',
    details: {
      title: 'PL de FIDC por Ativo-lastro',
      texts: [
        `Em 2024, os FIDCs de Recebíveis Comerciais se destacaram no mercado, representando 32,38% do patrimônio líquido (PL), equivalente a R$ 205,87 bilhões.
        Em segundo lugar, os FIDCs Multiclasse somaram R$ 82,92 bilhões, correspondendo a 13,04% do PL total.
        Na terceira posição, os FIDCs de Crédito Pessoal registraram R$ 78,95 bilhões, o que representa 12,42% do PL. Historicamente, os FIDCs de Recebíveis Comerciais têm mantido a maior participação percentual na composição do patrimônio líquido total dos FIDCs.`,
      ],
    },
  },
  {
    chart: fidcStockMarketCharts.plFidcMM,
    name: 'PL dos FIDC Multicedente-Multissacado',
    section: 'stock',
    details: {
      title: 'PL dos FIDC Multicedente Multissacado',
      texts: [
        `Em dezembro de 2024, os FIDCs multicedente-multissacado (MM), que possuem carteira composta por direitos creditórios de diversos cedentes e sacados, apresentaram um patrimônio líquido (PL) de R$ 51,15 bilhões, registrando um aumento de 27,14% em comparação com dezembro de 2023.
        No entanto, o número de FIDCs MM diminuiu de 376 para 362, o que representa uma queda de 3,7% em relação ao final de 2023. Em comparação com dezembro de 2022, o PL dos FIDCs MM cresceu 33,62%, enquanto a quantidade desses fundos caiu de 386 para 362.
        O FIDC Red Real LP se destaca como o maior FIDC MM em termos de PL, com R$ 3,46 bilhões.`,
      ],
    },
  },
];

export const fidcRankings: YearbookMarketRanking[] = [
  {
    name: 'Ranking FIDCs por Montante Emitido',
    details: {
      title: 'Ranking FIDCs por Montante Emitido',
      texts: [
        'O ranking acumulado de FIDCs por Montante Emitido no mercado primário fechou o ano de 2024 com 2.113 fundos classificados, totalizando R$ 221,82 bilhões. Os três primeiros colocados do ranking concentram 11,46% desse valor. O FIDC For-Te, lastreado em Recebíveis Comerciais, mantém-se na liderança, com uma emissão de R$ 9,02 bilhões, representando 4,07% do total. Em segundo lugar, com R$ 8,26 bilhões e uma participação de 3,72%, encontra-se a Aetos Energia. Na terceira posição, o FIDC Viking, com R$ 7,95 bilhões, corresponde a 3,58% do montante emitido.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=stock&criteria=amountissued&timeRange=consolidated',
  },
  {
    name: 'Ranking de Assessores Jurídicos por Montante Emitido',
    details: {
      title: 'Ranking de Assessores Jurídicos por Montante Emitido',
      texts: [
        'O ranking de Assessores Jurídicos ranqueados em 2024 apresenta um montante emitido de R$ 24,95 bilhões, com 15 assessores classificados. O primeiro lugar foi ocupado pelo escritório Machado Meyer, com R$ 11,74 bilhões, o que representa 47,07% do total. Em segundo lugar, ficou a VBSO, com R$ 4,69 bilhões, correspondendo a 18,80% do total. A terceira posição foi alcançada pelo escritório Pinheiro Neto, com R$ 4,02 bilhões, o que equivale a 16,12%. Na quarta posição, está Barcellos Tucunduva, com R$ 1,66 bilhão, representando 6,65%. Por fim, na quinta posição, encontra-se FreitasLeite, com R$ 864,12 milhões, o que corresponde a 3,46%. Com esses dados, percebe-se que 82,01% do montante total emitido está concentrado nos três primeiros assessores jurídicos do ranking.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=legaladvisor&criteria=amountissued&timeRange=yearly',
  },
  {
    name: 'Ranking de Assessores Jurídicos por Número de Operações',
    details: {
      title: 'Ranking de Assessores Jurídicos por Número de Operações',
      texts: [
        'O ranking em questão apresentou 15 Assessores Jurídicos e um total de 106 operações, destacando a liderança do escritório Barcellos Tucunduva, com 31 operações, o que representa 29,25% do total. Na segunda posição, empatados, estão os escritórios Pinheiro Neto e VBSO, ambos com 16 operações, correspondendo a 15,09% do total cada. Em seguida, encontra-se o escritório Machado Meyer, com 10 operações, representando 9,43%. Juntos, os quatro escritórios somam 68,87% do número total de operações.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=legaladvisor&criteria=operationsissued&timeRange=yearly',
  },
  {
    name: 'Ranking de Agências de Classificação de Risco por Montante Classificado',
    details: {
      title:
        'Ranking de Agências de Classificação de Risco por Montante Classificado',
      texts: [
        'No ranking apresentado, o montante de R$ 6,95 bilhões em 2024 representou uma queda de 67,32% em relação ao ano anterior. A lista inclui 6 instituições, com a Austin liderando, com R$ 1,93 bilhão, o que corresponde a 27,83% do total. Em segundo lugar, está a Fitch, com R$ 1,83 bilhão, representando 26,32%, seguida pela Liberum Ratings, em terceiro lugar, com R$ 1,33 bilhão, equivalente a 19,18%. Os três primeiros ranqueados concentram juntos 81,4% do montante total.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=agencyrating&criteria=amountclassified&timeRange=yearly',
  },
  {
    name: 'Ranking de Agências de Classificação de Risco por Número de Cotas Classificadas',
    details: {
      title:
        'Ranking de Agências de Classificação de Risco por Número de Cotas Classificadas',
      texts: [
        'Foram classificados um total de 392 títulos, com a presença de 6 instituições no ranking de Agências de Classificação de Risco por número de Títulos Classificados. A Austin lidera a lista com 183 títulos, o que corresponde a 46,68% do total. Em segundo lugar, encontra-se a Liberum Ratings, com 114 títulos, representando 29,08%, seguida pela SR, em terceiro lugar, com 51 títulos, o que equivale a 13,01%. Juntas, as três primeiras instituições concentram 88,77% do total de títulos classificados.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=agencyrating&criteria=titlesnumberclassified&timeRange=yearly',
  },
  {
    name: 'Ranking FIDC por Montante de PL',
    details: {
      title: 'Ranking FIDC por Montante de PL',
      texts: [
        'O ranking de FIDCs por Montante de PL no mercado de capitais totalizou R$ 137,80 bilhões em 2024, representando um aumento de 83,09% em relação a 2023, quando o montante foi de R$ 75,26 bilhões. Os três primeiros colocados do ranking são responsáveis por 8,84% do total, o que indica uma maior distribuição em comparação ao ano anterior, quando a concentração dos três primeiros atingiu 28,9%. O Itaú Crédito Privado, com lastro em prestação de serviço público, lidera com R$ 4,95 bilhões, correspondendo a 3,59%. Em segundo lugar, está o Acr BEM Bancos Emissores de Cartão de Crédito, com lastro em crédito pessoa jurídica, somando R$ 3,66 bilhões, ou 2,66%. Na terceira posição, encontra-se o BV Auto I Segmento Financeiro, com lastro em financiamento de veículos representados por CCBs, totalizando R$ 3,54 bilhões, ou 2,57%.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=stock&criteria=amountnetworth&timeRange=yearly',
  },
  {
    name: 'Ranking FIDC por Montante de PL - Acumulado',
    details: {
      title: 'Ranking FIDC por Montante de PL - Acumulado',
      texts: [
        'Em 2024, o ranking por montante de PL de todos os FIDCs em atividade registrou 3.045 fundos ranqueados, um acréscimo de 779 registros em relação ao ano anterior, totalizando R$635,90 bilhões. Esse valor representa um aumento de 45,55% em comparação a 2023, quando o montante era de R$ 436,89 bilhões. A concentração do PL é de 11,58% nos três primeiros colocados, sendo o FIDC Tapso na liderança, seguido pelo Anna FIC NP e, logo depois, o Cielo.',
        'O FIDC Tapso lidera com R$ 32,45 bilhões, correspondendo a 5,10% do total; o Anna FIC NP ocupa a segunda posição, com R$ 13,07 bilhões, representando 2,06%; e o FIDC Cielo está em terceiro lugar, com R$ 11,03 bilhões, equivalente a 1,74%.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=stock&criteria=amountnetworth&timeRange=consolidated',
  },
  {
    name: 'Ranking de Administradores por Montante de PL',
    details: {
      title: 'Ranking de Administradores por Montante de PL',
      texts: [
        'O ranking de administradores do mercado de FIDC apresenta, em 2024, 39 instituições ranqueadas e um montante total de R$ 137,80 bilhões. Os três primeiros colocados detêm 45,46% desse valor. A Reag DTVM lidera o ranking com R$ 24,95 bilhões, correspondendo a 18,10%. Em segundo lugar, está o BTG Pactual Serviços Financeiros DTVM, com R$ 23,10 bilhões, representando 16,76%. Já o Banco Daycoval ocupa a terceira posição com R$ 14,61 bilhões, equivalente a 10,60%.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=admin&criteria=amountnetworth&timeRange=yearly',
  },
  {
    name: 'Ranking de Administradores por Número de Operações',
    details: {
      title: 'Ranking de Administradores por Número de Operações',
      texts: [
        'Com um total de 1.004 operações e 39 empresas ranqueadas, as seguintes instituições foram identificadas no ranking de Administradores por Número de Operações: ',
        'A Singulare lidera o ranking com 162 operações, correspondendo a 16,14% do total; o BTG Pactual Serviços Financeiros DTVM ocupa a segunda posição, com 136 operações, representando 13,55%; e o Banco Daycoval está em terceiro lugar, com 110 operações, equivalendo a 10,96%.',
        'Dessa forma, conclui-se que as três primeiras instituições do ranking concentraram 40,63% do número total de operações.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=admin&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Administradores por PL - Acumulado',
    details: {
      title: 'Ranking de Administradores por PL - Acumulado',
      texts: [
        'O ranking de administradores por montante de PL de todos os FIDCs em atividade é composto por 51 instituições e totalizou R$ 635,92 bilhões, representando um aumento de 45,61% em relação a 2023, quando o montante era de aproximadamente R$ 436,91 bilhões. O top 3 do ranking permanece o mesmo de 2023 e concentra 41,03% do total. A liderança é ocupada pela Oliveira Trust, com R$ 94,73 bilhões, o que corresponde a 14,90% do total. Em segundo lugar, está a Singulare, com R$ 91,73 bilhões, representando 14,43% do montante. Na terceira posição, encontra-se o BTG Pactual Serviços Financeiros DTVM, com R$74,56 bilhões, equivalente a 11,72% do total.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=admin&criteria=amountnetworth&timeRange=consolidated',
  },
  {
    name: 'Ranking de Administradores por Número de Operações - Acumulado',
    details: {
      title: 'Ranking de Administradores por Número de Operações - Acumulado',
      texts: [
        'O Ranking de Administradores de FIDCs por número de operações em atividade, no final de 2024, registrou um total de 3.019 operações ativas e 51 instituições ranqueadas. O top 3 de administradores concentra 39,68% de participação no ranking. A Singulare ocupa a primeira posição, com 720 operações ativas, correspondendo a 23,85% do total. Em segundo lugar, figura o BTG Pactual Serviços Financeiros DTVM, com 259 operações, o que representa 8,58% do total. O Banco Daycoval aparece em terceiro lugar, com 219 operações, equivalente a 7,25% do total do ranking.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=admin&criteria=operations&timeRange=consolidated',
  },
  {
    name: 'Ranking de Custodiantes por Montante de PL',
    details: {
      title: 'Ranking de Custodiantes por Montante de PL',
      texts: [
        'O Ranking de Custodiantes de FIDCs por montante de patrimônio líquido (PL), no ano de 2024, registrou a cifra de R$ 130,03 bilhões, distribuídos entre 41 empresas ranqueadas. Em comparação com 2023, o PL aumentou em 73,06%. O top 3 de Custodiantes concentrou 46,36% do total do ranking, com o Banco BTG Pactual permanecendo na liderança, com um PL de R$ 22,91 bilhões. Em segundo lugar, aparece a Reag DTVM, com R$ 22,35 bilhões em PL, seguida pelo Banco Daycoval, com um montante de R$ 15,02 bilhões.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=custodian&criteria=amountnetworth&timeRange=yearly',
  },
  {
    name: 'Ranking de Custodiantes por Número de Operações',
    details: {
      title: 'Ranking de Custodiantes por Número de Operações',
      texts: [
        'O Ranking de Custodiantes de FIDCs por número de operações em atividade, em 2024, registrou um total de 983 operações ativas, o que representa um aumento de 63,29% em relação ao ano de 2023. Essas operações estão distribuídas entre 41 empresas ranqueadas. As três primeiras instituições do ranking somaram juntas 42,92% de participação, destacando-se: Singulare, com 161 operações; Banco BTG Pactual, com 131 operações; e Banco Daycoval, com 130 operações.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=custodian&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Custodiantes por Montante de PL - Acumulado',
    details: {
      title: 'Ranking de Custodiantes por Montante de PL - Acumulado',
      texts: [
        'O Ranking de Custodiantes de FIDCs por montante de PL dos fundos operacionais registrou, em 2024, um total de R$ 627,12 bilhões, representando um aumento de 43,69% em relação a 2023. As operações estão distribuídas entre 55 empresas ranqueadas. As três primeiras colocadas concentraram juntas 41,36% do total do ranking. São elas: Oliveira Trust, com R$ 98,86 bilhões em PL; Singulare, com R$ 91,16 bilhões em PL; e Reag DTVM, com R$ 69,36 bilhões em PL.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=custodian&criteria=amountnetworth&timeRange=consolidated',
  },
  {
    name: 'Ranking de Custodiantes por Número de Operações - Acumulado',
    details: {
      title: 'Ranking de Custodiantes por Número de Operações - Acumulado',
      texts: [
        'O Ranking de Custodiantes de FIDCs por número de operações em atividade, em dezembro de 2024, registrou um total de 3.004 operações ativas, distribuídas entre 55 empresas ranqueadas. As três primeiras colocadas somaram juntas 40,55% de participação no ranking, sendo elas: Singulare, com 722 operações, representando 24,03% do total; Banco Daycoval, com 262 operações, correspondente a 8,72% de participação; e Banco BTG Pactual, com 234 operações, o que equivale a 7,79% do total.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=custodian&criteria=operations&timeRange=consolidated',
  },
  {
    name: 'Ranking de Gestores por Montante de PL',
    details: {
      title: 'Ranking de Gestores por Montante de PL',
      texts: [
        'O Ranking de Gestores de FIDCs por montante de PL dos fundos que entraram em operação em 2024 registrou um total de R$ 137,80 bilhões, um aumento de 83,09% em relação a 2023, com 243 gestores ranqueados em 2024. O top 3 de Gestores concentrou 19,07% do total do ranking, abaixo dos 36,07% registrados em 2023. Na liderança, encontra-se a Reag Jus Gestão de Ativos Judiciais, com R$ 11,99 bilhões de PL por operações ativas, representando 8,70% do total. Em segundo lugar, aparece a Artesanal Investimentos, com PL de R$ 7,22 bilhões por operações ativas, correspondendo a 5,24% do ranking. Na terceira posição, está a Angá Administração de Recursos, com R$ 7,05 bilhões de PL por operações ativas, somando 5,11% do total.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=manager&criteria=amountnetworth&timeRange=yearly',
  },
  {
    name: 'Ranking de Gestores por Número de Operações',
    details: {
      title: 'Ranking de Gestores por Número de Operações',
      texts: [
        'O Ranking de Gestores de FIDCs por número de operações realizadas em 2024 registrou um total de 1.014 operações ativas e 243 gestores ranqueados. O top 3 de gestores por quantidade de operações somou 10,14% de participação no ranking. Na liderança, encontra-se a Reag Jus Gestão de Ativos Judiciais, com 37 operações, o que corresponde a 3,65% do total. Em segundo lugar, está a Tercon Investimentos, com 35 operações, totalizando 3,45% de participação no ranking. Na terceira posição, aparece a Catalise Investimentos, com 31 operações ativas, somando 3,06% de participação.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=manager&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Gestores por Montante de PL - Acumulado',
    details: {
      title: 'Ranking de Gestores por Montante de PL - Acumulado',
      texts: [
        'O Ranking de Gestores de FIDCs por montante de patrimônio líquido (PL) dos fundos ativos, em dezembro de 2024, registrou R$ 635,90 bilhões e contou com 374 gestores ranqueados. Em comparação com dezembro de 2023, observou-se um aumento de 45,53% no PL e de 16,51% no número de gestores ranqueados. O top 3 de Gestores concentrou 18,85% do total do ranking, com destaque para a Reag Jus Gestão de Ativos Judiciais, que assumiu a liderança, com R$ 45,27 bilhões de PL por operações ativas, representando 7,12% do total. Em segundo lugar, ficou a Oliveira Trust, com PL de R$ 37,63 bilhões, o que equivale a 5,92% de participação no ranking. Na terceira posição, aparece a Oliveira Trust Servicer, com R$ 36,85 bilhões de PL por operações ativas, somando 5,80% de participação no ranking.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=manager&criteria=amountnetworth&timeRange=consolidated',
  },
  {
    name: 'Ranking de Gestores por Número de Operações - Acumulado',
    details: {
      title: 'Ranking de Gestores por Número de Operações - Acumulado',
      texts: [
        'O Ranking de Gestores de FIDCs por número de operações em atividade, em dezembro de 2024, registrou um total de 3.045 operações ativas, distribuídas entre 374 gestores ranqueados. Os três primeiros gestores do ranking somaram juntos 12,51% de participação no total. Tercon Investimentos liderou com 211 operações, correspondendo a 6,93% do total; Ouro Preto Gestão de Recursos ficou em segundo lugar, com 95 operações, representando 3,12%; e Solis Investimentos ocupou a terceira posição, com 74 operações, totalizando 2,43% de participação no ranking.',
      ],
    },
    endpointUrl:
      'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2024&ranking=manager&criteria=operations&timeRange=consolidated',
  },
];
