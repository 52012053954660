import { useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useQuery } from 'react-query';

import { Gallery } from './Gallery';
import { Tabs } from './Tabs';
import { colors } from '../../constants';
import { marketVideos } from '../../data/presentation';
import { MarketName, YearbookMarketVideo } from '../../types';
import { getVideoEmbedUrl, getVideoThumbnailUrl } from '../../utils';

type VideoGalleryProps = {
  market: MarketName;
};

export function VideoGallery({ market }: VideoGalleryProps) {
  const [selectedVideo, setSelectedVideo] =
    useState<YearbookMarketVideo | null>(null);
  const [tabValue, setTabValue] = useState(0);

  const videoEmbedUrl = useMemo(
    () => (selectedVideo ? getVideoEmbedUrl(selectedVideo.videoId) : ''),
    [selectedVideo],
  );

  const relatedVideos = useMemo(
    () =>
      marketVideos.filter(
        (video) => video.market === market && video !== selectedVideo,
      ),
    [market, selectedVideo],
  );

  const videos = useMemo(
    () => (tabValue === 0 ? marketVideos : relatedVideos),
    [tabValue, selectedVideo, market],
  );

  const { data: thumbnails } = useQuery({
    queryKey: ['thumbnails'],
    queryFn: async () => {
      const thumbnailUrls = await Promise.all(
        videos.map((video) => getVideoThumbnailUrl(video.videoId)),
      );

      return videos.reduce(
        (acc, video, index) => {
          acc[video.videoId] = thumbnailUrls[index];
          return acc;
        },
        {} as Record<string, string>,
      );
    },
  });

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setTabValue(newValue);
  }

  function handleVideoClick(video: YearbookMarketVideo) {
    setSelectedVideo(video);
  }

  useEffect(() => {
    setSelectedVideo(relatedVideos[0]);
  }, [market]);

  return (
    <Box
      component="section"
      data-yearbook-section="Vídeos"
      sx={({ breakpoints }) => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        bgcolor: colors.black,
        p: 2,
      })}>
      <Box
        sx={({ breakpoints }) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
          maxWidth: breakpoints.values.lg,
          mx: 'auto',
          color: colors.white,
          [breakpoints.down(1480)]: {
            ml: '130px',
            width: 'calc(100% - 130px)',
          },
          [breakpoints.down('lg')]: {
            width: '100%',
            ml: 0,
          },
        })}>
        <Typography variant="h2">Galeria de Vídeos</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
            mt: 2,
          }}>
          <Typography variant="h6">{selectedVideo?.title}</Typography>
          <Typography variant="body1">{selectedVideo?.author}</Typography>
          {videoEmbedUrl && (
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                paddingTop: '56.25%',
              }}>
              <Box
                component={'iframe'}
                allow="autoplay; clipboard-write; encrypted-media; web-share"
                allowFullScreen
                src={videoEmbedUrl}
                width="100%"
                height="100%"
                maxWidth="100%"
                sx={{
                  bgcolor: colors.black,
                  border: 'none',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <Tabs tabValue={tabValue} handleTabChange={handleTabChange} />

            {tabValue === 0 && (
              <Gallery
                videos={videos}
                thumbnails={thumbnails}
                handleVideoClick={handleVideoClick}
                hidden={tabValue !== 0}
              />
            )}

            {tabValue === 1 && (
              <Gallery
                videos={relatedVideos}
                thumbnails={thumbnails}
                handleVideoClick={handleVideoClick}
                hidden={tabValue !== 1}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
