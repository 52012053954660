import { useEffect, useState } from 'react';

import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import { useQuery } from 'react-query';
import { findRankingStatisticsResume } from 'src/services/ranking';

import { useAuthContext } from '../../../context/AuthContextProvider';
import { convertValue } from '../../../utils/number';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '0.875rem',
    color: '#6D6E71',
    lineHeight: '16.94px',
  },
  value: {
    fontSize: '1.125rem',
    lineHeight: '21.78px',
    fontWeight: 700,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0, 4),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  item: {
    marginTop: '1.125rem',
  },
}));

const Item = ({ classes, title, value }) => {
  return (
    <Box className={classes.item}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </Box>
  );
};

export const StatisticsResume = ({
  state,
  showStatistics,
  setShowStatistics,
  numberCriterias,
  valueLabel,
  filters,
  hasConsolidated,
}) => {
  const classes = useStyles();
  const { auth } = useAuthContext();
  const [statistics, setStatistics] = useState({
    totalAmount: 0,
    totalPercentage: 0,
    total: 0,
    top3Percentage: 0,
  });
  const hasPermission =
    auth?.user?.subscription?.plan?.permissions['rankings_completo'];

  const { isFetching, refetch } = useQuery({
    queryKey: ['ranking-statistics-resume', filters, showStatistics],
    queryFn: () =>
      findRankingStatisticsResume(
        {
          market: state.market.toLowerCase(),
          year: state.year,
          type: state.ranking,
          criteria: state.criteria,
          timeRange: hasConsolidated ? state.timeRange : 'default',
        },
        auth.isLoggedIn,
      ),
    onSuccess: (data) => {
      setStatistics(data);
    },
    enabled: showStatistics,
  });

  const { totalAmount, totalPercentage, total, top3Percentage } = statistics;

  useEffect(() => {
    refetch();
  }, [showStatistics]);

  if (isFetching) {
    return (
      <Box className={classes.container}>
        <Skeleton variant="text" classes={classes} height={60} width={150} />
        <Skeleton variant="text" classes={classes} height={60} width={150} />
        <Skeleton variant="text" classes={classes} height={60} width={150} />
        <Skeleton variant="text" classes={classes} height={60} width={150} />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Item
        classes={classes}
        title="Total"
        value={
          numberCriterias.includes(state.criteria)
            ? totalAmount
            : valueLabel.includes('Montante')
              ? `${convertValue(totalAmount, null, true, 'currency')}`
              : `${convertValue(totalAmount, null, false, 'decimal')}`
        }
      />
      <Item classes={classes} title="Número de ranqueados" value={total} />
      <Item
        classes={classes}
        title="Concentração (top 3)"
        value={convertValue(
          isFetching ? 0 : top3Percentage / totalPercentage,
          null,
          false,
          'percent',
        )}
      />
      <Button
        variant="text"
        color="secondary"
        onClick={() => setShowStatistics((prev) => !prev)}
        disabled={!hasPermission}>
        {showStatistics ? 'Ver ranking' : 'Ver histórico'}
      </Button>
    </Box>
  );
};
