import { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

type SponsorCardProps = PropsWithChildren<{
  onClick?: () => void;
}>;

export function SponsorCard({ children, onClick }: SponsorCardProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        borderRadius: 2,
        padding: 2,
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
          cursor: 'pointer',
        },
        width: '100%',
        height: 260,
      }}
      onClick={onClick}>
      {children}
    </Box>
  );
}
