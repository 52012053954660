import { useState } from 'react';

import {
  Box,
  Divider,
  Grid,
  Hidden,
  Link,
  Typography,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';

import Details from './Details';
import Facilitators from './Facilitators';
import FacilitatorsDetails from './FacilitatorsDetails';
import Modules from './Modules';
import Organizers from './Organizers';
import Resume from './Resume';
import Supporters from './Supporters';
import Tesmonials from './Testmonials';
import TrainingTop from './TrainingTop';
import { trainingStyles } from './styles';
import Course from '../../components/Course';
import { If } from '../../components/If';
import MetaTags from '../../components/Seo/MetaTags';
import Subheader from '../../components/Subheader';
import { getTrainingById, getTrainings } from '../../services/training';
import { normalizeContent } from '../../utils/normalize-content';
import { ScrollToTop } from '../../utils/scroll';

function Presentation({ content, classes }) {
  return (
    <Box py={2}>
      <Typography
        style={{ whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{
          __html: normalizeContent(content, classes),
        }}
      />
    </Box>
  );
}

function RelatedCourses({ isLoading, courses }) {
  if (isLoading) return null;

  return courses
    .slice(0, 3)
    .map(({ id, title, previewImage, type, markets, slug, resume }) => (
      <Grid item xs={12} sm={6} md={4} key={id}>
        <Course
          hashId={id}
          title={title}
          image={previewImage}
          type={type}
          market={markets}
          slug={slug}
          resume={resume}
        />
      </Grid>
    ));
}

export default function Training() {
  const classes = trainingStyles();
  const [state, setState] = useState({ facilitatorsDetails: false });
  const { hashId } = useParams();
  const { data, isLoading, error } = useQuery(['training', hashId], () =>
    getTrainingById(hashId),
  );

  const marketsIds = data?.markets.map(({ id }) => id);
  const markets = marketsIds?.length ? marketsIds : ['0'];

  const { data: relatedCourses, isLoading: relatedCoursesLoading } = useQuery(
    'relatedTrainings',
    () => getTrainings({ from: 0, limit: 10, markets }),
    {
      enabled: !isLoading,
      refetchOnMount: 'always',
    },
  );

  const relatedCoursesFiltered =
    relatedCourses?.data.filter((course) => course.id !== data?.id) || [];
  const sponsorsFiltered =
    data?.supporters.filter((filter) => filter.level !== 'Apoiador') || [];
  const supportersFiltered =
    data?.supporters.filter((filter) => filter.level === 'Apoiador') || [];
  const testmonials = data?.testmonials || [];

  if (isLoading) return null;
  if (error) return error.message;

  return (
    <ScrollToTop>
      <MetaTags
        title={data.title}
        description={data.presentation}
        imageUrl={data.headerImage?.url}
      />
      <Box component="section">
        <Subheader
          title="Treinamento"
          breadcrumbs1={
            <Link
              component={RouterLink}
              to="/treinamentos"
              underline="none"
              className={classes.link}>
              Treinamentos
            </Link>
          }
          breadcrumbs2={data.title}
        />
        <TrainingTop
          classes={classes}
          title={data.title}
          type={data.type === 'Online' ? 'On-line' : data.type}
          saleAllowed={data.saleAllowed}
          discountPercent={data.discountPercent}
          mainOrganizer={data.mainOrganizer}
          investment={data.investment}
          markets={data.markets}
          courseTime={data.courseTime}
          level={data.level}
          stars={data.stars}
          image={data.headerImage}
          subscriptionUrl={data.subscriptionUrl}
          investmentWithoutDisccount={data.investmentWithoutDisccount}
          startDate={data?.resume?.startDate}
          endDate={data?.resume?.endDate}
          id={data.id}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Hidden smUp>
              {data.organizers?.length > 0 && (
                <Grid item>
                  <Typography className={classes.typography}>
                    &mdash;Organizadores
                  </Typography>
                  <Divider className={classes.dividerDetails} />
                  <Organizers classes={classes} organizers={data.organizers} />
                </Grid>
              )}
            </Hidden>
            <Grid item>
              <Typography className={classes.typography}>
                &mdash;{data.presentationTitle}
              </Typography>
              <Divider className={classes.divider} />
              <Presentation content={data.presentation} classes={classes} />
            </Grid>
            <Hidden smUp>
              <Grid item>
                <Typography className={classes.typography}>
                  &mdash;Resumo
                </Typography>
                <Divider className={classes.dividerDetails} />
                <Resume
                  classes={classes}
                  startDate={data?.resume?.startDate}
                  endDate={data?.resume?.endDate}
                  duration={data?.resume?.duration}
                  courseDurationType={data?.resume?.courseDurationType}
                  digitalCertificate={data?.resume?.digitalCertificate}
                  courseMethod={data?.resume?.courseMethod}
                />
              </Grid>
            </Hidden>
            <Grid item>
              <Typography className={classes.typography}>
                &mdash;Programa
              </Typography>
              <Divider className={classes.divider} />
              <Modules classes={classes} program={data.program} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Hidden xsDown>
              {data.organizers?.length > 0 && (
                <Grid item>
                  <Typography className={classes.typography}>
                    &mdash;Organizadores
                  </Typography>
                  <Divider className={classes.dividerDetails} />
                  <Organizers classes={classes} organizers={data.organizers} />
                </Grid>
              )}
            </Hidden>
            <Hidden xsDown>
              <Grid item>
                <Typography className={classes.typography}>
                  &mdash;Resumo
                </Typography>
                <Divider className={classes.dividerDetails} />
                <Resume
                  classes={classes}
                  startDate={data?.resume?.startDate}
                  endDate={data?.resume?.endDate}
                  duration={data?.resume?.duration}
                  courseDurationType={data?.resume?.courseDurationType}
                  digitalCertificate={data?.resume?.digitalCertificate}
                  courseMethod={data?.resume?.courseMethod}
                />
              </Grid>
            </Hidden>
            <Grid item>
              <Typography className={classes.typography}>
                &mdash;Detalhes
              </Typography>
              <Divider className={classes.dividerDetails} />
              <Details classes={classes} details={data.details} />
            </Grid>
            <If condition={testmonials.length !== 0}>
              <Grid item>
                <Typography className={classes.typography}>
                  &mdash;Depoimentos
                </Typography>
                <Divider className={classes.dividerDetails} />
                <Tesmonials testmonials={testmonials} classes={classes} />
              </Grid>
            </If>
          </Grid>
        </Grid>

        <Box>
          {data.courseFacilitators?.length > 0 && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.typography}>
                    &mdash;Facilitadores e convidados
                  </Typography>
                  <Divider className={classes.divider} />
                  <Facilitators
                    classes={classes}
                    facilitators={data.courseFacilitators}
                    state={state}
                    setState={setState}
                  />
                </Grid>
              </Grid>
              <FacilitatorsDetails
                classes={classes}
                state={state}
                setState={setState}
              />
            </>
          )}
        </Box>
        <Box className={classes.sponsor}>
          {sponsorsFiltered?.length > 0 || supportersFiltered?.length > 0 ? (
            <Grid container spacing={1}>
              {sponsorsFiltered?.length > 0 ? (
                <Grid
                  item
                  xs={12}
                  md={
                    sponsorsFiltered?.length && supportersFiltered?.length
                      ? 6
                      : 12
                  }>
                  <Typography className={classes.typography}>
                    &mdash;Patrocinadores
                  </Typography>
                  <Divider className={classes.divider} />
                  <Supporters classes={classes} supporters={sponsorsFiltered} />
                </Grid>
              ) : null}
              {supportersFiltered?.length > 0 ? (
                <Grid
                  item
                  xs={12}
                  md={
                    sponsorsFiltered?.length && supportersFiltered?.length
                      ? 6
                      : 12
                  }>
                  <Typography className={classes.typography}>
                    &mdash;Apoio
                  </Typography>
                  <Divider className={classes.divider} />
                  <Supporters
                    classes={classes}
                    supporters={supportersFiltered}
                  />
                </Grid>
              ) : null}
            </Grid>
          ) : null}
          {relatedCoursesFiltered.length > 0 ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.typography}>
                  &mdash;Cursos relacionados
                </Typography>
                <Divider className={classes.divider} />
                <Box py={2}>
                  <Grid container spacing={1}>
                    <RelatedCourses
                      courses={relatedCoursesFiltered}
                      isLoading={relatedCoursesLoading}
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          ) : null}
        </Box>
      </Box>
    </ScrollToTop>
  );
}
