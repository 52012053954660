import { useLocation } from 'react-router-dom';

export function getUtmUrl(url: string, campaign?: string, source?: string) {
  if (!url) return '';
  const newUrl = new URL(url);
  if (campaign) {
    newUrl.searchParams.append('utm_campaign', campaign);
  }
  if (source) {
    newUrl.searchParams.append('utm_source', source);
  }
  return newUrl.href;
}

/**
 * Verifica se a página atual é uma página do anuário entre 2024 e 2099
 * @param pathname - O caminho da página atual
 * @returns true se a página é uma página do anuário, false caso contrário
 */
export function isInYearbookPage(pathname: string) {
  return /\/anuarios\/(202[4-9]|20[3-9]\d)/.test(pathname);
}

export function useRedirectUrl() {
  const { search } = useLocation();
  const redirectUrl = search.replace('?redirectUrl=', '');
  return redirectUrl;
}
