import { css, styled } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

interface PieChartProps {
  data: number[];
  animationEnabled?: boolean;
  categories: string[];
  formatter?: (value: number) => string;
}

export default function PieChart(props: PieChartProps) {
  const StyledChart = styled(ReactApexChart)`
    ${({ theme }) => css`
      width: ${theme.breakpoints.values.sm}px;

      @media (max-width: ${theme.breakpoints.values.md}px) {
        width: ${theme.breakpoints.values.sm}px;
      }

      @media (max-width: ${theme.breakpoints.values.sm}px) {
        width: 100%;
      }
    `}
  `;
  return (
    <StyledChart
      type="donut"
      series={props.data}
      options={{
        chart: {
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false,
          },
        },
        labels: props.categories,
        plotOptions: {
          bar: {
            borderRadiusWhenStacked: 'last',
            borderRadiusApplication: 'end',
            rangeBarGroupRows: true,
          },
        },
        tooltip: {
          y: {
            formatter: props.formatter
              ? props.formatter
              : function (value: any) {
                  if (isNaN(Number(value))) {
                    return value;
                  }

                  const formatedNumber = Number(value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  });

                  return `${formatedNumber} %`;
                },
          },
        },
        colors: [
          '#FD841F',
          '#CD104D',
          '#F2CD5C',
          '#600446',
          '#35D0BA',
          '#A61F69',
        ],
        dataLabels: {
          formatter() {
            return '';
          },
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 1366,
            options: {
              plotOptions: {
                bar: {
                  borderRadiusWhenStacked: 'last',
                  borderRadiusApplication: 'end',
                  rangeBarGroupRows: true,
                },
              },
              chart: {
                width: '100%',
                animations: {
                  enabled: false,
                },
              },
            },
          },
          {
            breakpoint: 1000,
            options: {
              width: '100%',
              plotOptions: {
                bar: {
                  borderRadiusWhenStacked: 'last',
                  borderRadiusApplication: 'end',
                  rangeBarGroupRows: true,
                },
              },
              chart: {
                animations: {
                  enabled: false,
                },
              },
            },
          },
        ],
      }}
    />
  );
}
