import { SvgIcon, SvgIconProps } from '@mui/material';

export function CrIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      component={'svg'}
      viewBox="0 0 12 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/SvgIcon">
      <path d="M7.457 0h-5.8c-.44 0-.86.18-1.172.502C.175.824 0 1.26 0 1.714v12c0 .455.175.891.485 1.213.311.321.733.502 1.172.502h2.486V18l1.657-.857L7.457 18v-2.571h2.486c.44 0 .86-.181 1.172-.502.31-.322.485-.758.485-1.213V4.286m-4.143 6.857v1.714L5.8 12l-1.657.857v-1.714l-1.657-.857 1.657-.857V7.714l1.657.857 1.657-.857V9.43l1.657.857M6.63 5.143V1.286l3.728 3.857H6.63z" />
    </SvgIcon>
  );
}
