import { useMemo } from 'react';

import { Box } from '@mui/material';

import { Article } from './Article';
import { articlesData } from '../../data/presentation';
import { MarketName } from '../../types';

interface ArticlesSectionProps {
  market: MarketName;
}

export function ArticlesSection({ market }: ArticlesSectionProps) {
  const articles = useMemo(() => articlesData[market], [market]);

  return (
    <Box component={'section'} data-yearbook-section="Artigos" my={10}>
      {articles.map((article) => (
        <Article key={article.title} article={article} />
      ))}
    </Box>
  );
}
