import { useEffect } from 'react';

import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getSeoHome } from 'src/services/misc';

import Dashboard from './Dashboard';
import MetaTags from '../../components/Seo/MetaTags';
import { useAuthContext } from '../../context/AuthContextProvider';
import { ScrollToTop } from '../../utils/scroll';

export default function Home() {
  const { auth } = useAuthContext();
  const { data: seoHome } = useQuery('seo-home', getSeoHome);

  const history = useHistory();

  useEffect(() => {
    if (!auth.isLoggedIn) {
      history.push('/login?redirectUrl=/plataforma');
    }
  }, [auth.isLoggedIn, history]);

  return (
    <>
      <MetaTags
        title="UQBAR - Educação, Conexão e Inteligência de Mercado"
        description="Seja bem-vindo a Uqbar, a mais importante fonte de educação, conexão e inteligência do mercado de finanças estruturadas."
        seo={seoHome ? seoHome.data?.seo : undefined}
      />
      <ScrollToTop>
        <Dashboard />
      </ScrollToTop>
    </>
  );
}
