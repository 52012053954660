import React, { PropsWithChildren } from 'react';

import { ArrowForwardIosRounded } from '@mui/icons-material';
import { Breadcrumbs, Link, Typography, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import * as S from './styles';
import { If } from '../../If';

type BreadcrumbItem =
  | string
  | {
      title: string;
      link: string;
    };

type Props = {
  title?: string;
  titleIcon?: JSX.Element;
  subtitle?: string;
  tabItems?: Array<JSX.Element | null>;
  tabIndex: number;
  tabContent: Array<JSX.Element | null>;
  handleChange: (tab: number) => void;
  breadcrumbs1?: BreadcrumbItem;
  breadcrumbs2?: BreadcrumbItem;
  item?: JSX.Element;
};

const TabTemplate = (props: PropsWithChildren<Props>) => {
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, tab: number) => {
    props.handleChange(tab);
  };
  const RenderedComponent = props.tabItems?.length
    ? props.tabContent[props.tabIndex]
    : undefined;

  return (
    <S.Wrapper>
      <S.Header>
        <Breadcrumbs
          aria-label="navegação-secundária"
          sx={{
            fontSize: theme.spacing(1.75),
            marginBottom: !props.title ? '16px' : '0',
            '@media print': {
              display: 'none',
            },
          }}
          separator={
            <ArrowForwardIosRounded
              sx={{
                fontSize: theme.spacing(1.5),
                color: '#7D83984D',
                marginTop: '2px',
              }}
            />
          }>
          <Link
            component={RouterLink}
            to="/plataforma"
            underline="none"
            sx={{ color: theme.palette.primary.main }}>
            Home
          </Link>
          {props.breadcrumbs1 && typeof props.breadcrumbs1 !== 'string' ? (
            <Link
              component={RouterLink}
              to={props.breadcrumbs1.link}
              underline="none"
              sx={{ color: theme.palette.primary.main }}>
              {props.breadcrumbs1.title}
            </Link>
          ) : (
            <Typography
              sx={{
                fontSize: theme.spacing(1.75),
              }}>
              {props.breadcrumbs1}
            </Typography>
          )}

          {props.breadcrumbs2 && (
            <>
              {typeof props.breadcrumbs2 !== 'string' ? (
                <Link
                  component={RouterLink}
                  to={props.breadcrumbs2.link}
                  underline="none"
                  sx={{ color: theme.palette.secondary.main }}>
                  {props.breadcrumbs2.title}
                </Link>
              ) : (
                <Typography
                  sx={{
                    fontSize: theme.spacing(1.75),
                  }}>
                  {props.breadcrumbs2}
                </Typography>
              )}
            </>
          )}
        </Breadcrumbs>
        <If condition={Boolean(props.title)}>
          <S.Title>
            <>
              {props.titleIcon}
              {props.title}
            </>
          </S.Title>
        </If>
        <If condition={Boolean(props.subtitle)}>
          <S.Subtitle>{props.subtitle}</S.Subtitle>
        </If>
        <If condition={Boolean(props.tabItems?.length)}>
          <S.Content>
            <S.Tabs value={props.tabIndex} onChange={handleChange}>
              {props.tabItems}
            </S.Tabs>
            <If condition={props.item}>{props.item}</If>
          </S.Content>
        </If>
      </S.Header>
      <If condition={Boolean(props.tabItems?.length)}>
        <>{RenderedComponent}</>
      </If>
    </S.Wrapper>
  );
};

export default TabTemplate;
